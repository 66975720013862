import { customAxios } from "./customAxios"
export function searchSintesiAccounting(tipo,dataPraticaDa,dataPraticaA) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    }

    const data = JSON.stringify({
        "dataPraticaDal": dataPraticaDa,
        "dataPraticaAl": dataPraticaA,
        "tipoInserimento": tipo,

    })
    const searchRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/alfresco/searchSintesiAccounting", data, config)
    return searchRequest
}