import { customAxios } from './customAxios'
export function getNodeChildById(idNodo) {
    const config = {
        params: { idNode: idNodo.toString() },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }

    const getNodeChildByIdRequest =
        customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/alfresco/getNodeChildById?`, config
        ).then(response => response.data.body)
    return getNodeChildByIdRequest
}