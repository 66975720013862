import { customAxios } from './customAxios'

export function saveApprovazioneAdv(formValues) {
  const config = {
    headers: {
      AuthToken: JSON.parse(localStorage.getItem('token')),
    },
  }
  const data = {
    approvazione: formValues.approvazione,
    idNodoPratica: formValues.idNodoPratica,
    motivazione: formValues.motivazione,
    documentiOpzionali: formValues.documentiOpzionali,
    noteUtente: formValues.noteUtente,
  }

  const adeguataVerificaRequest = customAxios.post(
    '/alfresco/saveApprovazioneADV',
    data,
    config,
  )
  return adeguataVerificaRequest
}
