import { customAxios } from './customAxios'

export function getListaUtenzeAssegnatariMonitoraggio(monitoraggio) {
    let url = `${process.env.REACT_APP_BACKEND_HOST}/alfresco/getUtenzeAssegnatari`;
    
    if (typeof monitoraggio !== 'undefined') {
        url += `?monitoraggio=${monitoraggio}`;
    }
    
    const listaUtenzeAssegnatari = customAxios.get(url).then(res => res.data.body);
    
    return listaUtenzeAssegnatari;
}