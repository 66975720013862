import { customAxios } from "./customAxios";

export function sendRichiestaSupporto(formValues) {
    // debugger
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const data = {
        "idNodoPratica": formValues.idNodoPratica,
        "message": formValues.message,
        "groupsId": formValues.groupsId,
        "richiedenteSupportoConsulenziale" : formValues.richiedenteSupportoConsulenziale,
    }
    
    const sendRichiestaSupportoRequest = customAxios.post("/alfresco/sendRichiestaSupporto", data, config)
    return sendRichiestaSupportoRequest
}