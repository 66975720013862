import { customAxios } from "./customAxios";

export function duplicaAdv(formValues) {
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const data = {
       
        idNodoPratica: formValues.idNodoPratica,
        nomePratica: formValues.nomePratica,
        tipologiaDiRichiesta: formValues.tipologiaDiRichiesta,
        tipologiaOperativita: formValues.tipologiaOperativita,
        relazione: formValues.relazione,
        daAssegnare: formValues.daAssegnare,
        documentiOpzionaliVari: formValues.documentiOpzionaliVari,
        motivazioneRichiesteOpzionali: formValues.motivazioneRichiesteOpzionali,
        dettaglioRelazione: formValues.dettaglioRelazione,
        documentiPerAdeguataVerifica: formValues.documentiPerAdeguataVerifica
    }

    const duplicaAdvRequest = customAxios.post("/alfresco/duplicaAdv", data, config)
    return duplicaAdvRequest
}