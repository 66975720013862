import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import ProfiloUtente from './ProfiloUtente'
import NuovaRichiesta from './NuovaRichiesta'
import Dashboard from './Dashboard'
import NotFound from './NotFound'
import ModificaPassword from './ModificaPassword'
import SessioneScaduta from './SessioneScaduta'
import { AuthContext } from '../App'
import Login from './Login'
import OnlyWithFunction from '../OnlyWithFunctions'
import { useContext, useEffect } from 'react'
import Dettaglio from './Dettaglio'
import NuovaRichiestaForm from './NuovaRichiestaForm'
import StatistichePanel from './StatistichePanel'
import Accounting from './Accounting'
import { validateToken } from '../services/validateToken'
import TransitionComponentSnackbar from '../UI/CustomSnackbar';
import Monitoring from './Monitoring'
import DettaglioMonitoraggio from './DettaglioMonitoraggio'

const HomePage = () => {
  const navigate = useNavigate()
  const { state: AuthState } = useContext(AuthContext)
  // console.table({ AuthState })

  useEffect(() => {
    if (AuthState.isAuthenticated === false) {
      return <Login />
    }
  }, [AuthState.isAuthenticated])
  // console.log('render')


  setTimeout(function () {
    validateToken().then(res => {
      if (res.data.body !== "OK") {
        AuthState.isAuthenticated = false
        localStorage.clear()
        window.location.reload()
      }
    })
  }, 3600000)


  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      AuthState.isAuthenticated = false
      navigate("/login")
    }
  }, [localStorage])

  return (
    <div style={{ position: "relative" }}>
      <TransitionComponentSnackbar />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="dettaglio" element={<Dettaglio />} />
        <Route path="dettaglioMonitoraggio" element={<DettaglioMonitoraggio />} />

        <Route
          path="nuova-richiesta"
          element={
            <OnlyWithFunction acceptedFunction="NUOVA_ADV">
              <NuovaRichiesta />
            </OnlyWithFunction>
          }
        />
        <Route
          path="nuova-richiesta-form"
          element={
            <OnlyWithFunction acceptedFunction="NUOVA_ADV">
              <NuovaRichiestaForm />
            </OnlyWithFunction>
          }
        />
        <Route
          path="/statistiche"
          element={
            <StatistichePanel />
          }
        />
        <Route
          path="/accounting"
          element={
            <Accounting />
          }
        />
        <Route
          path="/sintesiAccounting"
          element={
            <Accounting onlySintesi={true} />
          }
        />
        <Route
          path="/monitoring"
          element={
            <Monitoring />
          }
        />
        <Route path="/*" element={<NotFound />} />
        <Route path="/profilo-utente" element={<ProfiloUtente />} />
        <Route path="/modifica-password" element={<ModificaPassword />} />
        <Route path="/sessione-scaduta" element={<SessioneScaduta />} />
      </Routes>
    </div>
  )
}

export default HomePage
