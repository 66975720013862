import React from 'react'
import { Box } from '@mui/material'

import homepageLogoADV from '../assets/logo-bancaprogetto.png'

const LogoComponent = () => {
    return (
        <>
            <Box
                ml="20%"
                component="img"
                sx={{
                    height: {
                        xs: '8em',
                        xl: '10em',
                    },
                    width: { xs: '30em', xl: '35em' },
                }}
                alt="Logo ADV"
                src={homepageLogoADV}
            />
        </>
    )
}

export default LogoComponent
