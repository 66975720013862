import React from 'react'
import {
  RadioGroup,
  Radio,
  ListItemText,
  Box,
  Stack,
  Typography,
  textarea,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { StyledButton } from '../../UI/StyledButton'
import CustomModal from '../../UI/CustomModal'
import OnlyWithFunction, { isValidUser } from '../../OnlyWithFunctions'
import { getEsitoADV } from '../../services/getEsitoADV'
import { saveApprovazioneAdv } from '../../services/saveApprovazioneADV'
import SendModal from '../../UI/SendModal'
import { sendRichiestaSupporto } from '../../services/sendRichiestaSupporto'
import { getApprovazioneADV } from '../../services/getApprovazioneADV'
import { userAssignedBelongToGroup } from '../../services/userAssignedBelongToGroup'
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function ApprovazioneAdeguataVerifica(props) {
  const theme = useTheme()
  const location = useLocation()
  const [idNodoPratica, setIdNodoPratica] = React.useState('')
  const [save, setSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [error, setError] = useState(false)
  const [valueApprovazione, setValueApprovazione] = React.useState('')
  const [valueNote1, setValueNote1] = React.useState('')
  const [valueNote2, setValueNote2] = React.useState('')
  const [richiesta, setRichiesta] = useState('')
  const [groupType, setGroupType] = useState('')
  const [gruppoUtenteCreatore, setGruppoUtenteCreatore] = useState(location.state?.selectedRowData.entry.properties["folder_model:gruppoUtenteCreatore"])
  const [disable, setDisable] = React.useState(true)
  const [checkOperatore, setCheckOperatore] = React.useState({})
  const [docName, setDocName] = React.useState([])
  const [show, setShow] = useState(false)
  const [valueRadio6, setValueRadio6] = React.useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [salvataggio, setSalvataggio] = useState(true)
  const [noteUtenteAutorizzativo, setNoteUtenteAutorizzativo] = React.useState('')
  const [rispostaSupporto, setRispostaSupporto] = useState(false)
  const [assegnataAdUtenteBase, setAssegnataAdUtenteBase] = useState(5)
  const [assegnataAFieldAnalyst, setAssegnataAFieldAnalyst] = useState(5)
  const [richiedenteSupportoConsulenziale, setRichiedenteSupportoConsulenziale] = React.useState(() => {
    const storedValue = localStorage.getItem('user');
    try {
      return JSON.parse(storedValue);
    } catch (error) {
      console.error('Errore nel parsing del valore di localStorage:', error);
      return "";
    }
  });
  const [value, setValue] = React.useState({
    approvazione: '',
    idNodoPratica: props.idNodoPratica,
    motivazione: '',
    documentiOpzionali: [''],
    noteUtente: '',
  })

  const [value2, setValue2] = React.useState({
    approvazione: '',
    idNodoPratica: props.idNodoPratica,
    motivazione: '',
    documentiOpzionali: [''],
    noteUtente: props.noteUtente,
  })

  const names = [
    'Ultimo Bilancio Ottico',
    'Catena Partecipativa con grafo',
    'Titolare Effettivo (PDF)',
    'Referenze Bancarie Nazionali',
    'Cariche e quote possedute dagli esponenti e soci in altre aziende',
    'Informazioni INPS',
    'Stato di Famiglia',
    'ADVR Rafforzata Complify',
  ]

  useEffect(() => {
    try {
      getEsitoADV(props.idNodoPratica).then(response => {
        // debugger
        setCheckOperatore(response)
      })
    } catch (error) {
      // console.log(error)
    }
  }, [props.idNodoPratica])
  // console.log(checkOperatore)

  useEffect(() => {
    try {
      // debugger
      setValue({
        ...value,
        approvazione: valueApprovazione,
        motivazione: valueNote1,
        documentiOpzionali: docName,
        noteUtente: valueNote2,
      })
      // debugger
      // console.log(value)
    } catch (error) {
      // console.log(error)
    }
  }, [valueApprovazione, valueNote1, docName, valueNote2])

  useEffect(() => {

    // debugger


    try {
      userAssignedBelongToGroup(location.state?.selectedRowData.entry.properties["folder_model:daAssegnare"]).then(response => {
        if (response) {


          if (response.data === 1) {
            setAssegnataAdUtenteBase(response.data)
            // console.log('assegnazione a utenteBase:',response.data)
            // console.log('assegnazione a Field:',assegnataAFieldAnalyst)
            // console.log('assegnazione a utenteBase:',assegnataAdUtenteBase)
          }
          else if (response.data === 0) {
            setAssegnataAFieldAnalyst(response.data)
          }
        }
      })
    }
    catch (error) {
      // console.log(error)
    }
  }, [assegnataAdUtenteBase, assegnataAFieldAnalyst])

  const handleChangeRadio = event => {
    if (event.target.checked) {
      // debugger
      setValueApprovazione(event.target.value)
      setDisable(false)
    }
  }
  const handleChangeNote1 = event => {
    setValueNote1(event.target.value)
  }
  const handleChangeNote2 = event => {
    setValueNote2(event.target.value)
  }

  const handleSave = event => {
    setSave(true)
    setLoading(true)
    saveApprovazioneAdv(value)
      .then(response => {
        setInfoMessage(response.data.message)
        // console.log(JSON.stringify(value))
        setLoading(false)
        // console.log(value)
        // debugger
        // console.log(response)
        // console.log(value)
        setTimeout(function () {
          window.location.reload()
        }, 3000)
      })
      .catch(error => {
        // console.log(error.response.status)
        setLoading(false)
        setTimeout(function () {
          setSave(false)
        }, 5000)
        setError(true)
        if (error.response) {
          alert(error.response.status)
          setInfoMessage(error.response.data.message)
        }
        if (error.request) {
          alert(error.response.message)
        }
      })
  }

  useEffect(() => {
    try {
      getApprovazioneADV(props.idNodoPratica).then(response => {
        // console.log(response)
        setValueNote2(response?.noteUtente)
        // console.log('Response note utente = ', response?.noteUtente)
      })
    } catch (error) {
      // console.log(error)
    }
  }, [props.idNodoPratica])

  const [titleSendModal, setTitleSendModal] = useState('')

  const handleChangeRichiesta = (event, rispostaSupporto) => {
    // debugger
    setGroupType('')

    setRichiedenteSupportoConsulenziale(localStorage.getItem('user'))
    setRispostaSupporto(false)
    setValueRadio6(event.target.value)
    if (event.target.value === 'Richiesta di supporto per ADVR in fase di onboarding') {
      setGroupType('onboarding')
      setTitleSendModal('Richiesta Supporto')
    }
    else if (event.target.value === 'Richiesta di supporto per ADVR in fase di monitoraggio') {
      setGroupType('monitoraggio')
      setTitleSendModal('Richiesta Supporto')
    }
    setDocName([])
    setModalOpen(true)
  }

  const handleShow = event => {
    event.preventDefault()
    if (!show) {
      setShow(true)
    } else {
      setShow(false)
    }
  }
  const multiChange = event => {
    const {
      target: { value },
    } = event
    setDocName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }
  const handleChangeModal = event => {
    setModalOpen(false)
  }

  const handleSendRichiestaSupporto = richiestaSupporto => {
    // debugger
    setSave(true)
    setLoading(true)
    setModalOpen(false)
    setInfoMessage('')

    sendRichiestaSupporto(richiestaSupporto)
      .then(response => {
        setLoading(false)
        setInfoMessage(response.data.message)
        setError(response.data.code !== 200)
        setTimeout(function () {
          window.location.reload()
        }, 1000)
      })
      .catch(err => {
        setLoading(false)
        setInfoMessage(
          "Errore durante l' invio della richiesta, se persiste contattare l'amministratore",
        )
        setError(true)
        setTimeout(function () {
          setSave(false)
        }, 5000)
      })
  }
  return (

    <Stack sx={{ display: 'flex' }}>
      {/* {(loading === true) &&
                    <CircularProgress size='1rem' />} */}
      <SendModal
        open={modalOpen}
        value={richiesta}
        groupType={groupType}
        richiedenteSupportoConsulenziale={richiedenteSupportoConsulenziale}
        onClick={handleChangeModal}
        idNodoPratica={props.idNodoPratica}
        onSubmit={handleSendRichiestaSupporto}
        rispostaSupporto={rispostaSupporto}
        titleSendModal={titleSendModal}
      />

      <CustomModal
        open={save}
        loading={loading}
        error={error}
        message={infoMessage}
      />
      <FormGroup>
        <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Esito ADVR</Typography>

        <textarea
          style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
          minrows={4}
          disabled={true}
          defaultValue={checkOperatore.noteAzioniDaPorreInEssere}
        ></textarea>
        {/* {(isValidUser('APPROVATORE_MONITORAGGIO')) &&
          <><RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={valueApprovazione}
            onChange={handleChangeRadio}
          >
            <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Approvazione</Typography>

            <Stack direction="row">
              <FormControlLabel
                value="Chiusa"
                control={<Radio />}
                label={<Typography variant="caption">SI</Typography>} />
              <FormControlLabel
                value="In Lavorazione"
                control={<Radio />}
                label={<Typography variant="caption">NO</Typography>} />
            </Stack>
          </RadioGroup>
            <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Motivazione</Typography>
            <textarea
              style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
              minrows={4}
              disabled={false}
              defaultValue={valueNote1}
              onChange={handleChangeNote1}
            ></textarea></>
        } */}
        {((isValidUser('APPROVA_PRATICA_FIELD_ANALYST') && gruppoUtenteCreatore === "FIELD_ANALYST") ||
          (isValidUser('APPROVA_PRATICA_FIELD_ANALYST') || gruppoUtenteCreatore === null)
          && assegnataAFieldAnalyst === 0) &&

          <><RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={valueApprovazione}
            onChange={handleChangeRadio}
          >
            <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Approvazione</Typography>

            <Stack direction="row">
              <FormControlLabel
                value="Chiusa"
                control={<Radio />}
                label={<Typography variant="caption">SI</Typography>} />
              <FormControlLabel
                value="In Lavorazione"
                control={<Radio />}
                label={<Typography variant="caption">NO</Typography>} />
            </Stack>
          </RadioGroup>
            <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Motivazione</Typography>
            <textarea
              style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
              minrows={4}
              disabled={false}
              defaultValue={valueNote1}
              onChange={handleChangeNote1}
            ></textarea></>
        }
        {((isValidUser('APPROVA_PRATICA_UTENTE_BASE') && gruppoUtenteCreatore === "UTENTE_BASE") ||
          ((isValidUser('APPROVA_PRATICA_UTENTE_BASE') || gruppoUtenteCreatore === null) && assegnataAdUtenteBase === 1)) &&
          <><RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={valueApprovazione}
            onChange={handleChangeRadio}
          >
            <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Approvazione</Typography>

            <Stack direction="row">
              <FormControlLabel
                value="Chiusa"
                control={<Radio />}
                label={<Typography variant="caption">SI</Typography>} />
              <FormControlLabel
                value="In Lavorazione"
                control={<Radio />}
                label={<Typography variant="caption">NO</Typography>} />
            </Stack>
          </RadioGroup>
            <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>Motivazione</Typography>
            <textarea
              style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
              minrows={4}
              disabled={false}
              defaultValue={valueNote1}
              onChange={handleChangeNote1}
            >
            </textarea>
          </>
        }

        {valueApprovazione === 'In Lavorazione' && (
          <FormGroup>
            <StyledButton
              onClick={handleShow}
              sx={{
                borderRadius: '5px 0px 0px 5px',
                mt: '20px',
                alignSelf: 'flex-end',
                paddingBottom: '10px',
                paddingTop: '0px',
              }}
            >
              ...
            </StyledButton>


            {show && (
              <FormGroup>
                <InputLabel variant="standard">
                  Documenti (Opzionali) Vari
                </InputLabel>
                <Select
                  variant="standard"
                  size="none"
                  sx={{ width: '90%', maxWidth: '600px' }}
                  id="demo-multiple-checkbox"
                  multiple
                  value={docName}
                  onChange={multiChange}
                  renderValue={selected => selected.join(', ')}
                  MenuProps={MenuProps}
                  inputProps={{
                    name: 'documentiOpzionaliVari',
                    id: 'uncontrolled-native',
                  }}
                >
                  {names.map(name => (
                    <Tooltip
                      placement="left"
                      key={name}
                      title={name}
                      value={name}
                    >
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={docName.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    </Tooltip>
                  ))}
                </Select>
                <FormGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueRadio6}
                  sx={{ mt: '20px' }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                  >
                    <OnlyWithFunction acceptedFunction={'APPROVA_PRATICA_UTENTE_BASE'}>
                      <StyledButton
                        onClick={handleChangeRichiesta}
                        value={'Richiesta di supporto per ADVR in fase di onboarding'}
                        sx={{ borderRadius: '5px 0px 0px 5px', alignSelf: 'flex-end' }}
                      >Richiesta di supporto per ADVR in fase di onboarding
                      </StyledButton>
                    </OnlyWithFunction>

                    <OnlyWithFunction acceptedFunction={'APPROVA_PRATICA_FIELD_ANALYST'}>
                      <StyledButton
                        onClick={handleChangeRichiesta}
                        value={'Richiesta di supporto per ADVR in fase di monitoraggio'}
                        sx={{ borderRadius: '5px 0px 0px 5px', alignSelf: 'flex-end', }}
                      >Richiesta di supporto per ADVR in fase di monitoraggio
                      </StyledButton>
                    </OnlyWithFunction>

                  </Stack>

                </FormGroup>
                <Typography sx={{ mr: 2, mb: 2, mt: 2 }}>
                  NOTE UTENTE AUTORIZZATIVO
                </Typography>
                <textarea
                  style={{ margin: "2px", minWidth: "100%", width: "100px", maxWidth: "100px", height: "60px", maxHeight: "50%", minHeight: "60px" }}
                  minrows={4}
                  defaultValue={valueNote2}
                  onChange={handleChangeNote2}
                ></textarea>
              </FormGroup>
            )}
          </FormGroup>
        )}

        <StyledButton
          disabled={disable}
          onClick={handleSave}
          sx={{
            borderRadius: '5px 0px 0px 5px',
            mt: '20px',
            alignSelf: 'flex-end',
          }}
        >
          Salva
        </StyledButton>
      </FormGroup>
    </Stack>
  )
}

export default ApprovazioneAdeguataVerifica
