// 

import { customAxios } from './customAxios'
export function getAbilitazioneTastoChiuso(idNodoPratica) {
    const config = {
        params: { idNodoPratica: idNodoPratica.toString() },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const getAbilitazioneTastoChiuso =
        customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/alfresco/abilitaChiuso?`, config).then(response => response.data.body)
    return getAbilitazioneTastoChiuso
}