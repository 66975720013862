import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Stack,
  Grid,
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  List,
  ListItem,
  IconButton,
  Modal,
  Fade
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTheme } from '@mui/material/styles';
import { StatiPratica } from '../../utils/StatiPratica';
import EsitoMonitoraggio from './EsitoMonitoraggio';
import { StyledButton } from '../../UI/StyledButton';
import { getAllDocs } from '../../services/getAllDocs';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import UploadDocumentMonitoraggio from './UploadDocumentMonitoraggio';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PDFViewerMonitoraggio from '../../Charts/PDFViewerMonitoraggio';
import { getOptionalFile } from '../../services/getOptionalFile';
import { deleteOptionalFile } from '../../services/deleteOptionalFile';
import { getRiepilogoPdfMonitoraggio } from '../../services/getRiepilogoPdfMonitoraggio';
import { OpenPrintPreviewFromByteArray } from '../../utils/OpenPrintPreviewFromByteArray';
import { searchById } from '../../services/searchById';
import { isValid } from 'date-fns';
import { isValidUser } from '../../OnlyWithFunctions';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('it-IT'); // Formatta la data in formato italiano
};

const AccordionDettaglioMonitoraggio = ({ dettaglioPratica: initialDettaglioPratica }) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [documentiOpzionali, setDocumentiOpzionali] = useState([]);
  const [folders, setFolders] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const [dettaglioPratica, setDettaglioPratica] = useState(initialDettaglioPratica);
  const [hasFetchedDetails, setHasFetchedDetails] = useState(false);
  const [isUploading, setIsUploading] = useState(false); // Stato per tracciare se un upload è in corso
  const [hasFetchedFolders, setHasFetchedFolders] = useState(false); // Stato per tracciare se i documenti sono stati caricati

  const fetchData = async (idMonitoraggio) => {
    if (hasFetchedFolders) return; // Evita chiamate multiple
    setLoading(true); // Imposta lo stato di caricamento all'inizio della funzione
    try {
      const response = await getAllDocs(idMonitoraggio);
      const optionalDocsFolder = response.data.children.find(child => child.name.includes('Documenti opzionali'));
      if (optionalDocsFolder) {
        const folderDetails = optionalDocsFolder.children
          .filter(folder => folder.idTipologia && folder.idTipologia !== 0)
          .map(folder => {
            const nameParts = folder.name.split('/');
            const folderName = nameParts[nameParts.length - 2]; // Assuming the second-to-last part is the folder name
            return {
              idTipologia: folder.idTipologia,
              name: folderName,
              children: folder.children,
            };
          });
        setFolders(folderDetails);
      }
    } catch (err) {
      console.error('Error fetching folders:', err);
      setError(err.message);
    } finally {
      setLoading(false); // Imposta lo stato di caricamento a false alla fine della funzione
      setHasFetchedFolders(true); // Imposta lo stato di caricamento documenti a true
    }
  };

  useEffect(() => {
    if (!hasFetchedDetails && dettaglioPratica && dettaglioPratica.idMonitoraggio) {
      handleUploadSuccess();
      setHasFetchedDetails(true);
    }
  }, [hasFetchedDetails, dettaglioPratica]);

  useEffect(() => {
    if (dettaglioPratica && dettaglioPratica.status) {
      setStatus(dettaglioPratica.status);
    }

    if (dettaglioPratica && dettaglioPratica.idMonitoraggio && !hasFetchedFolders) {
      fetchData(dettaglioPratica.idMonitoraggio);
    }
  }, [dettaglioPratica, hasFetchedFolders]);

  const handleUploadSuccess = async () => {
    if (isUploading) return; 
    setIsUploading(true); 

    try {
      if (dettaglioPratica && dettaglioPratica.idMonitoraggio) {
        
        const newDetails = await searchById(dettaglioPratica.idMonitoraggio);
        console.log(newDetails); 

        setStatus(newDetails.status);
        setDettaglioPratica(newDetails);
        setHasFetchedFolders(false); 
      }
    } catch (error) {
      console.error('Errore nel caricamento dei dettagli della pratica:', error);
    } finally {
      setIsUploading(false); // Imposta lo stato di upload a false alla fine della funzione
    }
  };

  const handleClick = (file) => {
    setSelectedFile(selectedFile === file ? null : file);
  };

  const handleView = async (file) => {
    try {
      const response = await getOptionalFile(file.path);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result.split(',')[1];
        setPdfData(base64data);
        setOpenModal(true);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error viewing file:', error);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
    setPdfData(null);
  };

  const handleDownload = async (file) => {
    try {
      const response = await getOptionalFile(file.path);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const handleDelete = async (file) => {
    try {
      const response = await deleteOptionalFile(dettaglioPratica.idMonitoraggio, file.path);
      const optionalDocsFolder = response.data.children.find(child => child.name.includes('Documenti opzionali'));
      if (optionalDocsFolder) {
        const folderDetails = optionalDocsFolder.children
          .filter(folder => folder.idTipologia && folder.idTipologia !== 0)
          .map(folder => {
            const nameParts = folder.name.split('/');
            const folderName = nameParts[nameParts.length - 2]; // Assuming the second-to-last part is the folder name
            return {
              idTipologia: folder.idTipologia,
              name: folderName,
              children: folder.children,
            };
          });
        setFolders(folderDetails);
        const newDetails = await searchById(dettaglioPratica.idMonitoraggio);
        console.log(newDetails); // Aggiungi questo per il debugging

        // Aggiorna lo stato con i nuovi dettagli della pratica
        setStatus(newDetails.status);
        setDettaglioPratica(newDetails);

      }
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setLoading(false);
    }
  };

  const getPdf = event => {
    event.stopPropagation(); // Impedisce la propagazione dell'evento di clic

    try {
      setLoading(true);
      event.preventDefault();
      event.stopPropagation();
      getRiepilogoPdfMonitoraggio(dettaglioPratica.idMonitoraggio).then(res => {
        if (res.data.code === 200) {
          OpenPrintPreviewFromByteArray(res.data.body);
          setLoading(false);
        } else setLoading(false);
      });
    } catch (error) {
      alert(error);
      setLoading(false);
    }
  };

  const handleUpdatePratica = (newDetails) => {
    setStatus(newDetails.status);
    setDettaglioPratica(newDetails); // Aggiorna i dettagli della pratica con i nuovi dati
    setHasFetchedFolders(false); // Forza il ricaricamento dei documenti opzionali
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Accordion
        elevation={0}
        disableGutters
        square={true}
        sx={{
          width: '1300px',
          backgroundColor: theme.palette.grey.dark,
          ml: '20px',
        }}
      >
        <AccordionSummary
          sx={{ backgroundColor: theme.palette.grey.dark }}
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container display="contents" justifyContent="center" alignItems="center">
            <Stack sx={{ width: '9%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="standard">
                {`NDG`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.ndg}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center" justifyItems={'start'}>
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`CODICE FISCALE`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.codiceFiscale}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`RAGIONE SOCIALE`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.ragioneSociale}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`LIVELLO DI RISCHIO`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.livelloDiRischio}
              </Typography>
            </Stack>

            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`DATA APERTURA`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {formatDate(dettaglioPratica.dataApertura)}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`PRESENZA VARIAZIONI CERVED`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.esitoSintetico}
              </Typography>
            </Stack>
            <Stack sx={{ width: '40%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`STATUS`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.status}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`DATA STATUS`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {formatDate(dettaglioPratica.dataStatus)}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`PRESENZA CHECK USO FONDI`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.esitoMonitoraggioFondi}
              </Typography>
            </Stack>
            <Stack sx={{ width: '20%' }} alignItems="center">
              <Typography sx={{ fontWeight: 900, textAlign: 'center' }} variant="caption">
                {`GESTORE`}
              </Typography>
              <Typography sx={{ textAlign: 'center' }} variant="caption">
                {dettaglioPratica.gestore}
              </Typography>
            </Stack>
          </Grid>
          <Stack sx={{ width: '9%' }}>
            {loading ? (
              <div style={{ marginLeft: '20px', marginTop: '1px' }}>
                <CircularProgress />
              </div>
            ) : (
              <Tooltip key={'Stampa Riepilogo'} title="Stampa Riepilogo">
                <IconButton onClick={getPdf}>
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion elevation={0} defaultExpanded disableGutters square={true}>
            <AccordionSummary
              sx={{
                backgroundColor: theme.palette.yellow.middle,
                width: '100%',
              }}
              onClick={() => {
                setStatus(status);
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: '24px' }}>SINTESI MONITORAGGIO</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: theme.palette.yellow.middle,
                width: '100%',
                alignItems: 'center',
              }}
            >
              <EsitoMonitoraggio status={status} dettaglioPratica={dettaglioPratica} onUpdatePratica={handleUpdatePratica} />
              <AccordionActions
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  padding: 0,
                }}
              ></AccordionActions>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
        <Accordion elevation={0} disableGutters square={true}
          sx={{
            backgroundColor: theme.palette.grey.dark,
            width: '100%',
          }}>
          <AccordionSummary
            sx={{
              backgroundColor: theme.palette.grey.dark,
              width: '100%',
            }}
            onClick={() => {
              setStatus(status);
            }}
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontWeight: 'bold', fontSize: '24px',marginLeft:'15px' }}>DOCUMENTI MONITORAGGIO</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: theme.palette.grey.light }}>
            {loading ? (
              <CircularProgress />
            ) : folders.length === 0 ? (
              <Typography sx={{ml:2}}>Nessun documento disponibile</Typography>
            ) : (
              folders.map((folder, index) => (
                <List key={index}>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <Typography sx={{ fontWeight: 'bold' }}>{folder.name}</Typography>
                    </Grid>
                    <Grid item xs={3} container justifyContent="flex-end">
                    {isValidUser("UTENTE_INTERNO") &&(
                      <UploadDocumentMonitoraggio
                        idMonitoraggio={dettaglioPratica.idMonitoraggio}
                        idTipologia={folder.idTipologia}
                        onUpload={handleUploadSuccess}
                        disabled={dettaglioPratica.status==="CHIUSA"}
                      />)}
                    </Grid>
                  </Grid>
                  <AccordionDetails sx={{ backgroundColor: theme.palette.grey.light }}>
                    <Grid container spacing={4}>
                      {folder.children && folder.children.length > 0 ? (
                        folder.children.map((file, fileIndex) => (
                          <Grid item key={fileIndex}>
                            <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                              <Tooltip title={file.name}>
                                <IconButton onClick={() => handleClick(file)}>
                                  <SummarizeIcon />
                                </IconButton>
                              </Tooltip>
                              {selectedFile === file && (
                                <Stack direction="row" spacing={1} sx={{ ml: 2 }}>
                                  <Tooltip title="Visualizza">
                                    <IconButton onClick={() => handleView(file)}>
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Scarica">
                                    <IconButton onClick={() => handleDownload(file)}>
                                      <FileDownloadIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {isValidUser("UTENTE_INTERNO") &&(
                                  <Tooltip title="Elimina">
                                    <IconButton onClick={() => handleDelete(file)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>)}
                                </Stack>
                              )}
                            </ListItem>
                          </Grid>
                        ))
                      ) : (
                        <Typography sx={{ marginTop: 2, ml: 2 }}>Nessun file presente</Typography>
                      )}
                    </Grid>
                  </AccordionDetails>
                </List>
              ))
            )}
          </AccordionDetails>
        </Accordion>
      </Accordion>
      <Modal
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={openModal}>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
          >
            {pdfData && (
              <PDFViewerMonitoraggio
                open={openModal}
                onClose={handleClose}
                document={pdfData}
                title={selectedFile.name}
              />
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default AccordionDettaglioMonitoraggio;
