import { customAxios } from './customAxios'
export function getRichiesteSupportoAml(tokenString) {
     tokenString = localStorage.getItem('token');
    const token = tokenString ? JSON.parse(tokenString) : ""; // Provide a default empty string if null
    const config = {
        headers: {
            Accept: "*/*",
            'AuthToken': token, // Now guaranteed to be a string
        },
    };
    

    const uri = process.env.REACT_APP_BACKEND_HOST + `/alfresco/getRichiesteSupportoAml?token=`+token;
    const getRichiesteSupportoRequest = customAxios.get(uri, config).then(response =>  response.data.body)
    return getRichiesteSupportoRequest;
}