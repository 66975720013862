import { customAxios } from './customAxios'

export function closePratica(idNodoPratica){
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    } 

   const closePraticaRequest = 
            customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/alfresco/closePratica?idNodoPratica="+idNodoPratica, null ,config)
   return closePraticaRequest





}