import { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import {
  Stack,
  Grid,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
  Modal,
  Container,
  InputAdornment,
  Tooltip,
  Box,
  Paper,
} from '@mui/material';
import CustomModal from '../UI/CustomModal';
import { useQuery } from '@tanstack/react-query';
import PDFViewer from '../Charts/PDFViewer';
import Header from '../UI/Header';
import { useTheme } from '@mui/material/styles';
import { downloadDocument } from '../services/downloadDocument';
import { StatiPratica } from '../utils/StatiPratica';
import AdeguataVerificaFormModal from './components/AdeguataVerificaFormModal';
import { updateFolder } from '../services/updateFolder';
import { annullamentoPratica } from '../services/annullamentoPratica';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccordionDettaglioMonitoraggio from './components/AccordionDettaglioMonitoraggio';
import { getPraticheDettaglio } from '../services/getPraticheDettaglio';
import Footer from '../UI/FooterMonitoraggio';
import DataTable from '../Charts/tb';
import DeleteIcon from '@mui/icons-material/Delete';
import { isValidUser } from '../OnlyWithFunctions';
import { searchMonitoring } from '../services/searchMonitoring'; // Importa la nuova funzione

const DettaglioMonitoraggio = ({  }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [listaPratiche, setListaPratiche] = useState([]);
  
  const [openCustomModal, setOpenCustomModal] = useState(false);

 
  const [errorState, setErrorState] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [refetchInterval, setRefetchInterval] = useState(0);
  const [pdfData, setPdfData] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const modalTableStyle = {
    position: 'absolute',
    top: '20',
    left: '20',
    transform: 'translate(10%, 10%)',
    width: '1300px',
    mb: '200px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 0,
    p: 2,
    borderRadius: 2,
  };

  const tableStyle = {
    transform: 'translate(30%, -40%)',
    maxHeight: 800,
    width: 900,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 20,
    borderRadius: 2,
  };

  const { data: praticheDettaglio, isFetching: isFetchingPraticheDettaglio, isLoading: isLoadingPraticheDettaglio, isFetched: isFetchedPraticheDettaglio, refetch: refetchPraticheDettaglio } = useQuery(
    ['pratiche_dettaglio', location.state?.selectedRowData.idMonitoraggio],
    () => searchMonitoring(location.state?.selectedRowData.ragioneSociale),
    { refetchInterval: refetchInterval }
  );





  const handleOpenModal = () => setOpenModal(!openModal);

  const clickDoc = (event, idFile, filename) => {
    event.preventDefault();
    if (!pdfData || pdfData.title !== filename) {
      downloadDocument(idFile, false).then(res =>
        setPdfData({ title: filename, document: res })
      );
    } else {
      setPdfData(undefined);
    }
  };





  useEffect(() => {
    if (praticheDettaglio) {
      setListaPratiche(location.state?.selectedRowData);
    }
  }, [praticheDettaglio]);

  return (
    <>
      <Header
        showHome
        showRichiesta={false}
        showDashMonitoraggio={true}
        idNodoPratica={location.state?.selectedRowData.idMonitoraggio}
        praticaProperties={location.state?.selectedRowData}
        header={
          <Box
            sx={{
              width: 1000,
              height: 70,
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                marginBottom: '1rem',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  flexDirection: 'row',
                  width: '80%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  mr: '10px',
                  ml: '155px'
                }}
              >
                <Tooltip title={location.state?.selectedRowData.ragioneSociale}>
                  <Box item xs={3} sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.ragioneSociale}
                      id="standard-basic"
                      helperText={<Typography variant="caption">Ragione Sociale</Typography>}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                            <ContentCopyIcon onClick={() => navigator.clipboard.writeText(location.state?.selectedRowData.ragioneSociale)} />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={location.state?.selectedRowData.codiceFiscale}>
                  <Box sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.codiceFiscale}
                      id="standard-basic"
                      helperText="Codice Fiscale"
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
           
                <Tooltip title={location.state?.selectedRowData.gestore}>
                  <Box sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.gestore}
                      id="standard-basic"
                      helperText="Gestore"
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip title={location.state?.selectedRowData.ndg}>
                  <Box sx={{ flexDirection: 'row' }}>
                    <TextField
                      disabled
                      value={location.state?.selectedRowData.ndg}
                      id="standard-basic"
                      helperText="NDG"
                      variant="standard"
                      sx={{ width: '95%' }}
                    />
                  </Box>
                </Tooltip>
              </Box>

            </Box>
          </Box>
        }
      />

      <Grid item container xs={12} direction="row" justifyItems="space-between" alignItems="flex-start" mt={14}>
        <Grid item xs={7}>
          <Stack sx={{ alignItems: 'center' }}>
            
              <AccordionDettaglioMonitoraggio
                key={location.state?.selectedRowData.idMonitoraggio}
                idNodoPratica={location.state?.selectedRowData.idMonitoraggio}
                dettaglioPratica={location.state?.selectedRowData}
                clickDoc={clickDoc}
                refetchPraticheDettaglio={refetchPraticheDettaglio}
              />
            
          </Stack>
        </Grid>
        <Footer idNodoPratica={location.state?.selectedRowData.idMonitoraggio} />
        {/* {pdfData && (
          <Grid item xs={5} component="div" sx={{ overflow: 'auto', maxWidth: '50%', maxHeight: '50%' }}>
            <PDFViewer document={pdfData.document} title={pdfData.title} />
          </Grid>
        )} */}
        {/* <Modal
          hideBackdrop
          open={openModal}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'fit-content',
            transform: 'translate(-50%, -50%)',
            width: 'fit-content',
            border: '1px solid #000',
            boxShadow: 0,
            p: 2,
            backgroundColor: '#ffff',
            paddingTop: '0px',
          }}
        >
          <AdeguataVerificaFormModal
            idNodoPratica={idPraticaDuplicata || location.state?.selectedRowData.idMonitoraggio}
            loading={loading}
            statoPratica={statoRichiesta}
            disabledButton={disabledButton}
            defaultValues={valoriPraticaDuplicata || valoriModale}
            onClick={(event, params) => handleModifica(event, params)}
            errorState={errorState}
            errorCF={errorCF}
            responseDettaglioRelazione={responseDettaglioRelazione}
            handleViewModal={() => setOpenModal(false)}
          />
        </Modal> */}
        {/* <CustomModal open={openCustomModal} loading={loading} error={errorState} message={infoMessage} /> */}
      </Grid>
      {/* {startPol && (
        <Paper variant="outlined" sx={tableStyle} onMouseLeave={openAdvAutomatica} square>
          <DataTable />
        </Paper>
      )} */}
    </>
  );
};

export default DettaglioMonitoraggio;
