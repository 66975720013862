import React, { useState, useContext, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getListaUtenzeAssegnatari } from '../services/getListaUtenzeAssegnatari'
import TransitionComponentSnackbar from '../UI/CustomSnackbar.jsx'

import {
  Box,
  Typography,
  TextField,
  Container,
  createTheme,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'

import { StyledButton } from '../UI/StyledButton'
import { postLogin } from '../services/login.js'
import { getListe } from '../services/getListe.js'

import { AuthContext } from '../App'
import LogoDividerContainer from '../containers/LogoDividerContainer'
import { ThemeProvider } from '@emotion/react'
const loginTheme = createTheme({ typography: { fontSize: 8 } })

const Login = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const [emailIsValid, setEmailIsValid] = useState(true)
  const [passwordIsValid, setPasswordIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [errorCode, setErrorCode] = useState()


  const { dispatch } = useContext(AuthContext)
  const [listaUtentiDaAssegnare, setListaUtentiDaAssegnare] = useState(false)
  const initialState = {
    email: '',
    password: '',
    isSubmitting: false,
    errorMessage: null,
  }
  const [data, setData] = useState(initialState)
  //fetch reperimento lista utenze assegnatari
  const {
    data: utenzeAssegnatari,
    isFetching: FetchingUtenzeAssegnatari,
    isLoading: LoadingUtenzeAssegnatari,
    isFetched: FetchedUtenzeAssegnatari,
  } = useQuery(['DaAssegnareA'], () => getListaUtenzeAssegnatari())
  useEffect(() => {
    if (listaUtentiDaAssegnare) {
      localStorage.setItem('DaAssegnareA', JSON.stringify(utenzeAssegnatari !== undefined ? utenzeAssegnatari : []))
    }
  }, [utenzeAssegnatari, listaUtentiDaAssegnare])

  useEffect(() => {
    try {
      if (data.password === ' ') {
        setPasswordIsValid(false)
      } else {
        setPasswordIsValid(true)
      }
    } catch (error) {
      // console.log(error)
    }

    return () => setPasswordIsValid(false)
  }, [data.password])
  // console.log(passwordIsValid)

  // console.log(data.password)

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = event => {
    
    setListaUtentiDaAssegnare(true)
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    postLogin(data.password, data.email)
      .then(response => {
        if (response.data.code === 200) {
          dispatch({
            type: 'LOGIN',
            payload: response.data.body,
          })
          getListe().then(response => {
            if (response.data.code === 200) {
              // console.log(response.data)

              localStorage.setItem(
                'TipologiaCliente',
                JSON.stringify(response.data.body.TIPOLOGIA_CLIENTE),
              )

              localStorage.setItem(
                'TipologiaDiRichiesta',
                JSON.stringify(response.data.body.TIPOLOGIA_DI_RICHIESTA),
              )

              localStorage.setItem(
                'DocumentiOpzionaliVari',
                JSON.stringify(response.data.body.DOCUMENTI_OPZIONALI_VARI),
              )
              localStorage.setItem(
                'VerificaRafforzataInRelazioneA',
                JSON.stringify(
                  response.data.body.VERIFICA_RAFFORZATA_IN_RELAZIONE_A,
                ),
              )
              localStorage.setItem(
                'MotivazioniDellAdeguataVerificaRafforzataArUtenteInterno',
                JSON.stringify(
                  response.data.body
                    .MOTIVAZIONI_DELL_ADEGUATA_VERIFICA_RAFFORZATA_AUTOMATICA_AR_UTENTEINTERNO,
                ),
              )
              localStorage.setItem(
                'MotivazioniDellAdeguataVerificaRafforzataAutomaticaMrUtenteInterno',
                JSON.stringify(
                  response.data.body
                    .MOTIVAZIONI_DELL_ADEGUATA_VERIFICA_RAFFORZATA_AUTOMATICA_MR_UTENTEINTERNO,
                ),
              )
              localStorage.setItem(
                'MotivazioniDellAdeguataVerificaRafforzataArUtenteBase',
                JSON.stringify(
                  response.data.body
                    .MOTIVAZIONI_DELL_ADEGUATA_VERIFICA_RAFFORZATA_AUTOMATICA_AR_UTENTEBASE,
                ),
              )

              localStorage.setItem(
                'RichiestaAgUif',
                JSON.stringify(response.data.body.RichiestaAgUif),
              )
              localStorage.setItem(
                'ControlliAmleGianos',
                JSON.stringify(response.data.body.ControlliAmleGianos),
              )
              localStorage.setItem(
                'nonSonoEmersiAltriElementi',
                JSON.stringify(response.data.body.nonSonoEmersiAltriElementi),
              )
              localStorage.setItem(
                'svolgeAttivitaSpecifica',
                JSON.stringify(response.data.body.svolgeAttivitaSpecifica),
              )
              localStorage.setItem(
                'nonSvolgeAttivitaSpecifica',
                JSON.stringify(response.data.body.nonSvolgeAttivitaSpecifica),
              )
            }
          })
        }

      })
      .catch(error => {
        localStorage.setItem("resmes",error.response.data.message)
        localStorage.setItem("rescode",error.response.data.code)
        // // console.log("errore:", error.response.data)
        setData({
          email: '',
          password: '',
          isSubmitting: false,
          isAuthenticated: false,
          errorMessage: 'Username o Password errata',
        })
      })
  }
  return (
    <>
      <div style={{ position: "relative" }}>
        <TransitionComponentSnackbar resmes={localStorage.getItem("resmes")?localStorage.getItem("resmes"):""} rescode={localStorage.getItem("rescode")?localStorage.getItem("rescode"):""} />
        <Header
          header={
            <Typography
              sx={{ flexGrow: 1, color: theme.palette.black.main }}
              xs={12}
              item
              alignItems="center"
              variant="h1"
            >
              ADEGUATA VERIFICA
            </Typography>
          }
        />
        <LogoDividerContainer showLogo={true}>
          <Box
            component="form"
            noValidate
            onSubmit={handleFormSubmit}
            ml="20em"
            sx={{ width: '75%' }}
          >
            <TextField
              sx={{ marginTop: '40px' }}
              margin="normal"
              required
              fullWidth
              value={data.email}
              variant="standard"
              helperText="Email di login"
              id="email"
              name="email"
              autoComplete="email"
              onChange={handleInputChange}
              autoFocus
            />
            <TextField
              margin="normal"
              helperText="Password"
              required
              value={data.password}
              fullWidth
              variant="standard"
              name="password"
              type="password"
              id="password"
              onChange={handleInputChange}
              autoComplete="current-password"
            />

            {data.errorMessage && (
              <span className="form-error">{data.errorMessage}</span>
            )}
            <div>
              <a href={process.env.REACT_APP_ALFRESCO_RESET_PASSWORD} target="_blank">Password dimenticata?</a>
            </div>
            <Container
              sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}
            >
              <StyledButton
                type="submit"
                sx={{ mt: 15, mb: 2 }}
                disabled={data.isSubmitting || (!emailIsValid && passwordIsValid)}
              >
                {data.isSubmitting ? 'Loading...' : 'LOGIN'}
              </StyledButton>
            </Container>
            <ThemeProvider theme={loginTheme}>
              <Typography
                sx={{ position: 'fixed', bottom: '10px', marginTop: '6em' }}
              >
                COMPLIFY è un marchio Esquad s.r.l. con sede legale in via XXIV
                Maggio 16, Cosenza CF/PI 10229340962
              </Typography>
            </ThemeProvider>
          </Box>
        </LogoDividerContainer>
      </div>
    </>
  )
}

export default Login
