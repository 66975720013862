import axios from 'axios'
export const customAxios = axios.create(
    {
        baseURL: process.env.REACT_APP_BACKEND_HOST,
    }
)

customAxios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 511 && window.location.pathname != '/sessione-scaduta') {
            window.location.href = '/sessione-scaduta';
        }
        // if (error.response?.status === 500) {
        //     debugger
        //     // Se il server restituisce un errore 500, reindirizza alla pagina di login
        //     window.location.href = '/sessione-scaduta';
        // }
        return Promise.reject(error);
    });