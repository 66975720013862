import { customAxios } from './customAxios'
export function getPraticheDettaglio(idNodoPratica) {
    const config = {
        params: { idNodoPratica: idNodoPratica.toString() },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const getPraticheDettaglioRequest =  customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/alfresco/getPraticheDettaglio?`, config).then(response => response.data.body)
    return getPraticheDettaglioRequest
}