import { customAxios } from './customAxios'
export function getChartData(tipoGrafico, praticheChiuse) {
    const config = {
        params: { campoPratica: tipoGrafico , praticheChiuse: praticheChiuse},
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const chartDataRequest =
        customAxios.get(process.env.REACT_APP_BACKEND_HOST + `/alfresco/getStatisticheByCampoPratica?`, config).then(response => response.data.body)
    return chartDataRequest
}