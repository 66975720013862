import React from 'react'
import { Stack, Grid, FormControl, Box } from '@mui/material'
import LogoComponent from '../UI/LogoComponent'
import DotDivider from '../UI/DotDivider'
import { Divider } from '@mui/material'

const LogoDividerContainer = (props) => {
    return (
        <Grid xs={12} container item direction='row' sx={{ marginTop: '2%', }} alignContent='center' justifyContent='start'>
            {props.showLogo ?
                <Grid item xs={6}>
                    <LogoComponent />
                </Grid> : <Grid item xs={6} />}
            <Divider orientation='vertical' flexItem />
            <Grid item xs={4}>
                <FormControl >
                    <Grid container component="main" sx={{ height: '100%', mt: '2%' }}>
                        <Grid item xs={12} >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                {props.children}
                            </Box>
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
        </Grid >
    )
}


export default LogoDividerContainer