import { useState, useEffect, useLayoutEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import React from 'react'
import {
  Stack,
  Grid,
  Typography,
  TextField,
  IconButton,
  CircularProgress,
  Modal,
  Container,
  InputAdornment,
  Tooltip,
  Box,
  Paper,
} from '@mui/material'
import CustomModal from '../UI/CustomModal'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import PDFViewer from '../Charts/PDFViewer'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'
import { downloadDocument } from '../services/downloadDocument'
import { StatiPratica } from '../utils/StatiPratica'
import AdeguataVerificaFormModal from './components/AdeguataVerificaFormModal'
import { updateFolder } from '../services/updateFolder'
import {annullamentoPratica} from '../services/annullamentoPratica'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AccordionDettaglio from './components/AccordionDettaglio'
import { getPraticheDettaglio } from '../services/getPraticheDettaglio'
import { getIndicatoriAdvAutomatica } from '../services/getIndicatoriAdvAutomatica'
import Footer from '../UI/Footer'
import DataTable from '../Charts/tb'
import DeleteIcon from '@mui/icons-material/Delete';
import { isValidUser } from '../OnlyWithFunctions'

const Dettaglio = ({ note }) => {
  const theme = useTheme()
  const modalTableStyle = {
    position: 'absolute',
    top: '20',
    left: '20',
    transform: 'translate(10%, 10%)',
    width: '1300px',
    mb: '200px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 0,
    p: 2,
    borderRadius: 2,
  }
  const tableStyle = {
    transform: 'translate(30%, -40%)',
    maxHeight: 800,
    width: 900,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 20,
    borderRadius: 2,
  }
  const location = useLocation()

  const {
    data: indicatoriPratica,
    isFetching: isFetchingIndicatoriPratica,
    isLoading: isLoadingIndicatoriPratica,
    isFetched: isFetchedIndicatoriPratica,
  } = useQuery(
    ['indicatori_Pratica', location.state?.selectedRowData.entry.id],
    () => getIndicatoriAdvAutomatica(location.state?.selectedRowData.entry.id),
    {
      enabled:
        location.state?.selectedRowData.entry.properties[
        'folder_model:tipoInserimento'
        ] === 'Automatica' || location.state?.selectedRowData.entry.properties[
        'folder_model:tipoInserimento'
        ] === 'Automatica Medio Rischio' || location.state?.selectedRowData.entry.properties[
        'folder_model:tipoInserimento'
        ] === 'Automatica Alto Rischio',
    },
  )

  const [startPol, setStartPol] = useState(false)
  const [open, setOpen] = useState(false)
  const [showDuplicaAdv, setShowDuplicaAdv] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [openCustomModal, setOpenCustomModal] = useState(false)
  const [paramsRelazione, setParamsRelazione] = useState('')
  const [refetchPostUpdateFolder, setRefetchPostUpdateFolder] = useState(false)
  const [selectedRowData, setSelectedRowData] = useState(undefined)
  const [idPraticaDuplicata, setIdPraticaDuplicata] = useState('')
  const [valoriPraticaDuplicata, setValoriPraticaDuplicata] = useState(undefined)

  const [showPDF, setShowPDF] = useState({
    cartaIdentita: false,
    listeAML_MASI: false,
    listeAML_SCARPELLI: false,
    protestiDiBrisco: false,
    protestiScarpelli: false,
    TEEsquad: false,
    VisuraEsquad: false,
    camerale: false,
    sociEsponenti: false,
    paolo_scaroni: false,
    starfire: false,
    starfireServices: false,
  })

  const [formValues, setFormValues] = useState({
    NDG: undefined,
    ragioneSociale: undefined,
    codiceFiscale: undefined,
    indirizzo: undefined,
    nazione: undefined,
    dataUltima: undefined,
    esito: undefined,
    note: undefined,
  })
  const [refetchInterval, setRefetchInterval] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    // debugger
    if (refetchPostUpdateFolder === true) {
      // console.log(refetchPostUpdateFolder)
      setRefetchInterval(1000)
      setTimeout(function () {
        setRefetchInterval(0)
        setRefetchPostUpdateFolder(false)
      }, 4000)
    }
  }, [refetchPostUpdateFolder])

  const handleOpen = () => setOpen(true)
  const [openModal, setOpenModal] = useState(false)

  const [statoRichiesta, setStatoRichiesta] = useState(
    location.state?.selectedRowData.entry.properties[
    'folder_model:statorichiesta'
    ],
  )

  const [valoriModale, setValoriModale] = useState(
    location.state?.selectedRowData.entry.properties,
  )
  const [errorState, setErrorState] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [errorCF, setErrorCF] = useState(false)
  const [listaPratiche, setListaPratiche] = useState([])

  const [responseIndirizzo, setResponseIndirizzo] = useState('')
  const [responseRagioneSociale, setResponseRagioneSociale] = useState('')
  const [responseCodiceFiscale, setResponseCodiceFiscale] = useState('')
  const [responseNazione, setResponseNazione] = useState('')
  const [responseNdg, setResponseNdg] = useState('')
  const [responseRelazione, setResponseRelazione] = useState('')
  const [responseDettaglioRelazione, setResponseDettaglioRelazione] =
    useState('')

  const {
    data: praticheDettaglio,
    isFetching: isFetchingPraticheDettaglio,
    isLoading: isLoadingPraticheDettaglio,
    isFetched: isFetchedPraticheDettaglio,
    refetch: refetchPraticheDettaglio,
  } = useQuery(
    ['pratiche_dettaglio', location.state?.selectedRowData.entry.id],
    () => getPraticheDettaglio(location.state?.selectedRowData.entry.id),
    { refetchInterval: refetchInterval },
  )

  useEffect(() => {
    if (praticheDettaglio && praticheDettaglio.length > 0) {
      const lastPratica = praticheDettaglio[praticheDettaglio.length - 1].entry.properties
      setResponseIndirizzo(lastPratica['folder_model:indirizzo'] || '')
      setResponseRagioneSociale(lastPratica['folder_model:ragioneSociale'] || '')
      setResponseCodiceFiscale(lastPratica['folder_model:codiceFiscale'] || '')
      setResponseNazione(lastPratica['folder_model:nazione'] || '')
      setResponseNdg(lastPratica['folder_model:NDG'] || '')
      setResponseRelazione(lastPratica['folder_model:relazione'] || '')
      setResponseDettaglioRelazione(lastPratica['folder_model:dettaglioRelazione'] || '')
      setValoriModale(lastPratica)
    }
  }, [praticheDettaglio])

  const addPraticaToDettaglio = pratica => {
    // debugger
    if (praticheDettaglio) {
      setListaPratiche(buildListaPratiche([...praticheDettaglio, pratica]))
      setRefetchPostUpdateFolder(true)
      setShowDuplicaAdv(false)
    }
  }

  const buildListaPratiche = pratiche => [...new Map(pratiche.filter(p => p).map(p => [p.entry.id, p])).values()]
  //sincronizzazione form del modale modifica
  //puntamento alla pratica duplicata avente in richiesta o in lavorazione(modificabile)
  useEffect(() => {
    if (listaPratiche && listaPratiche?.length > 1) {
      for (var i = 0; i < listaPratiche?.length; i++) {
        if (listaPratiche[i].entry.properties["folder_model:statorichiesta"] === "In Richiesta" ||
          listaPratiche[i].entry.properties["folder_model:statorichiesta"] === "In Lavorazione") {
          setIdPraticaDuplicata(listaPratiche[i]?.entry?.id)
          setValoriPraticaDuplicata(listaPratiche[i].entry?.properties)
          setShowDuplicaAdv(false)
        }
        else if (listaPratiche[listaPratiche?.length - 1].entry.properties["folder_model:statorichiesta"] === StatiPratica.chiusa
        ) {
          setShowDuplicaAdv(true)
        }
      }
    }

    else if (listaPratiche && praticheDettaglio && listaPratiche?.length !== praticheDettaglio?.length) {
      for (var i = 0; i < praticheDettaglio?.length; i++) {
        if (praticheDettaglio[praticheDettaglio?.length - 1]?.entry.properties["folder_model:statorichiesta"] === StatiPratica.chiusa) {
          setShowDuplicaAdv(true)
        }
      }
    }
  }, [listaPratiche, addPraticaToDettaglio]
  )

  useEffect(() => {
    const atLeastOneClosed =
      praticheDettaglio &&
      praticheDettaglio.filter(
        pratica =>
          pratica && pratica.entry?.properties['folder_model:dataChiusura'],
      ).length > 0

    if (praticheDettaglio) {
      setListaPratiche(buildListaPratiche([...listaPratiche, ...praticheDettaglio]))

    }

  }
    , [praticheDettaglio])

    //`apici inversi`
    //annullamento pratica
    function handleAnnullaPratica(){
      // debugger
        annullamentoPratica(location.state?.selectedRowData.entry.id).then(response => {
          if (response.data.code === 200) {
            //pop up messaggio di avvenuta cancellazione e reindirizzamento alla dashboard
            setOpenCustomModal(true)
            setErrorState(false)
            setInfoMessage(`Annullamento avvenuto con successo`)
            setTimeout(function () {
              setOpenCustomModal(false)
              navigate('/')
            }, 4000)
          }
        }).catch(error => {
          //pop up messaggio di errore 
          setOpenCustomModal(true)
          setErrorState(true)
          if (error.response.data.code !== 200) {
            setInfoMessage(error.response.data.message)
            setErrorCF(true)
          } else {
            setInfoMessage(`Errore durante l'annullamento della pratica`)
          }
          setTimeout(function () {
            setOpenCustomModal(false)
          }, 4000)
        })
    }

  // controllo modale modifica
  const handleModifica = (event, params) => {
    
    params = Object.assign({ ...params, statoRichiesta: statoRichiesta });
  
    if (params !== undefined) {
      // Controlla se tipoInserimento contiene 'Automatica'
      const isAutomatica = params.tipoInserimento && params.tipoInserimento.includes('Automatica');
  
      if (
        (!isAutomatica && params.NDG === '') ||  // Controlla NDG solo se non è 'Automatica'
        params.ragioneSociale === '' ||
        params.Citta === '' ||
        params.indirizzo === '' ||
        params.nazione === '' ||
        params.daAssegnare === '' ||
        params.daAssegnare === null
      ) {
        setInfoMessage('COMPLETARE CAMPI OBBLIGATORI');
        setOpenCustomModal(true);
        setErrorState(true);
        setTimeout(function () {
          setInfoMessage('');
          setOpenCustomModal(false);
        }, 4000);
      } else if (
        (params.relazione !== undefined || params.relazione !== null || params.relazione !== '') &&
        (params.relazione.includes('Altro')) &&
        (params.dettaglioRelazione === null || params.dettaglioRelazione?.trim() === '')
      ) {
        setInfoMessage('Inserire dettaglio della relazione');
        setOpenCustomModal(true);
        setErrorState(true);
        setTimeout(function () {
          setInfoMessage('');
          setOpenCustomModal(false);
        }, 4000);
      } else if (
        (params.codiceFiscale?.trim() === '' || !params.codiceFiscale) &&
        (params.nazione?.trim().toLowerCase() === 'it' ||
          params.nazione?.trim().toLowerCase() === 'italia')
      ) {
        setInfoMessage('Completare Codice Fiscale');
        setOpenCustomModal(true);
        setErrorState(true);
        setTimeout(function () {
          setInfoMessage('');
          setOpenCustomModal(false);
        }, 1000);
      } else if (params.ragioneSociale.length > 100) {
        setInfoMessage('Ragione Sociale non può contenere più di 100 caratteri!');
        setOpenCustomModal(true);
        setErrorState(true);
        setTimeout(function () {
          setInfoMessage('');
          setOpenCustomModal(false);
        }, 4000);
      } else if (
        params.documentiOpzionaliVari !== null &&
        params.documentiOpzionaliVari.length !== 0 &&
        (params.motivazioneRichiesteOpzionali === '' ||
          params.motivazioneRichiesteOpzionali === null)
      ) {
        setInfoMessage('Completare Motivazioni richeste opzionali');
        setOpenCustomModal(true);
        setErrorState(true);
        setTimeout(function () {
          setInfoMessage('');
          setOpenCustomModal(false);
        }, 4000);
      } else {
        debugger;
        setInfoMessage('');
        setOpenCustomModal(true);
        setLoading(true);
        updateFolder(params)
          .then(response => {
            if (response.data.code === 200) {
              setRefetchPostUpdateFolder(true);
              setDisabledButton(true);
              setOpenCustomModal(true);
              setLoading(false);
              setErrorState(false);
              setInfoMessage(response.data.message);
              setValoriModale(response.data.body.properties);
              setFormValues({
                ...formValues,
                [formValues.dettaglioRelazione]:
                  response.data.body.properties['folder_model:dettaglioRelazione'],
              });
              setResponseIndirizzo(response.data.body.properties['folder_model:indirizzo']);
              setResponseRagioneSociale(response.data.body.properties['folder_model:ragioneSociale']);
              setResponseDettaglioRelazione(response.data.body.properties['folder_model:dettaglioRelazione']);
              setResponseCodiceFiscale(
                !response.data.body.properties['folder_model:codiceFiscale']
                  ? ''
                  : response.data.body.properties['folder_model:codiceFiscale'],
              );
              setResponseNazione(response.data.body.properties['folder_model:nazione']);
              setResponseNdg(response.data.body.properties['folder_model:NDG']);
              setResponseRelazione(response.data.body.properties['folder_model:relazione']);
  
              setTimeout(function () {
                setOpenCustomModal(false);
              }, 3000);
  
              setTimeout(function () {
                setOpenModal(false);
                setDisabledButton(false);
              }, 2000);
            }
          })
          .catch(error => {
            setLoadingModal(false);
            setErrorState(true);
            if (error.response.data.code === 403) {
              setInfoMessage(error.response.data.message);
              setLoadingModal(false);
              setErrorCF(true);
            } else {
              setInfoMessage("ERRORE NELLA CREAZIONE DELL'ADVR");
            }
          });
      }
    }
  };
  

  const handleOpenModal = () => setOpenModal(!openModal)

  const [pdfData, setPdfData] = useState(undefined)


  useLayoutEffect(() => {
    setFormValues({
      ragioneSociale:
        location.state?.selectedRowData.entry.properties[
        'folder_model:ragioneSociale'
        ],
      NDG: location.state?.selectedRowData.entry.properties['folder_model:NDG'],
      
      gruppoUtenteCreatore: location.state?.selectedRowData.entry.properties['folder_model:gruppoUtenteCreatore'],

      codiceFiscale:
        location.state?.selectedRowData.entry.properties[
        'folder_model:codiceFiscale'
        ],
      indirizzo:
        location.state?.selectedRowData.entry.properties[
        'folder_model:indirizzo'
        ],
      nazione:
        location.state?.selectedRowData.entry.properties[
        'folder_model:nazione'
        ],

      dataUltima: location.state?.selectedRowData.dataUltima,

      documentiOpzionaliVari:
        location.state.selectedRowData.entry.properties[
        'folder_model:documentiOpzionaliVari'
        ],

      esito: location.state?.selectedRowData.esito,

      note: location.state?.selectedRowData.note,
    })
    return () => {
      setFormValues({
        ragioneSociale: undefined,
        codiceFiscale: undefined,
        indirizzo: undefined,
        nazione: undefined,
        dataUltima: undefined,
        esito: undefined,
        note: undefined,
      })
    }
  }, [formValues.ragioneSociale])

  useLayoutEffect(() => handleOpen(), [showPDF])

  const clickDoc = (event, idFile, filename) => {
    // debugger
    event.preventDefault()
    // console.log('Cliccato file con id: ', idFile)
    if (!pdfData || pdfData.title !== filename)
      downloadDocument(idFile, false).then(res =>
        setPdfData({ title: filename, document: res }),
      )
    else setPdfData(undefined)
  }

  const copiaRagioneSociale = () => {
    navigator.clipboard.writeText(formValues.ragioneSociale).then(function () {
      // console.error()
    })
  }

  const syncStatoRichiesta = statoRichiesta => {
    //stato richiesta passato dall'accordion dettaglio
    // debugger
    setStatoRichiesta(statoRichiesta)
  }

  //handle passato ad accordion dettaglio per l'apertura/chiusura tabella
  const openAdvAutomatica = startPol => {
    // debugger
    setStartPol(!startPol)
  }


  return (
    <>
      <Header
        showHome
        showRichiesta={false}
        showDuplicaAdv={showDuplicaAdv}
        idNodoPratica={location.state?.selectedRowData.entry.id}
        praticaProperties={
          praticheDettaglio
            ? praticheDettaglio[0].entry?.properties
            : location.state?.selectedRowData?.entry.properties
        }
        addPraticaToDettaglio={addPraticaToDettaglio}
        header={
          <Box
            sx={{
              width: 1000,
              height: 70,
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              display: 'flex',
            }}
          ><>
              <Box
                sx={{
                  marginBottom: '1rem',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Box
                  sx={{
                    flexDirection: 'row',
                    width: '80%',
                    justifyContent: 'space-between',
                    display: 'flex',
                    mr: '10px',
                    ml: '155px'
                  }}
                >
                  <Tooltip title={responseRagioneSociale} >
                    <Box item xs={3} sx={{ flexDirection: 'row' }}>
                      <TextField
                        disabled
                        value={responseRagioneSociale}
                        id="standard-basic"
                        helperText={
                          <Typography variant="caption">
                            Ragione Sociale
                          </Typography>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ cursor: 'pointer' }}
                            >
                              <ContentCopyIcon onClick={copiaRagioneSociale} />
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                        sx={{ width: '95%' }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip title={responseCodiceFiscale} >
                    <Box sx={{ flexDirection: 'row' }}>
                      <TextField
                        disabled
                        value={responseCodiceFiscale}
                        id="standard-basic"
                        helperText="Codice Fiscale"
                        variant="standard"
                        sx={{ width: '95%' }}
                      />
                    </Box>
                  </Tooltip>

                  <Tooltip title={responseIndirizzo} >
                    <Box sx={{ flexDirection: 'row' }}>
                      <TextField
                        disabled
                        value={responseIndirizzo}
                        id="standard-basic"
                        helperText="Indirizzo"
                        variant="standard"
                        sx={{ width: '95%' }}
                      />
                    </Box>
                  </Tooltip>

                  <Tooltip title={responseNazione} >
                    <Box sx={{ flexDirection: 'row' }}>
                      <TextField
                        disabled
                        value={responseNazione}
                        id="standard-basic"
                        helperText="Nazione"
                        variant="standard"
                        sx={{ width: '95%' }}
                      />
                    </Box>
                  </Tooltip>
                  <Tooltip title={responseNdg} >
                    <Box sx={{ flexDirection: 'row' }}>
                      <TextField
                        disabled
                        value={responseNdg}
                        id="standard-basic"
                        helperText="NDG"
                        variant="standard"
                        sx={{ width: '95%' }}
                      />
                    </Box>
                  </Tooltip>

                </Box>
                <Box
                  sx={{ display: 'flex', mb: '22px', mr: '75px' }}
                  direction={'row'}
                >
                  
                  <IconButton
                    onClick={handleAnnullaPratica}
                    disabled={
                      (statoRichiesta !== StatiPratica.inLavorazione &&
                      statoRichiesta !== StatiPratica.inRichiesta) ||
                      statoRichiesta == StatiPratica.annullata
                    }
                  ><DeleteIcon />
                  </IconButton>
                  
                  <IconButton
                    onClick={handleOpenModal}
                    disabled={
                      (statoRichiesta !==StatiPratica.checkOutOperatore &&
                      statoRichiesta !== StatiPratica.infoComplete &&
                      statoRichiesta !== StatiPratica.inLavorazione &&
                      statoRichiesta !== StatiPratica.inRichiesta) ||
                      statoRichiesta == StatiPratica.annullata
                    }
                  ><EditIcon />
                  </IconButton>

                  
                </Box>
              </Box></>
          </Box>
        }
      />

      <Grid
        item
        container
        xs={12}
        direction="row"
        justifyItems="space-between"
        alignItems="flex-start"
        mt={14}
      ><>
          <Grid item xs={7}><>
            <Stack sx={{ alignItems: 'center' }}>
              {isFetchingPraticheDettaglio && (
                <CircularProgress></CircularProgress>
              )}
              {isFetchedPraticheDettaglio &&
                listaPratiche &&
                listaPratiche

                  .map(pratica => (
                    <>
                      <AccordionDettaglio
                        indicatoriPratica={
                          indicatoriPratica ? indicatoriPratica : []
                        }
                        isFetchedIndicatoriPratica={isFetchedIndicatoriPratica}
                        openTableState={startPol}
                        openAdvAutomatica={openAdvAutomatica}
                        refetchPostUpdateFolder={refetchPostUpdateFolder}
                        syncStatoRichiesta={syncStatoRichiesta}
                        idNodoPratica={pratica.entry?.id}
                        dettaglioPratica={pratica}
                        clickDoc={clickDoc}
                        refetchPraticheDettaglio={refetchPraticheDettaglio}
                        valueRelazioneModal={pratica?.entry.properties['folder_model:relazione']}
                        valueNDGModal={pratica?.entry.properties['folder_model:NDG']}
                      ></AccordionDettaglio></>
                  ))}
            </Stack></>
          </Grid>
          <Footer
            idNodoPratica={location.state?.selectedRowData.entry.id}
          ></Footer>

          {pdfData && (
            <Grid
              item
              xs={5}
              component="div"
              sx={{ overflow: 'auto', maxWidth: '50%', maxHeight: '50%' }}
            ><>
                <PDFViewer document={pdfData.document} title={pdfData.title} />
              </>
            </Grid>
          )}

          <Modal
            hideBackdrop
            open={openModal}
            s sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              height: '550px',
              width: '900px', 
              transform: 'translate(-50%, -50%)',
              maxWidth: '80vw', 
              maxHeight: '80vh', 
              overflowY: 'auto', 
              border: '1px solid #000',
              boxShadow: 0,
              p: 2,
              backgroundColor: '#ffff',
              paddingTop: '0px',
            }}
          ><>
              <AdeguataVerificaFormModal
                idNodoPratica={idPraticaDuplicata ? idPraticaDuplicata : location.state?.selectedRowData.entry.id}
                loading={loading}
                statoPratica={statoRichiesta}
                disabledButton={disabledButton}
                defaultValues={valoriPraticaDuplicata ? valoriPraticaDuplicata : valoriModale}
                onClick={(event, params) => handleModifica(event, params)}
                errorState={errorState}
                errorCF={errorCF}
                responseDettaglioRelazione={responseDettaglioRelazione}
                handleViewModal={() => setOpenModal(false)}
              /></>
          </Modal>
          <CustomModal
            open={openCustomModal}
            loading={loading}
            error={errorState}
            message={infoMessage} />
        </>

      </Grid>
      {startPol && (
        <Paper
          variant="outlined"
          sx={tableStyle}
          onMouseLeave={openAdvAutomatica}
          square
        >
          <DataTable indicatoriPratica={indicatoriPratica}></DataTable>
        </Paper>
      )}
    </>
  )
}
export default Dettaglio
