import { customAxios } from "./customAxios"
export function approveDocs(idMonitoraggio, approvazione) {
    const config = {
        headers: {
            Authtoken: JSON.parse(localStorage.getItem('token')),
            'Content-Type': 'application/json'
        }
    };

    const data = {
        idMonitoraggio: idMonitoraggio,
        approvazione: approvazione
      };
    const approveRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/adv/monitoraggio/documenti-opzionali", data, config)
    return approveRequest
}