import { customAxios } from './customAxios'
export function createNewFolder(idFolder, subFolderName) {
    const config = {
        params:  {
            idFolder: idFolder,
            subFolderName: subFolderName
        },
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const data = {
        "idFolder": idFolder,
        "subFolderName": subFolderName
    }
    
    const createNewFolder =  customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/alfresco/createFolder?", null, config)
    return createNewFolder
}