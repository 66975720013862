import * as React from 'react';
import { Transition } from 'react-transition-group';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { Snackbar } from '@mui/base/Snackbar';
import { Stack, Typography } from '@mui/material';

export default function TransitionComponentSnackbar(props) {

  const blue = {
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0066CC',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const StyledSnackbar = styled(Snackbar)`
    position: fixed;
    z-index: 5500;
    display: flex;
    right: 16px;
    top: 16px;
  `;

  const SnackbarContent = styled('div')(
    ({ theme }) => `
    position: relative;
    overflow: hidden;
    z-index: 5500;
    display: flex;
    left: auto;
    justify-content: space-between;
    max-width: 560px;
    min-width: 300px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 4px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: ${theme.palette.mode === 'dark'
        ? `0 2px 8px rgb(0 0 0 / 0.5)`
        : `0 2px 8px ${grey[200]}`
      };
    padding: 0.75rem;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
  
    & .snackbar-title {
      margin-right: 0.5rem;
    }
  
    & .snackbar-close-icon {
      cursor: pointer;
      font-size: 10px;
      width: 1.25rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    `,
  );

  const positioningStyles = {
    entering: 'translateX(0)',
    entered: 'translateX(0)',
    exiting: 'translateX(500px)',
    exited: 'translateX(500px)',
    unmounted: 'translateX(500px)',
  };

  const [open, setOpen] = React.useState(false);

  const [exited, setExited] = React.useState(true);

  const [responseMessage, setResponseMessage] = React.useState("");

  const nodeRef = React.useRef(null);

  React.useEffect(() => {
    if (localStorage.getItem("isPasswordExpiring") === "true" && localStorage.getItem("passwordExpired") === "false") {
      setOpen(true)
      setResponseMessage("Gentile utente la password è in scadenza,la preghiamo di aggiornarla!")
    }
    else if (props && props?.rescode && props?.resmes && props?.rescode === "423" && props?.resmes !== "") {
      setOpen(true)
      setResponseMessage(props.resmes)
    }
    console.log("local storage:", localStorage)
  }, [localStorage, props])

  const handleClose = (_, reason) => {
    setOpen(false)
    localStorage.setItem("resmes", "")
    localStorage.setItem("rescode", "")
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };
  const handleOnEnter = () => {
    setExited(false);
  };

  const handleOnExited = () => {
    setExited(true);
  };



  return (
    <React.Fragment>
      <StyledSnackbar
        open={open}
        onClose={handleClose}
        exited={exited}
      >
        <Transition
          timeout={{ enter: 400, exit: 400 }}
          in={open}
          appear
          unmountOnExit
          onEnter={handleOnEnter}
          onExited={handleOnExited}
          nodeRef={nodeRef}
        >
          {(status) => (
            <SnackbarContent
              style={{
                transform: positioningStyles[status],
                transition: 'transform 300ms ease',
              }}
              ref={nodeRef}
            >
              <Stack direction={'column'}>
                <div className="snackbar-title">{responseMessage} </div>
                <div>
                  <a href={process.env.REACT_APP_ALFRESCO_RESET_PASSWORD}
                    onClick={
                      () =>
                        setTimeout(function () {
                          handleClose();
                        }, 3000)}
                  >Aggiorna Password</a>
                </div>
              </Stack>
              <CloseIcon onClick={handleClose} className="snackbar-close-icon" />
            </SnackbarContent>
          )}
        </Transition>
      </StyledSnackbar>
    </React.Fragment >
  );
}

