import { customAxios } from './customAxios'
export function postLogin(password, user) {
  const config = {
    headers: {
      AuthToken: JSON.parse(localStorage.getItem('token')),
    },
  }

  const body = {
    password: password,
    userId: user,
  }
  const login = customAxios.post(
    process.env.REACT_APP_BACKEND_HOST + `/alfresco/login?`,

    body,
    config,
  )

  return login
}
