import {
  Box,
  Typography,
  Modal,
  useTheme,
  Stack,
  Grid,
  TextField,
  CircularProgress,
  MenuItem,
  Tooltip,
  ListItemText,
  Autocomplete,
  Checkbox,
} from '@mui/material'
import React, { useState } from 'react'
import { StyledButton } from './StyledButton'
import OnlyWithFunction, { isValidUser } from '../OnlyWithFunctions'
import { useQuery } from '@tanstack/react-query'
import { getUsersByID } from '../services/getUsersByID'
import { useEffect } from 'react'
import { duplicaAdv } from '../services/duplicaAdv'
import CustomModal from './CustomModal'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 6,
  width: '800px',
}

const listOptionalDocNames = JSON.parse(
  localStorage.getItem('DocumentiOpzionaliVari'),
)

const verificaRafforzataInRelazioneA = JSON.parse(
  localStorage.getItem('VerificaRafforzataInRelazioneA'),
)

const listaAutomaticaAR = JSON.parse(
  localStorage.getItem(
    'MotivazioniDellAdeguataVerificaRafforzataArUtenteInterno',
  ),
)
const listaAutomaticaARAlt = ["Apertura rapporto continuativo (KYC)", "Monitoraggio Rapporto Automatico", "ADVR scaduta primo anno", "ADVR scaduta anni successivi", "Altro"]


const listaAutomaticaMR = JSON.parse(
  localStorage.getItem(
    'MotivazioniDellAdeguataVerificaRafforzataAutomaticaMrUtenteInterno',
  ),
)
const listaAutomaticaMRAlt = ["Apertura rapporto continuativo (KYC)", "Monitoraggio Rapporto Automatico", "ADVR scaduta primo anno", "ADVR scaduta anni successivi", "Altro"]

const listaManualeUtenteBase = JSON.parse(
  localStorage.getItem('MotivazioniDellAdeguataVerificaRafforzataArUtenteBase'),
)

const tipologiaDiRichiesta = JSON.parse(
  localStorage.getItem('TipologiaDiRichiesta'),
)
const tipologiaDiRichiestaAlt = ['ADV ALTO RISCHIO AUTOMATICA','ADV MEDIO RISCHIO AUTOMATICA','ADV ALTO RISCHIO MANUALE',];


const tipologiaCliente = JSON.parse(localStorage.getItem('TipologiaCliente'))

/*const listRelazioneManuale = [
  'Sospetto di riciclaggio',
  'Operazione occasionale',
  'Esito Monitoraggio',
  'Richiesta UIF',
  'Sequestro',
  'Esibizione atti e documenti',
  'Precedente SOS',
  'Altro',
]
//rimosso campo : "Apertura rapporto continuativo"
const listRelazioneAutomatica = [
  'Apertura Rapporto Continuativo',
  'Monitoraggio rapporto',
  'ADV scaduta (primo anno)',
  'ADV scaduta (anni successivi al primo)',
  'Settore a rischio ALTO secondo policy BP',
  'Settore rischio MEDIO secondo policy BP',
  'Paese a rischio (Sede e residenza)',
  'Liste (Sanction, Terrorismo etcc) e bad press negative',
  'PEP o collegato PEP',
  'Presenza di PIL',
  'Precedente SOS',
  'Criticità in visura camerale o pregiudizievoli',
  'Società estera o Italiana partecipata da soc. estera',
  'Catena societaria opaca',
  'Presenza di Trust o fiduciaria',
  'Altro',
]

// DocumentiOpzionaliVari
const names = [
  'Ultimo Bilancio Ottico',
  'Catena Partecipativa con grafo',
  'Titolare Effettivo (PDF)',
  'Referenze Bancarie Nazionali',
  'Cariche e quote possedute dagli esponenti e soci in altre aziende',
  'Informazioni INPS',
  'Stato di Famiglia',
  'ADV Rafforzata Complify',
]
*/
const documentiPerAdeguataVerifica = [
  'Dati camerali, anagrafica impresa',
  "Protesti, pregiudizievoli e procedure concorsuali (sull'impresa)",
  'Riscontro liste AML (Impresa, TE Italia o estero, Bad Press, principali esponenti)',
  'TE (in alternativa TE Italia automatico, No Rea, estero',
  'Ultimo bilancio numerico depositato',
  "Visura catastale (sull'impresa oggetto di indagine)",
  'Dossier esponenti, cariche e soci (stessa impresa) nel caso di TE Italia',
  'TE Italia (automatico)',
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const DuplicaADVModal = props => {
  const [openCustomModal, setOpenCustomModal] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [existingOptionalDocName, setExistingOptionalDocName] = useState(
    props.docName && props.docName['folder_model:documentiOpzionaliVari']
      ? props.docName['folder_model:documentiOpzionaliVari']
      : [],
  )

  const [existingRelazione, setExistingRelazione] = useState(
    props.relazione && props.relazione['folder_model:relazione']
      ? props.relazione['folder_model:relazione']
      : [],
  )

  const [formValues, setFormValues] = useState({
    idNodoPratica: props.idNodoPratica ? props.idNodoPratica : '',
    nomePratica: props.praticaProperties
      ? props.praticaProperties['folder_model:nomePratica']
      : '',
    tipologiaDiRichiesta: props.praticaProperties
      ? props.praticaProperties['folder_model:tipologiaDiRichiesta']
      : undefined,
    tipologiaOperativita: props.praticaPropertiesx
      ? props.praticaProperties['folder_model:tipologiaOperativita']
      : '',
    relazione: props.praticaProperties
      ? props.praticaProperties['folder_model:relazione']
      : '',
    motivazioneRichiesteOpzionali: props.praticaProperties
      ? props.praticaProperties['folder_model:motivazioneRichiesteOpzionali']
      : '',
    documentiOpzionaliVari:
      props.praticaProperties &&
      props.praticaProperties['folder_model:documentiOpzionaliVari']
        ? props.praticaProperties['folder_model:documentiOpzionaliVari']
        : [],
    daAssegnare:
      props.praticaProperties
      ? props.praticaProperties['folder_model:daAssegnare']
      : '',
    dettaglioRelazione: '',
  })
  // console.log('initstate', formValues, props, props.idNodoPratica)

  const daAssegnareA = JSON.parse(
  localStorage.getItem('DaAssegnareA'),
)
  const [showDettagliorelazione, setShowDettagliorelazione] = useState(false)
  const [listRelazioneA, setListRelazioneA] = useState(listaManualeUtenteBase)
  const [assegnareA, setAssegnareA] = useState('')
  const [docName, setDocName] = React.useState([])
  const [docPerAdeguataVerificaSelected, setDocPerAdeguataVerificaSelected] =
    useState([])
  const [showNote, setShowNote] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [errorState, setErrorState] = useState(false)
  useEffect(() => setOpenModal(props.open), [props.open])


  useEffect(
            () =>{ 
              //debugger
              setFormValues({ ...formValues, documentiOpzionaliVari: existingOptionalDocName })
            },[docName,existingOptionalDocName] 
            )

  const multiChange = event => {
    const {
      target: { value },
    } = event
    //setShowNote(true)
    setExistingOptionalDocName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )

    setShowNote(value.length > 0)
  }

  const multiChangeDocPerAdv = event => {
    const {
      target: { value },
    } = event
    const docs = typeof value === 'string' ? value.split(',') : value
    setDocPerAdeguataVerificaSelected(docs)
    setFormValues({ ...formValues, documentiPerAdeguataVerifica: docs })
  }

  /*const change = event => {
    const {
      target: { value },
    } = event
    setFormValues({ ...formValues, [event.target.name]: event.target.value })

    if (value === 'Altro') {
      setShowDettagliorelazione(true)
    } else {
      setShowDettagliorelazione(false)
    }
  }
  */
  const change2 = event => {
    const {
      target: { value },
    } = event
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  const handleChange = event => {
    event.preventDefault()
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
    // console.log(formValues)

    if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV ALTO RISCHIO AUTOMATICA'
    ) {
      setListRelazioneA(listaAutomaticaARAlt)
    } else if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV MEDIO RISCHIO AUTOMATICA'
    ) {
      setListRelazioneA(listaAutomaticaMRAlt)
      //setShowDettagliorelazione(false)
    } else if (
      event.target.name === 'tipologiaDiRichiesta' &&
      event.target.value === 'ADV ALTO RISCHIO MANUALE'
    ) {
      setListRelazioneA(listaManualeUtenteBase)
      //setShowDettagliorelazione(false)
    }
  }

  const [noteCampo, setNoteCampo] = React.useState('')
  const theme = useTheme()

  const handleChangeNoteCampo = event => {
    event.preventDefault()
    setNoteCampo(event.target.value)
  }
  const today = new Date()
  const dataChiusura =
    props.praticaProperties &&
    props.praticaProperties['folder_model:dataChiusura']
      ? new Date(props.praticaProperties['folder_model:dataChiusura'])
      : undefined
  const dataChiusuraDaysDifference = dataChiusura
    ? (today.getTime() - dataChiusura.getTime()) / (1000 * 3600 * 24)
    : undefined

  const handleSubmit = (event, formValues) => {
    //debugger
    setLoadingSubmit(true)
    duplicaAdv(formValues)
      .then(response => {
        // console.log(response.data.body)
        props.addPraticaToDettaglio({ entry: response.data.body })
        props.handleClose()
        setLoadingSubmit(false)
      })
      .catch(err => {
        setLoadingSubmit(false)
        //console.log('error duplicaAdv', err.response.data.message)
        setOpenCustomModal(true)
            setErrorState(true)
            setInfoMessage(err.response.data.message)
            setTimeout(function () {
              setOpenCustomModal(false)
            setInfoMessage('')
            setErrorState(true)
            props.handleClose()
            }, 4000)
      })
  }

  const multiChangeMotivazioni = event => {
    const {
      target: { value },
    } = event
    //setShowDettagliorelazione(true)
    setExistingRelazione(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
    //debugger
    // console.log(formValues.relazione)

    if (value.includes('Altro')  || existingRelazione.includes('Esito Monitoraggio')) {
      setShowDettagliorelazione(true)
    } else {
      setShowDettagliorelazione(false)
    }
  }

  return (
    <><Modal open={openModal} onClose={props.handleClose}>
      <Box sx={style}>
        <Typography
          sx={{ flexGrow: 1 }}
          xs={12}
          item
          alignItems="center"
          mb={'1rem'}
          color={theme.palette.black.main}
          variant="h1"
        >
          Nuova ADVR
        </Typography>

        {dataChiusuraDaysDifference && dataChiusuraDaysDifference > 90 && (
          <Stack
            sx={{ backgroundColor: '#FFFFFF' }}
            alignContent="space-center"
            spacing={{ xs: 0, md: 20, lg: 20, xl: 30 }}
          >
            <Grid
              container
              padding="40px"
              spacing={{ xs: 2, sm: 2, md: 4 }}
              columns={{ xs: 8, sm: 8, md: 9 }}
              justifyItems="center"
            >
              <Grid item xs={4}>
                <TextField
                  sx={{ mt: 1, width: '100%' }}
                  onChange={handleChange}
                  name={'nomePratica'}
                  value={formValues.nomePratica}
                  id="standard-basic"
                  label="Nome Pratica"
                  variant="standard" />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  label="Tipologia di richiesta"
                  sx={{ width: '130%' }}
                  variant="standard"
                  value={isValidUser('NUOVA_ADV_AUTOMATICA') ? formValues.tipologiaDiRichiesta : 'ADV ALTO RISCHIO MANUALE'}
                  onChange={handleChange}
                  error={errorState &&
                    (formValues.tipologiaDiRichiesta === '' ||
                      formValues.tipologiaDiRichiesta === undefined)}
                  inputProps={{
                    name: 'tipologiaDiRichiesta',
                    id: 'uncontrolled-native',
                  }}
                >
                  {tipologiaDiRichiestaAlt?.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <OnlyWithFunction acceptedFunction="RICHIESTA_DOCUMENTI_OPZIONALI">
                  <Tooltip
                    title={docName && docName.length !== 0 ? docName.join(', ') : ''}
                    placement="top"
                  >
                    <TextField
                      label="Documenti (Opzionali) Vari"
                      variant="standard"
                      size="none"
                      sx={{ width: '100%' }}
                      id="demo-multiple-checkbox"
                      select
                      MenuProps={MenuProps}
                      SelectProps={{
                        multiple: true,
                        value: typeof existingOptionalDocName === 'string' &&
                          existingOptionalDocName
                          ? existingOptionalDocName.split(',')
                          : existingOptionalDocName,
                        onChange: multiChange,
                        renderValue: selected => selected.join(', '),
                      }}
                    >
                      {listOptionalDocNames?.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={existingOptionalDocName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </OnlyWithFunction>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  select
                  sx={{ width: '130%' }}
                  variant="standard"
                  label="Verifica rafforzata in relazione al seguente prodotto"
                  onChange={change2}
                  value={formValues.tipologiaOperativita}
                  inputProps={{
                    name: 'tipologiaOperativita',
                    id: 'uncontrolled-native',
                  }}
                >
                  {verificaRafforzataInRelazioneA?.map(name => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  title={existingRelazione && existingRelazione.length !== 0
                    ? existingRelazione.join(', ')
                    : ''}
                  placement="top"
                >
                  <TextField
                    select
                    label="Motivazione dell'adeguata verifica"
                    sx={{ width: '100%' }}
                    variant="standard"
                    id="demo-multiple-checkbox"
                    MenuProps={MenuProps}
                    SelectProps={{
                      multiple: true,
                      value: typeof existingRelazione === 'string' &&
                        existingRelazione
                        ? existingRelazione.split(',')
                        : existingRelazione,
                      onChange: multiChangeMotivazioni,
                      renderValue: selected => selected.join(', '),
                    }}
                    error={errorState &&
                      (formValues.relazione === undefined ||
                        formValues.relazione === '')}
                  >
                    {listRelazioneA?.map(name => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={existingRelazione.indexOf(name) > -1} />
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>

              <Grid item xs={4}>
                <OnlyWithFunction
                  acceptedFunction={'ASSEGNA_PRATICA'}
                  fallbackComponent={<TextField
                    sx={{ width: '100%' }}
                    disabled
                    name={'assegnareA'}
                    defaultValue={props.assegnare}
                    id="standard-basic"
                    label="Da assegnare a"
                    variant="standard" />}
                >
                  <Autocomplete
                    name={'daAssegnare'}
                    noOptionsText={'Nessun valore corrispondente'}
                    sx={{ width: '130%' }}
                    id="standard-basic"
                    value={formValues.daAssegnare}
                    label="Da assegnare a"
                    required
                    variant="standard"
                    onChange={(event, newValue) => {
                      event.preventDefault()
                      setFormValues({ ...formValues, daAssegnare: newValue })
                    } }
                    options={daAssegnareA !== null ? daAssegnareA : []}
                    inputValue={assegnareA || ''}
                    onInputChange={(event, newInputValue) => {
                      setAssegnareA(newInputValue)
                    } }
                    disablePortal
                    PopperComponent="Popper"
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Da Assegnare a"
                        fullWidth
                        required
                        variant="standard"
                        autoFocus />
                    )} />
                </OnlyWithFunction>
              </Grid>
            </Grid>
          </Stack>
        )}
        {dataChiusuraDaysDifference && dataChiusuraDaysDifference < 90 && (
          <div>
            <Grid
              container
              padding="30px"
              mt={{ xs: 10, lg: 8, xl: 8 }}
              spacing={{ xs: 2, sm: 2, md: 2 }}
              columns={{ xs: 8, sm: 8, md: 8 }}
              justifyItems="center"
            >
              <Grid item xs={4}>
                <Tooltip
                  title={docPerAdeguataVerificaSelected &&
                    docPerAdeguataVerificaSelected.length !== 0
                    ? docPerAdeguataVerificaSelected.join(', ')
                    : ''}
                  placement="top"
                >
                  <TextField
                    label="Documenti per adeguata verifica"
                    variant="standard"
                    size="none"
                    sx={{ width: '100%' }}
                    id="demo-multiple-checkbox"
                    select
                    MenuProps={MenuProps}
                    SelectProps={{
                      multiple: true,
                      value: docPerAdeguataVerificaSelected,
                      onChange: multiChangeDocPerAdv,
                      renderValue: selected => selected.join(', '),
                    }}
                  >
                    {documentiPerAdeguataVerifica?.map(name => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={docPerAdeguataVerificaSelected.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <OnlyWithFunction acceptedFunction="RICHIESTA_DOCUMENTI_OPZIONALI">
                  <Tooltip
                    title={docName && docName.length !== 0 ? docName.join(', ') : ''}
                    placement="top"
                  >
                    <TextField
                      label="Documenti (Opzionali) Vari"
                      variant="standard"
                      size="none"
                      sx={{ width: '100%' }}
                      id="demo-multiple-checkbox"
                      select
                      MenuProps={MenuProps}
                      SelectProps={{
                        multiple: true,
                        value: typeof existingOptionalDocName === 'string' &&
                          existingOptionalDocName
                          ? existingOptionalDocName.split(',')
                          : existingOptionalDocName,
                        onChange: multiChange,
                        renderValue: selected => selected.join(', '),
                      }}
                    >
                      {listOptionalDocNames?.map(name => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={existingOptionalDocName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Tooltip>
                </OnlyWithFunction>


                <Grid item xs={4}>
                  {showNote && (
                    <textarea
                      required
                      minrows={3}
                      style={{ margin: "2px", width: "100%", maxWidth: "100%", height: "120px", maxHeight: "120px", borderColor: "black" }}
                      aria-label="empty textarea"
                      defaultValue={formValues.motivazioneRichiesteOpzionali}
                      placeholder="Motivazioni richieste opzionali*"
                      onChange={handleChange}
                      name={'motivazioneRichiesteOpzionali'} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}

        {loadingSubmit && <CircularProgress />}
        {!loadingSubmit && (
          <Grid alignContent="end" item xs={12}>
            <Typography></Typography>
            <StyledButton
              onClick={(event, params) => handleSubmit(event, formValues)}
              sx={{ margin: 'auto', marginTop: '2em' }}
              disabled={formValues.documentiOpzionaliVari.length > 0 &&
                !formValues.motivazioneRichiesteOpzionali}
            >
              CONFERMA
            </StyledButton>
          </Grid>
        )}
      </Box>
    </Modal>
    <CustomModal
        open={openCustomModal}
        error={errorState}
        message={infoMessage} /></>
  )
}

export default DuplicaADVModal
