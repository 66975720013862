import { customAxios } from './customAxios'


export function addDocumentiOpzionali(formValues){
    const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
    const data = {
        "idNodoPratica": formValues.idNodoPratica,
        "documentiOpzionali": formValues.documentiOpzionali
    }



const addDocumentiOpzionaliRequest =
        customAxios.post(process.env.REACT_APP_BACKEND_HOST + "/alfresco/addDocumentiOpzionali", data, config)
    return addDocumentiOpzionaliRequest
}