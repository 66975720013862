import { customAxios } from './customAxios';

export function getMonitoraggioByAdv(idAdv) {
    const config = {
        params: { idADV: idAdv },
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }
    const getMonitoraggioByAdvRequest = customAxios.get(`${process.env.REACT_APP_BACKEND_HOST}/adv/monitoraggio/search-monitoraggiobyadv`, config)
    .then(response => {
        // Estrarre solo inputInizialeList da ciascun elemento della risposta
        return response.data.map(item => item.inputInizialeList);
    });
        return getMonitoraggioByAdvRequest;
}
