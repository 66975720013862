import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { StatiPratica } from '../../utils/StatiPratica';
import { useState, useEffect } from 'react'
import InputAdornment from '@mui/material/InputAdornment';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
const cellNames = [
    'NDG',//cellNames[0]
    'Codice Fiscale',//cellNames[1]
    'Ragione Sociale',////cellNames[2]
    'Sale/Field Analyst',//cellNames[3]
    'Data Apertura',//cellNames[4]
    'Data Chiusura',//cellNames[5]
    'Stato Pratica',//cellNames[6]
    'Tipo Inserimento',//cellNames[7]
  ];
  const tipoInserimento = ['Automatica Alto Rischio', 'Automatica Medio Rischio', 'Manuale','Automatica','Manuale Alto Rischio', 'Manuale Medio Rischio'];
  const saleFieldAnalystList = JSON.parse(localStorage.getItem('DaAssegnareA'))
  
  const selectTypeSearch = ['Uguale', 'Diverso'];
  const textTypeSearch = ['Uguale', 'Contiene'];
  const dateTypeSearch = ['Uguale', 'Maggiore','Minore','Periodo'];

 


const FilterMenu = ({ anchorEl, open, handleClose, handleAdd, handleRemove, handleFiltra, handleDownloadExcel, 
    handleSelectChangeColumn1,handleSelectChangeColumn2,handleSelectChangeColumn3,handleSelectChangeColumn4,
    handleSelectChangeColumn5,handleSelectChangeColumn6,handleSelectChangeColumn7,
    handleSelectChangeColumn8, handleSelectChangeStato, handleSelectChangeTipo, 
    handleSelectSaleFieldAnalyst, handleChangeNDG, handleChangeCF, handleChangeRagioneSociale, 
    handleDateChangePraticaDa, handleDateChangePraticaA,handleDateChangeChiusuraDa,handleDateChangeChiusuraA, 
    handleDateTextSearch,handleDateChiusuraTextSearch, handleSelectSelectSearchStato, handleSelectSelectSearchTipo, 
    handleSelectSelectSearchField, handleSelectTextSearchNdg, handleSelectTextSearchCf, handleSelectTextSearchRagSociale,
     count, cell1, cell2, cell3, cell4, cell5, cell6, cell7,cell8, cellNames, isStatoEnabled, 
     selectSearchingStato, stato, tipo, saleFieldAnalyst, selectSearchingTipo, selectSearchingField, 
     ndg, cf, ragioneSociale, dateSearching,dateSearchingChiusura,textSearchingRagSociale,textSearchingNdg,textSearchingCf,
     dataPraticaDa, dataPraticaA,dataChiusuraDa,dataChiusuraA, isDataPraticaEnabled,handleClearRagioneSociale,handleClearStatoPratica,handleClearCodiceFiscale,handleClearNdg,handleClearSaleFieldAnalyst,handleClearDataPraticaDa,handleClearTipoInserimento,handleClearDataPraticaA,handleClearDataChiusuraDa,handleClearDataChiusuraA}) => {
    return (
      <Menu
        id="basic-menu"
        sx={{ width: '100%', marginTop: '-100px' }} // Regola il valore di marginTop in base alle tue esigenze
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right', // o 'left' per aprire a sinistra
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
  <div>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleAdd}
      disabled={count === 8}
      title="Aggiungi Filtro"
    >
      <AddIcon></AddIcon>
    </IconButton>
    <span></span>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleRemove}
      disabled={count === 0}
      title="Rimuovi Filtro"
    >
      <RemoveIcon></RemoveIcon>
    </IconButton>
  </div>

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleFiltra}
      title='Cerca'
    >
      <SearchIcon></SearchIcon>
    </IconButton>

    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleDownloadExcel}
      title="Download Excel"
    >
      <FileDownloadIcon></FileDownloadIcon>
    </IconButton>
  </div>
</div>
<div style={{margin:'10px'}}>
{count >= 1 &&(
        <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
          
          <Select
  name='optSelect'
  value={cell1}
  onChange={handleSelectChangeColumn1}
  sx={{ width: '190px', height: '30px'}}
>
  {count === 1
    ? cellNames.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))
    : cellNames
    .filter(opzione => [cell2, cell3, cell4, cell5, cell6, cell7,cell8].every(cell => cell !== opzione))
        .map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
</Select>



{cell1 == cellNames[6] && (
  
  <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
      name='tipoSearching'
      value={selectSearchingStato}
      onChange={handleSelectSelectSearchStato}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {selectTypeSearch.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
    </div>
  )}

{cell1 === cellNames[4] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearching}
      onChange={handleDateTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
    
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />

      
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearching === "Periodo" && (
        <DatePicker
        value={dataPraticaA}
        onChange={handleDateChangePraticaA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataPraticaA && (
                    <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow:1}}
        disabled={!isDataPraticaEnabled}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}

{cell1 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}


   {cell1 == cellNames[7] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingTipo}
          onChange={handleSelectSelectSearchTipo}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell1 == cellNames[3] && (
 <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
 <Select
       name='tipoSearching'
       value={selectSearchingField}
       onChange={handleSelectSelectSearchField}
       sx={{ width: '190px', height: '30px' }}
       //disabled={!isStatoEnabled}
     >
      {selectTypeSearch.map((opzione, index) => (
       <MenuItem key={index} value={opzione}>
         {opzione}
       </MenuItem>
     ))}
     </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
    </div>

  )}



  {cell1 == cellNames[0] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='ndgSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
  <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  />
  <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearNdg}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
  </div>
  )}
  {cell1 == cellNames[1] && (
       <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
      <Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
   </div>
  )}
  {cell1 == cellNames[2] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
       <Select
          name='tipoSearching'
          value={textSearchingRagSociale}
          onChange={handleSelectTextSearchRagSociale}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>

   
   
   
</div>

  )}

</div>
)}
{count >= 2 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}>
  
  <Select
    name='optSelect'
    value={cell2}
    onChange={handleSelectChangeColumn2}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1)

    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell2 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingStato}
          onChange={handleSelectSelectSearchStato}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px'}}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
    </div>
  )
  }
  {cell2 == cellNames[4] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
  <Select
    name='tipoSearching'
    value={dateSearching}
    onChange={handleDateTextSearch}
    sx={{ width: '190px', height: '30px' }}
  >
    {dateTypeSearch.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
  <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

    {dateSearching === "Periodo" && (
      <DatePicker
      value={dataPraticaA}
      onChange={handleDateChangePraticaA}
      renderInput={(props) => (
        <TextField
          {...props}
          label="A"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaA && (
                  <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ width: '50%' }}
      disabled={!isDataPraticaEnabled}
    />
    )}
    </div>
  </LocalizationProvider>
  </div>

</div>
)}
{cell2 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell2 == cellNames[7] && (
 <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
 <Select
       name='tipoSearching'
       value={selectSearchingTipo}
       onChange={handleSelectSelectSearchTipo}
       sx={{ width: '190px', height: '30px' }}
       //disabled={!isStatoEnabled}
     >
      {selectTypeSearch.map((opzione, index) => (
       <MenuItem key={index} value={opzione}>
         {opzione}
       </MenuItem>
     ))}
     </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>


  )}
  {cell2 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell2 == cellNames[0] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start',}}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  />
  <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearNdg}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
  </div>
  )}
  {cell2 == cellNames[1] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
   </div>
  )}
  {cell2 == cellNames[2] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingRagSociale}
          onChange={handleSelectTextSearchRagSociale}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
   </div>
  )}

  
  
</div>
)}
{count >= 3 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center',marginTop:'10px' }}>
  
  <Select
    name='optSelect'
    value={cell3}
    onChange={handleSelectChangeColumn3}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1 && opzione !== cell2)

    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell3 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
      name='tipoSearching'
      value={selectSearchingStato}
      onChange={handleSelectSelectSearchStato}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {selectTypeSearch.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>

  )
  }
  {cell3 == cellNames[4] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
      <Select
        name='tipoSearching'
        value={dateSearching}
        onChange={handleDateTextSearch}
        sx={{ width: '190px', height: '30px' }}
      >
        {dateTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
      </Select>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
  
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
  
        {dateSearching === "Periodo" && (
          <DatePicker
          value={dataPraticaA}
          onChange={handleDateChangePraticaA}
          renderInput={(props) => (
            <TextField
              {...props}
              label="A"
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                    {dataPraticaA && (
                      <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                        <CancelIcon />
                      </IconButton>
                    )}
                    {props.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '190px',
                paddingRight: 0,
                paddingBottom: 3,
                '& .MuiSvgIcon-root': {
                  marginRight: '5px',
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                },
              }}
            />
          )}
          format="DD/MM/YYYY"
          sx={{ flexGrow:1 }}
          disabled={!isDataPraticaEnabled}
        />
        )}
        </div>
      </LocalizationProvider>
      </div>
  
    </div>
)}
{cell3 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell3 == cellNames[7] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingTipo}
           onChange={handleSelectSelectSearchTipo}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell3 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell3 == cellNames[0] && (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  />
  <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearNdg}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell3 == cellNames[1] && (
           <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell3 == cellNames[2] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
    <Select
              name='tipoSearching'
              value={textSearchingRagSociale}
              onChange={handleSelectTextSearchRagSociale}
              sx={{ width: '190px', height: '30px' }}
              //disabled={!isStatoEnabled}
            >
             {textTypeSearch.map((opzione, index) => (
              <MenuItem key={index} value={opzione}>
                {opzione}
              </MenuItem>
            ))}
            </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}

  
  
</div>
)}
{count >= 4 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center',marginTop:'10px' }}>
  
  <Select
    name='optSelect'
    value={cell4}
    onChange={handleSelectChangeColumn4}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1 && opzione !== cell2 && opzione !== cell3)

    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell4 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingStato}
          onChange={handleSelectSelectSearchStato}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )
  }
  {cell4 == cellNames[4] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
     <Select
       name='tipoSearching'
       value={dateSearching}
       onChange={handleDateTextSearch}
       sx={{ width: '190px', height: '30px' }}
     >
       {dateTypeSearch.map((opzione, index) => (
         <MenuItem key={index} value={opzione}>
           {opzione}
         </MenuItem>
       ))}
     </Select>
     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
 
     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
     <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
 
       {dateSearching === "Periodo" && (
         <DatePicker
         value={dataPraticaA}
         onChange={handleDateChangePraticaA}
         renderInput={(props) => (
           <TextField
             {...props}
             label="A"
             InputProps={{
               ...props.InputProps,
               endAdornment: (
                 <InputAdornment position="end">
                   {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                   {dataPraticaA && (
                     <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                       <CancelIcon />
                     </IconButton>
                   )}
                   {props.InputProps.endAdornment}
                 </InputAdornment>
               ),
             }}
             sx={{
               width: '190px',
               paddingRight: 0,
               paddingBottom: 3,
               '& .MuiSvgIcon-root': {
                 marginRight: '5px',
               },
               '& .MuiInputBase-root': {
                 border: '1px solid #ced4da',
                 borderRadius: '4px',
               },
             }}
           />
         )}
         format="DD/MM/YYYY"
         sx={{ flexGrow:1 }}
         disabled={!isDataPraticaEnabled}
       />
       )}
       </div>
     </LocalizationProvider>
     </div>
 
   </div>

)}
{cell4 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell4 == cellNames[7] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingTipo}
           onChange={handleSelectSelectSearchTipo}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell4 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell4 == cellNames[0] && (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  />
  <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearNdg}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell4 == cellNames[1] && (
           <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
   </div>
  )}
  {cell4 == cellNames[2] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
    <Select
              name='tipoSearching'
              value={textSearchingRagSociale}
              onChange={handleSelectTextSearchRagSociale}
              sx={{ width: '190px', height: '30px' }}
              //disabled={!isStatoEnabled}
            >
             {textTypeSearch.map((opzione, index) => (
              <MenuItem key={index} value={opzione}>
                {opzione}
              </MenuItem>
            ))}
            </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}

  
  
</div>
)}
{count >= 5 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center',marginTop:'10px' }}>
  
  <Select
    name='optSelect'
    value={cell5}
    onChange={handleSelectChangeColumn5}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1 && opzione !== cell2 && opzione !== cell3 && opzione !== cell4)
    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell5 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingStato}
          onChange={handleSelectSelectSearchStato}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )
  }
  {cell5 == cellNames[4] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
     <Select
       name='tipoSearching'
       value={dateSearching}
       onChange={handleDateTextSearch}
       sx={{ width: '190px', height: '30px' }}
     >
       {dateTypeSearch.map((opzione, index) => (
         <MenuItem key={index} value={opzione}>
           {opzione}
         </MenuItem>
       ))}
     </Select>
     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
 
     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
     <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
 
       {dateSearching === "Periodo" && (
         <DatePicker
         value={dataPraticaA}
         onChange={handleDateChangePraticaA}
         renderInput={(props) => (
           <TextField
             {...props}
             label="A"
             InputProps={{
               ...props.InputProps,
               endAdornment: (
                 <InputAdornment position="end">
                   {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                   {dataPraticaA && (
                     <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                       <CancelIcon />
                     </IconButton>
                   )}
                   {props.InputProps.endAdornment}
                 </InputAdornment>
               ),
             }}
             sx={{
               width: '190px',
               paddingRight: 0,
               paddingBottom: 3,
               '& .MuiSvgIcon-root': {
                 marginRight: '5px',
               },
               '& .MuiInputBase-root': {
                 border: '1px solid #ced4da',
                 borderRadius: '4px',
               },
             }}
           />
         )}
         format="DD/MM/YYYY"
         sx={{ flexGrow:1 }}
         disabled={!isDataPraticaEnabled}
       />
       )}
       </div>
     </LocalizationProvider>
     </div>
 
   </div>

)}
{cell5 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
       <DatePicker
       value={dataChiusuraA}
       onChange={handleDateChangeChiusuraA}
       renderInput={(props) => (
         <TextField
           {...props}
           label="A"
           InputProps={{
             ...props.InputProps,
             endAdornment: (
               <InputAdornment position="end">
                 {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                 {dataChiusuraA && (
                   <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                     <CancelIcon />
                   </IconButton>
                 )}
                 {props.InputProps.endAdornment}
               </InputAdornment>
             ),
           }}
           sx={{
             width: '190px',
             paddingRight: 0,
             paddingBottom: 3,
             '& .MuiSvgIcon-root': {
               marginRight: '5px',
             },
             '& .MuiInputBase-root': {
               border: '1px solid #ced4da',
               borderRadius: '4px',
             },
           }}
         />
       )}
       format="DD/MM/YYYY"
       sx={{ flexGrow: 1 }}
     />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell5 == cellNames[7] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingTipo}
           onChange={handleSelectSelectSearchTipo}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell5 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell5 == cellNames[0] && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  />
  <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearNdg}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell5 == cellNames[1] && (
               <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell5 == cellNames[2] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
    <Select
              name='tipoSearching'
              value={textSearchingRagSociale}
              onChange={handleSelectTextSearchRagSociale}
              sx={{ width: '190px', height: '30px' }}
              //disabled={!isStatoEnabled}
            >
             {textTypeSearch.map((opzione, index) => (
              <MenuItem key={index} value={opzione}>
                {opzione}
              </MenuItem>
            ))}
            </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
      >
        <CancelIcon></CancelIcon>
    </IconButton></div>
  )}

  
  
</div>
)}
{count >= 6 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center',marginTop:'10px' }}>
  
  <Select
    name='optSelect'
    value={cell6}
    onChange={handleSelectChangeColumn6}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1 && opzione !== cell2 && opzione !== cell3 && opzione !== cell4 && opzione !== cell5 )
    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell6 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingStato}
          onChange={handleSelectSelectSearchStato}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )
  }
  {cell6 == cellNames[4] && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
        <Select
          name='tipoSearching'
          value={dateSearching}
          onChange={handleDateTextSearch}
          sx={{ width: '190px', height: '30px' }}
        >
          {dateTypeSearch.map((opzione, index) => (
            <MenuItem key={index} value={opzione}>
              {opzione}
            </MenuItem>
          ))}
        </Select>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
    
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
        <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    
          {dateSearching === "Periodo" && (
            <DatePicker
            value={dataPraticaA}
            onChange={handleDateChangePraticaA}
            renderInput={(props) => (
              <TextField
                {...props}
                label="A"
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                      {dataPraticaA && (
                        <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                          <CancelIcon />
                        </IconButton>
                      )}
                      {props.InputProps.endAdornment}
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: '190px',
                  paddingRight: 0,
                  paddingBottom: 3,
                  '& .MuiSvgIcon-root': {
                    marginRight: '5px',
                  },
                  '& .MuiInputBase-root': {
                    border: '1px solid #ced4da',
                    borderRadius: '4px',
                  },
                }}
              />
            )}
            format="DD/MM/YYYY"
            sx={{ flexGrow:1 }}
            disabled={!isDataPraticaEnabled}
          />
          )}
          </div>
        </LocalizationProvider>
        </div>
    
      </div>

)}
{cell6 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell6 == cellNames[7] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingTipo}
           onChange={handleSelectSelectSearchTipo}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell6 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell6 == cellNames[0] && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  />
  <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearNdg}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell6 == cellNames[1] && (
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell6 == cellNames[2] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
    <Select
              name='tipoSearching'
              value={textSearchingRagSociale}
              onChange={handleSelectTextSearchRagSociale}
              sx={{ width: '190px', height: '30px' }}
              //disabled={!isStatoEnabled}
            >
             {textTypeSearch.map((opzione, index) => (
              <MenuItem key={index} value={opzione}>
                {opzione}
              </MenuItem>
            ))}
            </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}

  
  
</div>
)}
{count >= 7 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center',marginTop:'10px' }}>
  
  <Select
    name='optSelect'
    value={cell7}
    onChange={handleSelectChangeColumn7}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1 && opzione !== cell2 && opzione !== cell3 && opzione !== cell4 && opzione !== cell5 && opzione !== cell6 )
    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell7 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingStato}
          onChange={handleSelectSelectSearchStato}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )
  }
  {cell7 == cellNames[4] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
      <Select
        name='tipoSearching'
        value={dateSearching}
        onChange={handleDateTextSearch}
        sx={{ width: '190px', height: '30px' }}
      >
        {dateTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
      </Select>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
  
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
  
        {dateSearching === "Periodo" && (
          <DatePicker
          value={dataPraticaA}
          onChange={handleDateChangePraticaA}
          renderInput={(props) => (
            <TextField
              {...props}
              label="A"
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                    {dataPraticaA && (
                      <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                        <CancelIcon />
                      </IconButton>
                    )}
                    {props.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '190px',
                paddingRight: 0,
                paddingBottom: 3,
                '& .MuiSvgIcon-root': {
                  marginRight: '5px',
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                },
              }}
            />
          )}
          format="DD/MM/YYYY"
          sx={{ flexGrow:1 }}
          disabled={!isDataPraticaEnabled}
        />
        )}
        </div>
      </LocalizationProvider>
      </div>
  
    </div>

)}
{cell7 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell7 == cellNames[7] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingTipo}
           onChange={handleSelectSelectSearchTipo}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell7 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell7 == cellNames[0] && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  /><IconButton
  id="basic-button"
  aria-controls={open ? 'basic-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
  onClick={handleClearNdg}
  title="Clear"
>
  <CancelIcon></CancelIcon>
</IconButton></div>
  )}
  {cell7 == cellNames[1] && (
               <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Clear"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell7 == cellNames[2] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
    <Select
              name='tipoSearching'
              value={textSearchingRagSociale}
              onChange={handleSelectTextSearchRagSociale}
              sx={{ width: '190px', height: '30px' }}
              //disabled={!isStatoEnabled}
            >
             {textTypeSearch.map((opzione, index) => (
              <MenuItem key={index} value={opzione}>
                {opzione}
              </MenuItem>
            ))}
            </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}

  
  
</div>
)}
{count >= 8 &&(
<div style={{ display: 'flex', flexDirection: 'row', alignItems:'center',marginTop:'10px' }}>
  
  <Select
    name='optSelect'
    value={cell8}
    onChange={handleSelectChangeColumn8}
    sx={{ width: '190px', height: '30px' }}
  >
    {cellNames.filter(opzione => opzione !== cell1 && opzione !== cell2 && opzione !== cell3 && opzione !== cell4 && opzione !== cell5 && opzione !== cell6 && opzione !== cell7 )
    .map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
  </Select>

 
  {cell8 == cellNames[6] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
    <Select
          name='tipoSearching'
          value={selectSearchingStato}
          onChange={handleSelectSelectSearchStato}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {selectTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <Select
      name='statoPraticaValue'
      value={stato}
      onChange={handleSelectChangeStato}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
      {Object.entries(StatiPratica).map(([key, value]) => (
        <MenuItem key={key} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearStatoPratica}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )
  }
  {cell8 == cellNames[4] && (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
      <Select
        name='tipoSearching'
        value={dateSearching}
        onChange={handleDateTextSearch}
        sx={{ width: '190px', height: '30px' }}
      >
        {dateTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
      </Select>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>
  
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <DatePicker
      value={dataPraticaDa}
      onChange={handleDateChangePraticaDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataPraticaDa && (
                  <IconButton onClick={handleClearDataPraticaDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
      disabled={!isDataPraticaEnabled}
    />
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
  
        {dateSearching === "Periodo" && (
          <DatePicker
          value={dataPraticaA}
          onChange={handleDateChangePraticaA}
          renderInput={(props) => (
            <TextField
              {...props}
              label="A"
              InputProps={{
                ...props.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                    {dataPraticaA && (
                      <IconButton onClick={handleClearDataPraticaA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                        <CancelIcon />
                      </IconButton>
                    )}
                    {props.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
              sx={{
                width: '190px',
                paddingRight: 0,
                paddingBottom: 3,
                '& .MuiSvgIcon-root': {
                  marginRight: '5px',
                },
                '& .MuiInputBase-root': {
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                },
              }}
            />
          )}
          format="DD/MM/YYYY"
          sx={{ flexGrow:1}}
          disabled={!isDataPraticaEnabled}
        />
        )}
        </div>
      </LocalizationProvider>
      </div>
  
    </div>

)}
{cell8 === cellNames[5] && (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>
    <Select
      name='tipoSearching'
      value={dateSearchingChiusura}
      onChange={handleDateChiusuraTextSearch}
      sx={{ width: '190px', height: '30px' }}
    >
      {dateTypeSearch.map((opzione, index) => (
        <MenuItem key={index} value={opzione}>
          {opzione}
        </MenuItem>
      ))}
    </Select>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start' }}>

    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
    <DatePicker
      value={dataChiusuraDa}
      onChange={handleDateChangeChiusuraDa}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Da"
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                {dataChiusuraDa && (
                  <IconButton onClick={handleClearDataChiusuraDa} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                    <CancelIcon />
                  </IconButton>
                )}
                {props.InputProps.endAdornment}
              </InputAdornment>
            ),
          }}
          sx={{
            width: '190px',
            paddingRight: 0,
            paddingBottom: 3,
            '& .MuiSvgIcon-root': {
              marginRight: '5px',
            },
            '& .MuiInputBase-root': {
              border: '1px solid #ced4da',
              borderRadius: '4px',
            },
          }}
        />
      )}
      format="DD/MM/YYYY"
      sx={{ flexGrow: 1 }}
    />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start' }}>

      {dateSearchingChiusura === "Periodo" && (
        <DatePicker
        value={dataChiusuraA}
        onChange={handleDateChangeChiusuraA}
        renderInput={(props) => (
          <TextField
            {...props}
            label="A"
            InputProps={{
              ...props.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {/* Condizionalmente mostrare l'icona di cancellazione solo se esiste una data */}
                  {dataChiusuraA && (
                    <IconButton onClick={handleClearDataChiusuraA} sx={{ marginRight: '-25px' }}> {/* Aggiusta questo valore come necessario */}
                      <CancelIcon />
                    </IconButton>
                  )}
                  {props.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
            sx={{
              width: '190px',
              paddingRight: 0,
              paddingBottom: 3,
              '& .MuiSvgIcon-root': {
                marginRight: '5px',
              },
              '& .MuiInputBase-root': {
                border: '1px solid #ced4da',
                borderRadius: '4px',
              },
            }}
          />
        )}
        format="DD/MM/YYYY"
        sx={{ flexGrow: 1 }}
      />
      )}
      </div>
    </LocalizationProvider>
    </div>

  </div>
)}
  {cell8 == cellNames[7] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingTipo}
           onChange={handleSelectSelectSearchTipo}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='tipoInserimentoValue'
      value={tipo}
      onChange={handleSelectChangeTipo}
      sx={{ width: '190px', height: '30px' }}
      disabled={!isStatoEnabled}
    >
     {tipoInserimento.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearTipoInserimento}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton>
    
    </div>
  )}
  {cell8 == cellNames[3] && (
     <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
     <Select
           name='tipoSearching'
           value={selectSearchingField}
           onChange={handleSelectSelectSearchField}
           sx={{ width: '190px', height: '30px' }}
           //disabled={!isStatoEnabled}
         >
          {selectTypeSearch.map((opzione, index) => (
           <MenuItem key={index} value={opzione}>
             {opzione}
           </MenuItem>
         ))}
         </Select>
    <Select
      name='saleFieldAnalystValue'
      value={saleFieldAnalyst}
      onChange={handleSelectSaleFieldAnalyst}
      sx={{ width: '190px', height: '30px' }}
      //disabled={!isStatoEnabled}
    >
     {saleFieldAnalystList.map((opzione, index) => (
      <MenuItem key={index} value={opzione}>
        {opzione}
      </MenuItem>
    ))}
    </Select>
    <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearSaleFieldAnalyst}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}



  {cell8 == cellNames[0] && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingNdg}
          onChange={handleSelectTextSearchNdg}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
    <TextField
    margin="normal"
    placeholder=""
    required
    fullWidth
    value={ndg}
    variant="standard"
    helperText=""
    id="search"
    name="search"
    autoComplete="search"
    onChange={handleChangeNDG}
    autoFocus
    sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
  /><IconButton
  id="basic-button"
  aria-controls={open ? 'basic-menu' : undefined}
  aria-haspopup="true"
  aria-expanded={open ? 'true' : undefined}
  onClick={handleClearNdg}
  title="Clear"
>
  <CancelIcon></CancelIcon>
</IconButton></div>
  )}
  {cell8 == cellNames[1] && (
               <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start'}}>
<Select
          name='tipoSearching'
          value={textSearchingCf}
          onChange={handleSelectTextSearchCf}
          sx={{ width: '190px', height: '30px' }}
          //disabled={!isStatoEnabled}
        >
         {textTypeSearch.map((opzione, index) => (
          <MenuItem key={index} value={opzione}>
            {opzione}
          </MenuItem>
        ))}
        </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={cf}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeCF}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}     

   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearCodiceFiscale}
      title="Clear"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}
  {cell8 == cellNames[2] && (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'start' }}>
    <Select
              name='tipoSearching'
              value={textSearchingRagSociale}
              onChange={handleSelectTextSearchRagSociale}
              sx={{ width: '190px', height: '30px' }}
              //disabled={!isStatoEnabled}
            >
             {textTypeSearch.map((opzione, index) => (
              <MenuItem key={index} value={opzione}>
                {opzione}
              </MenuItem>
            ))}
            </Select>
     <TextField
     margin="normal"
     placeholder=""
     required
     fullWidth
     value={ragioneSociale}
     variant="standard"
     helperText=""
     id="search"
     name="search"
     autoComplete="search"
     onChange={handleChangeRagioneSociale}
     autoFocus
     sx={{
      width: '190px',
      height: '30px', // Altezza simile al Select
      padding: 0, // Rimuovi il padding interno
      '& .MuiInputBase-root': {
        border: '1px solid #ced4da', // Imposta un bordo simile al Select
        borderRadius: '4px', // Puoi regolare il valore per adattarlo al tuo design
      },
      marginTop: '5px', // Sposta il TextField verso il basso
    }}
   />
   <IconButton
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClearRagioneSociale}
      title="Pulisci"
    >
      <CancelIcon></CancelIcon>
    </IconButton></div>
  )}

  
  
</div>
)}

</div>
      </Menu>
    );
  };
  
  export default FilterMenu;
  