import React, { useContext, useEffect } from 'react'
import Header from '../UI/Header'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { AuthContext } from '../App'

const SessioneScaduta = () => {
    const { state: AuthState, dispatch } = useContext(AuthContext)
    const theme = useTheme()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const handleLogout = event => {
        event.preventDefault()
        navigate('/')
        queryClient.removeQueries()
        dispatch({ type: 'LOGOUT', payload: { isAuthenticated: false } })
        localStorage.clear()
    }

    useEffect(() => {
        const timedRedirect = setTimeout(() => {
            navigate('/')
            queryClient.removeQueries()
            dispatch({ type: 'LOGOUT', payload: { isAuthenticated: false } })
            localStorage.clear()
        }, 10000)
        return () => clearTimeout(timedRedirect)
    }, [])

    return (
        <>
            <Header
                showProfile={false}
                header={
                    <>
                        <Grid
                            container
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            justifyContent="start"
                            alignContent="start"
                        >
                            <Typography
                                sx={{ flexGrow: 1 }}
                                xs={12}
                                item
                                alignItems="center"
                                mb={'1rem'}
                                color={theme.palette.black.main}
                                variant="h1"
                            >
                                SESSIONE SCADUTA
                            </Typography>
                        </Grid>
                    </>
                }
            />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3}>
                    <Typography margin="auto" variant="h1">
                        Tra qualche secondo verrai reindirizzato alla pagina di Login,
                        oppure clicca il tasto disconnetti :
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default SessioneScaduta
