import { customAxios } from './customAxios'
export function getListe() {
  const config = {
    headers: {
      Accept: '*/*',
      AuthToken: JSON.parse(localStorage.getItem('token')),
    },
  }
  const getListeRequest = customAxios.get(
    process.env.REACT_APP_BACKEND_HOST + `/alfresco/getListe?`,
    null,
    config,
  )
  return getListeRequest
}
