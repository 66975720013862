import { customAxios } from "./customAxios";

export function saveEsitoAdv(formValues) {
const config = {
        headers: {
            "AuthToken": JSON.parse(localStorage.getItem('token')),
        }
    }
const data = {
        "altro": formValues.altro,
        "altroAnalisi": formValues.altroAnalisi,
        "listAnalisi": formValues.listAnalisi,
        "anno": formValues.anno,
        "azioniDaPorreInEssere": formValues.azioniDaPorreInEssere,
        "checkoutOperatore": formValues.checkoutOperatore,
        "comportamentoSoggettoEsecutore": formValues.comportamentoSoggettoEsecutore,
        "controlliAttivitaCliente": formValues.controlliAttivitaCliente,
        "analisiSvolte":formValues.analisiSvolte,
        "controlliSettore": formValues.controlliSettore,
        "elevataProfessionalita": formValues.elevataProfessionalita,
        "idNodoPratica": formValues.idNodoPratica,
        "infoAcquisite": formValues.infoAcquisite,
        "noteAzioniDaPorreInEssere": formValues.noteAzioniDaPorreInEssere,
        "noteCampo1": formValues.noteCampo1,
        "noteCampo2": formValues.noteCampo2,
        "noteCampo3": formValues.noteCampo3,
        "noteCampo4": formValues.noteCampo4,
        "radioAzioniDaPorreInEssere": formValues.radioAzioniDaPorreInEssere,
        "risultaEssere": formValues.risultaEssere,
        "documentiOpzionali": formValues.documentiOpzionali,
        "aggiornaStato": formValues.aggiornaStato,
    }

    const adeguataVerificaRequest =customAxios.post("/alfresco/saveEsitoADV", data, config)
    //console.log("informazioni salvate",data)
    return adeguataVerificaRequest
}