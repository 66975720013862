import React from 'react';
import {
    Modal,
    Box,
    Typography,
    CircularProgress,
    Container,
} from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '64%',
    opacity: 1,
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    bgcolor: '#fff',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

const CustomModal = (props) => {
    return (
        <Modal open={props.open}>
            <Box sx={style}>
                <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {props.loading && <CircularProgress />}
                    {props.error && !props.loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ErrorIcon color="warning" sx={{ fontSize: '10rem' }} />
                        </Box>
                    ) : null}
                    {props.error === false && !props.loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <DoneOutlineIcon color="success" sx={{ fontSize: '10rem' }} />
                        </Box>
                    ) : null}
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.message}
                    </Typography>
                </Container>
            </Box>
        </Modal>
    );
};

export default CustomModal;
