import { customAxios } from './customAxios'
export function getRichiesteSupporto(idNodoPratica) {
    const config = {
        headers: {
            Accept: "*/*",
            'AuthToken': JSON.parse(localStorage.getItem('token'))
        },
    }

    const uri = process.env.REACT_APP_BACKEND_HOST + `/alfresco/getRichiesteSupporto?idNodoPratica=`+idNodoPratica;
    const getRichiesteSupportoRequest = customAxios.get(uri, config).then(response =>  response.data.body)
    return getRichiesteSupportoRequest;
}