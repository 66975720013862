import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import {
  Container,
  TextField,
  FormHelperText,
  RadioGroup,
  Radio,
  ListItemText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  textarea,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  AccordionActions,
} from '@mui/material'
import { useTheme } from '@emotion/react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useState, useEffect } from 'react'
import CropSquareIcon from '@mui/icons-material/CropSquare'
import { StyledButton } from '../../UI/StyledButton'
import { saveEsitoAdv } from '../../services/saveEsitoAdv'
import CustomModal from '../../UI/CustomModal'
import OnlyWithFunction, { isValidUser } from '../../OnlyWithFunctions'
import { StatiPratica } from '../../utils/StatiPratica'
import { titlesToolTip } from '../../utils/titlesToolTip'
import SendModal from '../../UI/SendModal'
import { addDocumentiOpzionali } from '../../services/addDocumentiOpzionali'
import { sendRichiestaSupporto } from '../../services/sendRichiestaSupporto'
import { getFoldersTree } from '../../services/getFoldersTree'
import { closeAdvMr } from '../../services/closeAdvMr'
import { Style } from '@mui/icons-material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const listDocOpzionali = JSON.parse(
  localStorage.getItem('DocumentiOpzionaliVari'),
)

const labelCheckBoxCheckOutOperatore = [
  "Ha svolto ricerche in rete (es. Google) e consultato liste negative in uso ed ha appurato l'assenza di pregiudizievoli sull'impresa e sui soggetti ad essa collegati (titolari effettivi, amministratore, esecutore/firmatario)",
  "Non sono emersi, oltre al settore di attività, altri elementi di rischio elevato (soggettivo od oggettivo) di riciclaggio o finanziamento al terrorismo, appurati tramite l'esecuzione dei controlli previsti nella policy AML della Banca (es. variazioni nell'attività svolta, modifiche nell'assetto societario, profilo economico non coerente con l'operatività richiesta osservata, assenza di rilievi o contestazioni da Pubbliche Autorità ecc)",
  "Sono emersi elementi tali da rendere necessaria una attività di adeguata verifica rafforzata",
  "E' stata rilevata la presenza di un PIL- Politico Italiano Locale",
  "Altro",
]


const azioniDaPo = [
  'Segnalazione operazione sospetta',
  'Instaurazione rapporto / esecuzione operazione',
  'Mantenimento del rapporto',
  'Non instaurazione rapporto /Non esecuzione operazione',
  'Monitoraggio rafforzato',
  "Non logicità dell'instaurazione / stato del rapporto o dell'esecuzione dell'operazione rispetto alle esigenze del soggetto o alla sua situazione economica / patrimoniale",
  'Non chiarezza circa la provenienza dei fondi impiegati nel rapporto (per gli investimenti o per il pagamento delle rate)',
  'Comportamento del cliente reticente',
  'Non chiarezza circa la destinazione dei fondi richiesti a titolo di finanziamento',
  'Estinzioni anticipate precoci non giustificate',
  'Pagamenti / estinzioni da parte di soggetti terzi non sufficientemente riconducibili al rapporto',
  'Profilo reputazionale non chiaro',
  'Altro',
]

const azioniADVAutomatica = ['Monitoraggio']
const analisi = [
  'Analisi Centrale Rischi',
  'Analisi Bilancio, dichiarazioni IVA e dei redditi',
  'Analisi documenti di acquisto, fatture, perizie relative agli investimenti, regolarità pagamenti.',
  'Analisi certifcazioni/dichiarazioni/documenti societari e contabili',
  'Analisi patrimoniale, business plan, contratti coi principali clienti e fornitori',
  'Analisi certifcazioni aziendali, attestati del datore di lavoro, fornitori, clienti, istituti bancari.',
  'Altro',
]


function EsitoAdeguataVerificaMedioR(props) {
  const theme = useTheme()
  const [value, setValue] = React.useState('no')
  const [showCombo, setShowCombo] = React.useState(true)
  const [openSelection, setOpenSelection] = useState(false)
  const [documentiOpzionaliPresenti, setDocumentiOpzionaliPresenti] =
    useState(false)
  const [alreadyExist, setAlreadyExist] = useState(false)
  const [par2, setPar2] = useState(false)
  const [par3, setPar3] = useState(false)
  const [saved, setSaved] = useState(props.sezioneGiallaSalvata ? true : false)
  const [docName, setDocName] = React.useState([])
  const [openSubMenu, setOpenSubMenu] = React.useState(false)
  const [infoAcquisite, setInfoAcquisite] = React.useState(false)

  const [anno, setAnno] = React.useState('')
  const [altro, setAltro] = React.useState('')
  const [richiedenteSupportoConsulenziale, setRichiedenteSupportoConsulenziale] = React.useState(JSON.parse(localStorage.getItem('user')))
  const [listaSezioneControlliSelezionati, setListaSezioneControlliSelezionati] = useState([null, null])
  const [resetAnno, setResetAnno] = React.useState(false)
  const [azioniDaPorreInEssere, setAzioniDaPorreInEssere] =
    React.useState(azioniDaPo)

  const [listAnalisi, setListaAnalisi] = React.useState('')
  const [controlliSettore, setControlliSettore] = React.useState('')
  const [selectedOptionalDocument, setSelectedOptionalDocument] = useState([])
  const [ripeti, setRipeti] = useState(false)
  const [show, setShow] = useState(false)
  const [valueRadio6, setValueRadio6] = React.useState('')
  const [showNote, setShowNote] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [noteAzioniDaPorreInEssere, setNoteAzioniDaPorreInEssere] =
    React.useState('')
  const [NoteUtenteAutorizzativo, setNoteUtenteAutorizzativo] =
    React.useState('')
  const [idNodoPratica, setIdNodoPratica] = React.useState('')
  const [checked, setChecked] = React.useState([])
  const [titleSendModal, setTitleSendModal] = useState('')
  const [nonSonoEmersiAltriElementiCheck, setNonSonoEmersiAltriElementiCheck] = useState(false)
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)
  const [check4, setCheck4] = useState(false)
  const [check5, setCheck5] = useState(false)
  const [rispostaSupporto, setRispostaSupporto] = useState(false)
  //boolean checkbox ESITI
  const [operazioneSospetta, setOperazioneSospetta] = useState(false)
  const [instaurazione, setInstaurazione] = useState(false)
  const [mantenimentoRapporto, setMantenimentoRapporto] = useState(false)
  const [nonInstaurazione, setNonInstaurazione] = useState(false)
  const [monitoraggioRafforzato, setMonitoraggioRafforzato] = useState(false)
  const [aumentoRischioChecked, setAumentoRischioChecked] = useState(false)
  const [riduzioneRischioChecked, setRiduzioneRischioChecked] = useState(false)
  const [nonLogicita, setNonLogicita] = useState(false)
  const [nonChiarezza, setNonChiarezza] = useState(false)
  const [comportamentoDelCliente, setComportamentoDelCliente] = useState(false)
  const [nonChiarezzaDest, setNonChiarezzaDest] = useState(false)
  const [estinzioniAnticipateP, setEstinzioniAnticipateP] = useState(false)
  const [pagamentiEnstinzioni, setPagamentiEstinzioni] = useState(false)
  const [profiloNonChiaro, setProfiloNonChiaro] = useState(false)

  //boolean checkbox CONTROLLI DELL'ATTIVITà DEL CLIENTE
  const [checkPresenta, setCheckPresenta] = useState(false)
  const [checkNonPresenta, setCheckNonPresenta] = useState(false)
  const [checkRisulta, setCheckRisulta] = useState(false)
  const [checkNonRisulta, setCheckNonRisulta] = useState(false)
  const [dichiaraDiAverNonAdottato, setDichiaraDiAverNonAdottato] =
    useState(false)
  const [dichiaraDiAverAdottato, setDichiaraDiAverAdottato] = useState(false)

  //boolean checkbox analisi svolte
  const [checkAnalisiCentrale, setCheckAnalisiCentrale] = useState(false)
  const [checkAnalisiBilancio, setCheckAnalisiBilancio] = useState(false)
  const [checkAnalisiDocumenti, setCheckAnalisiDocumenti] = useState(false)
  const [checkAnalisiCertifcazioni, setCheckAnalisiCertifcazioni] =
    useState(false)
  const [checkAnalisiPatrimoniale, setCheckAnalisiPatrimoniale] =
    useState(false)
  const [
    checkAnalisiCertifcazioniAziendali,
    setCheckAnalisiCertifcazioniAziendali,
  ] = useState(false)
  const [checkALtroAnalisi, setCheckALtroAnalisi] = useState(false)
  const [valueRadioRisultaEssere, setValueRadioRisultaEssere] = React.useState('')
  const [valueRadio2, setValueRadio2] = React.useState('')
  const [valueRadio4, setValueRadio4] = React.useState('')
  const [valueControlliAttivitàCliente, setValueControlliAttivitàCliente] =
    React.useState('')
  const [valoreAnalisiSvolte, setValoreAnalisiSvolte] = React.useState('')
  const [save, setSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [error, setError] = useState(false)
  const [altroAnalisi, setAltroAnalisi] = React.useState(
    props.sezioneGiallaSalvata && props.sezioneGiallaSalvata !== ''
      ? props.sezioneGiallaSalvata.altroAnalisi
      : '',
  )
  const [richiesta, setRichiesta] = useState('')
  const [errorAnno, setErrorAnno] = useState(false)
  const [sendDocOpzionali, setSendDocOpzionali] = React.useState({
    idNodoPratica: props.idNodoPratica,
    documentiOpzionali: [''],
  })
  const [groupType, setGroupType] = useState('')
  const [checkOperatore, setCheckOperatore] = React.useState(
    props.sezioneGiallaSalvata && props.sezioneGiallaSalvata !== ''
      ? Object.assign(props.sezioneGiallaSalvata)
      : {
        checkoutOperatore: [],
        controlliAttivitaCliente: '',
        analisiSvolte: '',
        anno: '',
        altro: '',
        altroAnalisi: '',
        azioniDaPorreInEssere: [],
        noteAzioniDaPorreInEssere: '',
        radioAzioniDaPorreInEssere: '',
        risultaEssere: '',
        elevataProfessionalita: '',
        controlliSettore: [],
        listAnalisi: [],
        idNodoPratica: props.idNodoPratica,
        documentiOpzionali: [''],
        aggiornaStato: false
      },
  )

  //fetch recupero alberatura cartelle adeguata verifica
  const {
    data: pratica,
    isFetching: FetchingPratica,
    isLoading: LoadingPratica,
    isFetched: FetchedPratica,
    refetch: refetchPratica,
  } = useQuery(['pratica', props.idNodoPratica], () =>
    getFoldersTree(props.idNodoPratica),
  )


  useEffect(() => {

    if (props.sezioneGiallaSalvata) {
      setListaSezioneControlliSelezionati(props.sezioneGiallaSalvata.controlliSettore)
    }
  }, [props.sezioneGiallaSalvata])

  //Boolean a true se sono presenti nella pratica documenti opzionali e se i dati della fetch sono disponibili
  useEffect(() => {
    if (pratica !== undefined && pratica !== null) {
      setDocumentiOpzionaliPresenti(true)
    } else {
      setRipeti(!ripeti)
    }
  }, [pratica, ripeti])

  //Confronto tra doc.opzionali presenti e doc.opzionali selezionati
  useEffect(() => {
    if (
      pratica &&
      documentiOpzionaliPresenti &&
      docName &&
      docName.length > 0
    ) {
      var isPresent = pratica.map(supFolder => supFolder.children[2]?.children)
      //console.table(isPresent);
      for (var i = 0; i < isPresent[0].length; i++) {
        for (var j = 0; j < docName.length; j++) {
          if (isPresent[0][i].name === docName[j]) {
            setAlreadyExist(true)
          }
        }
      }
    }
  }, [docName])



  const handleClickResult = event => {
    setSaved(false)
    if (event.target.value === 'Risulta essere') {
      setCheckRisulta(true)
      checkOperatore.risultaEssere = event.target.value
      setCheckNonRisulta(false)
      setValueRadioRisultaEssere(event.target.value)
    } else if (event.target.value === 'Non risulta essere') {
      setCheckNonRisulta(true)
      checkOperatore.risultaEssere = event.target.value
      setValueRadioRisultaEssere(event.target.value)
      setCheckRisulta(false)
    }
  }

  const handleClickIsPresent = event => {
    setSaved(false)
    if (event.target.value === 'Non presenta') {
      checkOperatore.elevataProfessionalita = event.target.value
      setCheckNonPresenta(true)
      setCheckPresenta(false)
    } else if (event.target.value === 'Presenta') {
      checkOperatore.elevataProfessionalita = event.target.value
      setCheckPresenta(true)
      setCheckNonPresenta(false)
    }
  }

  const handleClickAdotta = event => {
    // debugger
    if (
      event.target.value ===
      'Dichiara di non aver adottato un modello organizzato ex d.lgs.-231/2001'
    ) {
      setSaved(false)
      checkOperatore.controlliAttivitaCliente = event.target.value
      setDichiaraDiAverNonAdottato(true)
      setDichiaraDiAverAdottato(false)
    }
    if (
      event.target.value ===
      'Dichiara di aver adottato/aggiornato un modello organizzativo ex d.lgs. – 231/2001'
    ) {
      setSaved(false)
      checkOperatore.controlliAttivitaCliente = event.target.value
      setDichiaraDiAverAdottato(true)
      setDichiaraDiAverNonAdottato(false)
    }
  }

  const handleClickOperatore = (event, checks) => {
    // debugger
    setSaved(false)
    if (event.target.value === labelCheckBoxCheckOutOperatore[0]) {
      setCheck1(event.target.checked)
      handleSetterCheckOutOperatore(event)
    } else if (event.target.value === labelCheckBoxCheckOutOperatore[1]) {
      setCheck2(event.target.checked)
      handleSetterCheckOutOperatore(event)
    } else if (event.target.value === labelCheckBoxCheckOutOperatore[2]) {
      setCheck3(event.target.checked)
      handleSetterCheckOutOperatore(event)
    } else if (event.target.value === labelCheckBoxCheckOutOperatore[3]) {
      setCheck4(event.target.checked)
      handleSetterCheckOutOperatore(event)
    } else if (event.target.value === labelCheckBoxCheckOutOperatore[4]) {
      setCheck5(event.target.checked)
      handleSetterCheckOutOperatore(event)
    }
  }
  function handleSetterCheckOutOperatore(event) {
    // debugger
    if (event.target.checked === true) {
      setCheckOperatore({
        ...checkOperatore,
        checkoutOperatore: [
          ...checkOperatore.checkoutOperatore,
          event.target.value,
        ],
      })
    }
    else {
      setCheckOperatore({
        ...checkOperatore,
        checkoutOperatore: checkOperatore.checkoutOperatore.filter(
          e => e !== event.target.value,
        ),
      })
    }
  }


  const handleClickStatusCheckBoxAzioneDaPorre = (event, checks) => {
    //debugger
    if (event.target.value === azioniDaPorreInEssere[0]) {
      setOperazioneSospetta(event.target.checked)
    }
    if (event.target.value === azioniDaPorreInEssere[1]) {
      setInstaurazione(event.target.checked)
    }
    if (event.target.value === azioniDaPorreInEssere[2]) {
      setMantenimentoRapporto(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[3]) {
      setNonInstaurazione(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[4]) {
      setMonitoraggioRafforzato(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[5]) {
      setNonLogicita(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[6]) {
      setNonChiarezza(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[7]) {
      setComportamentoDelCliente(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[8]) {
      setNonChiarezzaDest(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[9]) {
      setEstinzioniAnticipateP(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[10]) {
      setPagamentiEstinzioni(event.target.checked)
    } else if (event.target.value === azioniDaPorreInEssere[11]) {
      setProfiloNonChiaro(event.target.checked)
    }
    else if (event.target.value === azioniDaPorreInEssere[12]) {
      setAltro(event.target.checked)
    }
  }

  const handleClickListAnalisi = (event, checks) => {
    if (event.target.value === analisi[0]) {
      setCheckAnalisiCentrale(event.target.checked)
    }
    if (event.target.value === analisi[1]) {
      setCheckAnalisiBilancio(event.target.checked)
    }
    if (event.target.value === analisi[2]) {
      setCheckAnalisiDocumenti(event.target.checked)
    }
    if (event.target.value === analisi[3]) {
      setCheckAnalisiCertifcazioni(event.target.checked)
    }
    if (event.target.value === analisi[4]) {
      setCheckAnalisiPatrimoniale(event.target.checked)
    }
    if (event.target.value === analisi[5]) {
      setCheckAnalisiCertifcazioniAziendali(event.target.checked)
    }
    if (event.target.value === analisi[6]) {
      setCheckALtroAnalisi(event.target.checked)
    }
  }


  //handle documenti opzionali selezionati in richiesta opzionali
  const multiChange = event => {
    const {
      target: { value },
    } = event

    setAlreadyExist(false)
    setDocName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
    // console.log(docName)
  }



  const handleChangeControlliSettore = event => {
    // debugger
    setSaved(false)
    setNonSonoEmersiAltriElementiCheck(event.target.checked)
    if (!nonSonoEmersiAltriElementiCheck) {
      listaSezioneControlliSelezionati.splice(0, 1, 'Non sono emersi altri elementi')
    }
    else if (nonSonoEmersiAltriElementiCheck) {
      listaSezioneControlliSelezionati.splice(0, 1, null)
    }
    // setListaSezioneControlliSelezionati(...listaSezioneControlliSelezionati,listaSezioneControlliSelezionati)
    setCheckOperatore({
      ...checkOperatore,
      controlliSettore: listaSezioneControlliSelezionati,
    })
    // console.log('ccc', checkOperatore.controlliSettore)
  }

  //checkbox Non svolge attività
  const handleChangePar3 = event => {
    setPar2(false)
    setSaved(false)
    setPar3(true)
    //  debugger
    if (event.target.checked) {
      listaSezioneControlliSelezionati.splice(1, 1, event.target.value)
    }
    setCheckOperatore({
      ...checkOperatore,
      controlliSettore: listaSezioneControlliSelezionati,
    })
    // console.log('ccc', checkOperatore.controlliSettore)
  }
  //checkbox Svolge attività
  const handleChangePar2 = event => {
    setPar2(true)
    setPar3(false)
    // debugger
    setSaved(false)
    if (event.target.checked) {
      listaSezioneControlliSelezionati.splice(1, 1, event.target.value)
    }
    setCheckOperatore({
      ...checkOperatore,
      controlliSettore: listaSezioneControlliSelezionati,
    })
    // console.log('ccc', checkOperatore.controlliSettore)
  }

  const handleChangeModal = event => {
    setModalOpen(false)
  }

  useEffect(() => {
    // debugger
    if (
      props.tipoInserimento &&
      props.tipoInserimento.toLowerCase() === 'automatica'
    ) {
      setAzioniDaPorreInEssere(
        azioniADVAutomatica.concat(azioniDaPorreInEssere),
      )
    } else setAzioniDaPorreInEssere(azioniDaPorreInEssere)
  }, [idNodoPratica])

  const [readOnlyMedioRischio, setReadOnlyMedioRischio] = useState(false)

  useEffect(() => {
    if (props.statoRichiesta !== StatiPratica.checkOutOperatore
      && props.statoRichiesta !== StatiPratica.infoComplete
      && props.tipoInserimento && props.tipoInserimento === 'Automatica Medio Rischio') {
      setReadOnlyMedioRischio(true)
    }
  }, [])

  const handleChangeCheckBoxChange = event => {
    // debugger
    if (event.target.checked === true) {
      setCheckOperatore({
        ...checkOperatore,
        checkoutOperatore: [
          ...checkOperatore.checkoutOperatore,
          event.target.value,
        ],
      })
    } else {
      setCheckOperatore({
        ...checkOperatore,
        checkoutOperatore: checkOperatore.checkoutOperatore.filter(
          e => e !== event.target.value,
        ),
      })
    }
  }

  const handleChangeCheckBoxDropdown = (event, azioniDaPorreInEssere) => {
    //  debugger
    setSaved(false)
    setOpenSelection(!openSelection)
    if (event.target.checked === true) {
      setCheckOperatore({
        ...checkOperatore,
        azioniDaPorreInEssere: [
          ...checkOperatore.azioniDaPorreInEssere,
          event.target.value,
        ],
      })
      // setOperazioneSospetta(event.target.checked)
    } else {
      // debugger
      setCheckOperatore({
        ...checkOperatore,
        azioniDaPorreInEssere: checkOperatore.azioniDaPorreInEssere.filter(
          e => e !== event.target.value,
        ),
      })
      resetArricchimentiOperazioneSospetta();
      setOperazioneSospetta(event.target.checked)
    }
  }

  const handleChangeCheckBoxEsiti = (event, azioniDaPorreInEssere) => {
    debugger
    setSaved(false)
    var index = azioniDaPorreInEssere.indexOf(event.target.value)
    if (event.target.checked === true) {
      setCheckOperatore({
        ...checkOperatore,
        azioniDaPorreInEssere: [
          ...checkOperatore.azioniDaPorreInEssere,
          event.target.value,
        ],
      })
    } else {
      setCheckOperatore({
        ...checkOperatore,
        azioniDaPorreInEssere: checkOperatore.azioniDaPorreInEssere.filter(
          e => e !== event.target.value,
        ),
      })
    }
    setChecked(checked.map((v, i) => (i === index ? !v : v)))
    // console.log(checked)
  }

  const [valueRadioAnalisiSvolte, setValueRadioAnalisiSvolte] = useState(null)
  const [boolCheck, setBoolCheck] = useState(false)
  const handleChangeRadioAnalisi = event => {
    // debugger
    setSaved(false)
    if (boolCheck === true) {
      setBoolCheck(false)
      checkOperatore.analisiSvolte = null
      checkOperatore.listAnalisi = []
      checkOperatore.altroAnalisi = ''
      setOpenSubMenu(false)
    } else if (boolCheck === false) {
      setBoolCheck(true)
      checkOperatore.analisiSvolte =
        'Dichiara di aver svolto le seguenti analisi e di aver allegato la necessaria documentazione:'
      setOpenSubMenu(true)
    }
  }

  const handleChangeCheckBoxChange5 = (event, index) => {
    // debugger
    setSaved(false)
    if (event.target.checked === true) {
      setCheckOperatore({
        ...checkOperatore,
        listAnalisi: [...checkOperatore.listAnalisi, event.target.value],
      })
    } else if (event.target.checked === false) {
      setCheckOperatore({
        ...checkOperatore,
        listAnalisi: checkOperatore.listAnalisi.filter(
          e => e !== event.target.value,
        ),
      })
    }
    setChecked(checked.map((v, i) => (i === index ? !v : v)))
  }




  const handleChangeRichiesta = (event, rispostaSupporto) => {
    // debugger
    setRichiedenteSupportoConsulenziale(JSON.parse(localStorage.getItem('user')))
    // console.log(richiedenteSupportoConsulenziale)
    if (event.target.value === 'Richiesta di supporto per ADVR in fase di onboarding' ||
      event.target.value === 'Richiesta di supporto per ADVR in fase di monitoraggio') {
      setTitleSendModal('Richiesta Supporto')
    }
    else if (event.target.value !== 'Richiesta di supporto per ADVR in fase di onboarding' ||
      event.target.value !== 'Richiesta di supporto per ADVR in fase di monitoraggio') {
      setTitleSendModal(event.target.value)
    }
    setGroupType('')
    setRispostaSupporto(rispostaSupporto)
    setValueRadio6(event.target.value)
    if (event.target.value === 'RICHIESTA SUPPORTO') {
      setRichiesta(event.target.value)
    } else if (
      event.target.value ===
      'Richiesta di supporto per ADVR in fase di onboarding'
    ) {
      setGroupType('onboarding')
    } else if (
      event.target.value ===
      'Richiesta di supporto per ADVR in fase di monitoraggio'
    ) {
      setGroupType('monitoraggio')
    }
    setDocName([])
    setModalOpen(true)
  }

  const handleShow = event => {
    event.preventDefault()
    if (!show) {
      setShow(true)
    } else {
      setShow(false)
    }
  }

  useEffect(
    () =>
      setSendDocOpzionali({
        ...sendDocOpzionali,
        documentiOpzionali: docName,
        idNodoPratica: props.idNodoPratica,
      }),
    [docName, idNodoPratica],
  )

  const handleChangeAzioniDaPorreInEssere = event => {
    event.preventDefault()
    setSaved(false)
    checkOperatore.noteAzioniDaPorreInEssere = event.currentTarget.value
    setNoteAzioniDaPorreInEssere(event.currentTarget.value)
  }

  const handleChangeAnno = event => {
    // debugger
    setSaved(false)
    event.preventDefault()
    checkOperatore.anno = event.target.value
  }
  const handleChangeAltro = event => {
    setSaved(false)
    event.preventDefault()
    checkOperatore.altro = event.target.value
  }
  const handleChangeAltroAnalisi = event => {
    // debugger
    setSaved(false)
    // event.preventDefault()
    checkOperatore.altroAnalisi = event.target.value
    setAltroAnalisi(event.target.value)
  }

  const handleAumentoRischio = event => {
    //debugger
    setSaved(false)
    if (
      event.target.value === 'Aumento profilo di rischio' &&
      event.target.checked === true
    ) {
      checkOperatore.radioAzioniDaPorreInEssere = event.target.value
      setRiduzioneRischioChecked(false)
    }
    setAumentoRischioChecked(!aumentoRischioChecked)
  }

  const handleRiduzioneRischio = event => {
    setSaved(false)
    //debugger
    if (
      event.target.value === 'Riduzione profilo di rischio' &&
      event.target.checked === true
    ) {
      checkOperatore.radioAzioniDaPorreInEssere = event.target.value
      setAumentoRischioChecked(false)
    }
    setRiduzioneRischioChecked(!riduzioneRischioChecked)
  }

  const handleSendDocumentiOpzionali = event => {
    setSave(true)
    if (alreadyExist) {
      setError(true)
      setInfoMessage('Uno o più documenti selezionati risultano già presenti!')
      setTimeout(function () {
        setSave(false)
      }, 3000)
    } else {
      setInfoMessage('')
      setLoading(true)
      addDocumentiOpzionali(sendDocOpzionali)
        .then(response => {
          if (response.data.code === 200) {
            setLoading(false)
            setInfoMessage(response.data.message)
            setError(false)
            setTimeout(function () {
              setRipeti(false)
              setAlreadyExist(true)
              setSave(false)
            }, 2000)
            setTimeout(function () {
              window.location.reload()
            }, 5000)
          }
        })
        .catch(error => {
          // console.log(error.response.status)

          setLoading(false)
          setTimeout(function () {
            setSave(false)
          }, 5000)
          setError(true)
          if (error.response) {
            alert(error.response.status)
            setInfoMessage(error.response.data.message)
          }
          if (error.request) {
            alert(error.response.message)
          }
        })
    }
  }

  useEffect(() => {
    //valorizzazione checkbox checkout operatore
    if (props.sezioneGiallaSalvata) {
      setCheckOperatore(props.sezioneGiallaSalvata)
      if (props.sezioneGiallaSalvata.infoAcquisite) {
        setInfoAcquisite(props.sezioneGiallaSalvata.infoAcquisite ? props.sezioneGiallaSalvata.infoAcquisite : false)
      }
      if (
        props.sezioneGiallaSalvata.checkoutOperatore &&
        props.sezioneGiallaSalvata.checkoutOperatore.length > 0
      ) {
        if (
          props.sezioneGiallaSalvata.checkoutOperatore.includes(
            labelCheckBoxCheckOutOperatore[0],
          )
        ) {
          setCheck1(true)
        }
        if (
          props.sezioneGiallaSalvata.checkoutOperatore.includes(
            labelCheckBoxCheckOutOperatore[1],
          )
        ) {
          setCheck2(true)
        }
        if (
          props.sezioneGiallaSalvata.checkoutOperatore.includes(
            labelCheckBoxCheckOutOperatore[2],
          )
        ) {
          setCheck3(true)
        }
        if (
          props.sezioneGiallaSalvata.checkoutOperatore.includes(
            labelCheckBoxCheckOutOperatore[3],
          )
        ) {
          setCheck4(true)
        }
        if (
          props.sezioneGiallaSalvata.checkoutOperatore.includes(
            labelCheckBoxCheckOutOperatore[4],
          )
        ) {
          setCheck5(true)
        }

      }
      //valorizzazione checkbox checkout operatore
      if (
        props.sezioneGiallaSalvata &&
        props.sezioneGiallaSalvata.infoAcquisite === true
      ) {
        setInfoAcquisite(true)
      }
      //valorizzazione checkbox ESITI-> AZIONI DA PORRE IN ESSERE+ MOTIVI DELL'AZIONE(TEXTAREA)
      if (
        props.sezioneGiallaSalvata.azioniDaPorreInEssere &&
        props.sezioneGiallaSalvata.azioniDaPorreInEssere.length > 0
      ) {
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[0],
          )
        ) {
          setOperazioneSospetta(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[1],
          )
        ) {
          setInstaurazione(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[2],
          )
        ) {
          setMantenimentoRapporto(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[3],
          )
        ) {
          setNonInstaurazione(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[4],
          )
        ) {
          setMonitoraggioRafforzato(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[5],
          )
        ) {
          setNonLogicita(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[6],
          )
        ) {
          setNonChiarezza(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[7],
          )
        ) {
          setComportamentoDelCliente(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[8],
          )
        ) {
          setNonChiarezzaDest(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[9],
          )
        ) {
          setEstinzioniAnticipateP(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[10],
          )
        ) {
          setPagamentiEstinzioni(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[11],
          )
        ) {
          setProfiloNonChiaro(true)
        }
      }
      //MOTIVI DELL'AZIONE SOPRAINDICATA
      if (props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere !== '') {
        checkOperatore.noteAzioniDaPorreInEssere = Object.assign(
          props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere,
        )
      }
      //valorizzazione checkbox radioAzioniDaPoreInEssere
      if (
        props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere !== '' ||
        props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere !== null
      ) {
        if (
          props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere ===
          'Aumento profilo di rischio'
        ) {
          setAumentoRischioChecked(true)
        }
        if (
          props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere ===
          'Riduzione profilo di rischio'
        ) {
          setRiduzioneRischioChecked(true)
        }
        if (props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere !== '') {
          setNoteAzioniDaPorreInEssere(
            props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere,
          )
        }
        checkOperatore.infoAcquisite = Object.assign(
          props.sezioneGiallaSalvata.infoAcquisite ? props.sezioneGiallaSalvata.infoAcquisite : false,
        )

      }

      //valorizzazione checkbox controlli relativi al settore
      if (props.sezioneGiallaSalvata && props.sezioneGiallaSalvata.controlliSettore &&
        props.sezioneGiallaSalvata.controlliSettore.length > 0) {
        // debugger

        if (props.sezioneGiallaSalvata.controlliSettore.includes('Non sono emersi altri elementi')) {
          setNonSonoEmersiAltriElementiCheck(true)
        }
        if (props.sezioneGiallaSalvata.controlliSettore.includes('Svolge Attività Specifica')) {
          setPar2(true)
        }
        if (props.sezioneGiallaSalvata.controlliSettore.includes('Non Svolge Attività Specifica')) {
          setPar3(true)
        }
      }
      checkOperatore.controlliSettore = props.sezioneGiallaSalvata.controlliSettore

      //valorizzazione checkbox ESITI-> AZIONI DA PORRE IN ESSERE+ MOTIVI DELL'AZIONE(TEXTAREA)
      if (
        props.sezioneGiallaSalvata.azioniDaPorreInEssere &&
        props.sezioneGiallaSalvata.azioniDaPorreInEssere.length > 0
      ) {
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[0],
          )
        ) {
          setOperazioneSospetta(true)
          setOpenSelection(props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[0],
          ))

        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[1],
          )
        ) {
          setInstaurazione(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[2],
          )
        ) {
          setMantenimentoRapporto(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[3],
          )
        ) {
          setNonInstaurazione(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[4],
          )
        ) {
          setMonitoraggioRafforzato(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[5],
          )
        ) {
          setNonLogicita(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[6],
          )
        ) {
          setNonChiarezza(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[7],
          )
        ) {
          setComportamentoDelCliente(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[8],
          )
        ) {
          setNonChiarezzaDest(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[9],
          )
        ) {
          setEstinzioniAnticipateP(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[10],
          )
        ) {
          setPagamentiEstinzioni(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[11],
          )
        ) {
          setProfiloNonChiaro(true)
        }
      }
      //MOTIVI DELL'AZIONE SOPRAINDICATA
      if (props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere !== '') {
        checkOperatore.noteAzioniDaPorreInEssere = Object.assign(
          props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere,
        )
      }
      //valorizzazione checkbox radioAzioniDaPoreInEssere
      if (
        props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere !== '' ||
        props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere !== null
      ) {
        if (
          props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere ===
          'Aumento profilo di rischio'
        ) {
          setAumentoRischioChecked(true)
        }
        if (
          props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere ===
          'Riduzione profilo di rischio'
        ) {
          setRiduzioneRischioChecked(true)
        }
        if (props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere !== '') {
          setNoteAzioniDaPorreInEssere(
            props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere,
          )
        }
        checkOperatore.infoAcquisite = Object.assign(
          props.sezioneGiallaSalvata.infoAcquisite ? props.sezioneGiallaSalvata.infoAcquisite : false,
        )

      }

      //valorizzazione checkbox controlli relativi al settore
      if (props.sezioneGiallaSalvata && props.sezioneGiallaSalvata.controlliSettore &&
        props.sezioneGiallaSalvata.controlliSettore.length > 0) {
        // debugger

        if (props.sezioneGiallaSalvata.controlliSettore.includes('Non sono emersi altri elementi')) {
          setNonSonoEmersiAltriElementiCheck(true)
        }
        if (props.sezioneGiallaSalvata.controlliSettore.includes('Svolge Attività Specifica')) {
          setPar2(true)
        }
        if (props.sezioneGiallaSalvata.controlliSettore.includes('Non Svolge Attività Specifica')) {
          setPar3(true)
        }
      }
      checkOperatore.controlliSettore = props.sezioneGiallaSalvata.controlliSettore
      //valorizzazione checkbox ESITI-> AZIONI DA PORRE IN ESSERE+ MOTIVI DELL'AZIONE(TEXTAREA)
      if (
        props.sezioneGiallaSalvata.azioniDaPorreInEssere &&
        props.sezioneGiallaSalvata.azioniDaPorreInEssere.length > 0
      ) {
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[0],
          )
        ) {
          setOperazioneSospetta(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[1],
          )
        ) {
          setInstaurazione(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[2],
          )
        ) {
          setMantenimentoRapporto(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[3],
          )
        ) {
          setNonInstaurazione(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[4],
          )
        ) {
          setMonitoraggioRafforzato(true)
        }
        if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[5],
          )
        ) {
          setNonLogicita(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[6],
          )
        ) {
          setNonChiarezza(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[7],
          )
        ) {
          setComportamentoDelCliente(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[8],
          )
        ) {
          setNonChiarezzaDest(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[9],
          )
        ) {
          setEstinzioniAnticipateP(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[10],
          )
        ) {
          setPagamentiEstinzioni(true)
        } if (
          props.sezioneGiallaSalvata.azioniDaPorreInEssere.includes(
            azioniDaPorreInEssere[11],
          )
        ) {
          setProfiloNonChiaro(true)
        }
      }
      //MOTIVI DELL'AZIONE SOPRAINDICATA
      if (props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere !== '') {
        checkOperatore.noteAzioniDaPorreInEssere = Object.assign(
          props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere,
        )
      }
      //valorizzazione checkbox radioAzioniDaPoreInEssere
      if (
        props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere !== '' ||
        props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere !== null
      ) {
        if (
          props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere ===
          'Aumento profilo di rischio'
        ) {
          setAumentoRischioChecked(true)
        }
        if (
          props.sezioneGiallaSalvata.radioAzioniDaPorreInEssere ===
          'Riduzione profilo di rischio'
        ) {
          setRiduzioneRischioChecked(true)
        }
        if (props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere !== '') {
          setNoteAzioniDaPorreInEssere(
            props.sezioneGiallaSalvata.noteAzioniDaPorreInEssere,
          )
        }
        checkOperatore.infoAcquisite = Object.assign(
          props.sezioneGiallaSalvata.infoAcquisite ? props.sezioneGiallaSalvata.infoAcquisite : false,
        )
      }
      //valorizzazione checkbox controlli relativi al settore
      if (props.sezioneGiallaSalvata && props.sezioneGiallaSalvata.controlliSettore &&
        props.sezioneGiallaSalvata.controlliSettore.length > 0) {
        // debugger

        if (props.sezioneGiallaSalvata.controlliSettore.includes('Non sono emersi altri elementi')) {
          setNonSonoEmersiAltriElementiCheck(true)
        }
        if (props.sezioneGiallaSalvata.controlliSettore.includes('Svolge Attività Specifica')) {
          setPar2(true)
        }
        if (props.sezioneGiallaSalvata.controlliSettore.includes('Non Svolge Attività Specifica')) {
          setPar3(true)
        }
      }
      checkOperatore.controlliSettore = props.sezioneGiallaSalvata.controlliSettore
    }
    //valorizzazione radiobutton controlli dell'attività del cliente
    if (props.sezioneGiallaSalvata.risultaEssere === 'Risulta essere') {
      setValueRadioRisultaEssere(props.sezioneGiallaSalvata.risultaEssere)
      setCheckRisulta(true)
    }

    if (props.sezioneGiallaSalvata.risultaEssere === 'Non risulta essere') {
      setValueRadioRisultaEssere(props.sezioneGiallaSalvata.risultaEssere)
      setCheckNonRisulta(true)
    }
    //elevata professionalità
    if (props.sezioneGiallaSalvata.elevataProfessionalita === 'Presenta') {
      setValueRadio2(props.sezioneGiallaSalvata.elevataProfessionalita)
      setCheckPresenta(true)
    }
    if (
      props.sezioneGiallaSalvata.elevataProfessionalita === 'Non presenta'
    ) {
      setValueRadio2(props.sezioneGiallaSalvata.elevataProfessionalita)
      setCheckNonPresenta(true)
    }
    //dichiara di aver adottato o non aver adottato
    if (props.sezioneGiallaSalvata.controlliAttivitaCliente !== '') {
      if (
        props.sezioneGiallaSalvata.controlliAttivitaCliente ===
        'Dichiara di non aver adottato un modello organizzato ex d.lgs.-231/2001'
      ) {
        setDichiaraDiAverNonAdottato(true)
      }
      if (
        props.sezioneGiallaSalvata.controlliAttivitaCliente ===
        'Dichiara di aver adottato/aggiornato un modello organizzativo ex d.lgs. – 231/2001'
      ) {
        setDichiaraDiAverAdottato(true)
        if (props.sezioneGiallaSalvata.anno) {
          setAnno(props.sezioneGiallaSalvata.anno)
        }
      }
    }
    //valorizzazione checkbox lista analisi
    if (
      props.sezioneGiallaSalvata &&
      props.sezioneGiallaSalvata.listAnalisi &&
      props.sezioneGiallaSalvata.listAnalisi.length > 0
    ) {
      if (
        props.sezioneGiallaSalvata.analisiSvolte ===
        'Dichiara di aver svolto le seguenti analisi e di aver allegato la necessaria documentazione:'
      ) {
        setBoolCheck(true)
        setOpenSubMenu(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[0])) {
        setCheckAnalisiCentrale(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[1])) {
        setCheckAnalisiBilancio(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[2])) {
        setCheckAnalisiDocumenti(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[3])) {
        setCheckAnalisiCertifcazioni(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[4])) {
        setCheckAnalisiPatrimoniale(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[5])) {
        setCheckAnalisiCertifcazioniAziendali(true)
      }
      if (props.sezioneGiallaSalvata.listAnalisi.includes(analisi[6])) {
        setCheckALtroAnalisi(true)
        //setAltroAnalisi(props.sezioneGiallaSalvata.altroAnalisi?props.sezioneGiallaSalvata.altroAnalisi:'')
      }
    }
  }, [props.sezioneGiallaSalvata])

  //tasto salva sezione gialla
  const handleSalvataggioIntermedio = event => {
    // debugger
    setSaved(true)
    checkOperatore.aggiornaStato = false
    saveEsitoAdv(checkOperatore).then(response => {
      setSave(true)
      setInfoMessage(response.data.message)
      setLoading(false)
      setError(false)
      setTimeout(function () {
        setSave(false)
      }, 3000)
    })
      .catch(error => {
        setLoading(false)
        setTimeout(function () {
          setSave(false)
        }, 3000)
        setError(true)
        if (error.response) {
          alert(error.response.status)
          setInfoMessage(error.response.data.message)
        }
        if (error.request) {
          alert(error.response.message)
        }
      })
    // console.log(props.statoRichiesta)
    props.syncRefetchSezioneGialla(true)
  }

  //tasto chiuso sezione gialla
  const handleChiusuraPratica = event => {
    debugger
   setSave(true)
   setLoading(true)
   setInfoMessage("CHIUSURA IN CORSO")
   if (checkOperatore.checkoutOperatore && checkOperatore.checkoutOperatore.length > 0 &&
     ((checkOperatore.checkoutOperatore.includes(labelCheckBoxCheckOutOperatore[2])
       && props.tipoInserimento === "Automatica Medio Rischio")
       ||
       (checkOperatore.checkoutOperatore.includes(labelCheckBoxCheckOutOperatore[3]) &&
         props.tipoInserimento === "Automatica Medio Rischio"))) {
     //CHIAMO IL BACKEND(NUOVO SERVIZIO)
     closeAdvMr(checkOperatore)
       .then(response => {
         setSave(true)
         setInfoMessage(response?.data.message ? response?.data.message : "ERROR,NO RESPONSE FROM BE")
         setLoading(false)
         setError(false)
         setTimeout(function () {
           setSave(false)
           window.location.reload()
         }, 3000)
       })
       .catch(error => {
         setLoading(false)
         setTimeout(function () {
           setSave(false)
         }, 5000)
         setError(true)
         if (error?.response) {
           alert(error?.response.status)
           setInfoMessage(error?.response.data.message)
         }
         if (error.request) {
           alert(error?.response.message)
         }
       })
   } else if (
     checkOperatore.checkoutOperatore.length === 0 ||
     checkOperatore.risultaEssere === '' ||
     checkOperatore.controlliAttivitaCliente === '' ||
     checkOperatore.controlliSettore.length === 0 ||
     checkOperatore.azioniDaPorreInEssere.length === 0 ||
     checkOperatore.noteAzioniDaPorreInEssere === '' ||
     checkOperatore.analisiSvolte !== null
     // || (altroCheck && checkOperatore.altro.trim() === '')
   ) {
     if (checkOperatore.checkoutOperatore.length === 0) {
       setSave(true)
       setInfoMessage("Selezionare almeno una voce in INFORMAZIONI SUL CLIENTE E ANALISI DEL PROFILO DI ATTIVITA' DELL'IMPRESA")
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }
     else if (checkOperatore.risultaEssere === '' ||
       checkOperatore.elevataProfessionalita === '' ||
       checkOperatore.controlliAttivitaCliente === '') {
       setSave(true)
       setInfoMessage("Completare sezione CONTROLLI DELL'ATTIVITA' DEL CLIENTE E SITUAZIONE ECONOMICA/PATRIMONIALE")
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }
     else if (checkOperatore.analisiSvolte && checkOperatore.analisiSvolte === 'Dichiara di aver svolto le seguenti analisi e di aver allegato la necessaria documentazione:' &&
       checkOperatore.listAnalisi && checkOperatore.listAnalisi.length === 0) {
       setSave(true)
       setInfoMessage("Completare sezione CONTROLLI DELL'ATTIVITA' DEL CLIENTE E SITUAZIONE ECONOMICA/PATRIMONIALE")
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }
     else if (checkALtroAnalisi && checkOperatore.altroAnalisi.trim() === '') {
       setSave(true)
       setInfoMessage("Completare campo note in sezione CONTROLLI DELL'ATTIVITA' DEL CLIENTE E SITUAZIONE ECONOMICA/PATRIMONIALE")
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }

     else if (checkOperatore.controlliSettore.length === 0) {
       setSave(true)
       setInfoMessage('Selezionare almeno una voce in CONTROLLI RELATIVI AL SETTORE')
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }
     else if (checkOperatore.azioniDaPorreInEssere.length === 0) {
       setSave(true)
       setInfoMessage('Selezionare almeno una voce in AZIONI DA PORRE IN ESSERE SEZIONE ESITI')
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }

     else if (checkOperatore.noteAzioniDaPorreInEssere === '' || undefined) {
       setSave(true)
       setInfoMessage("Specificare i motivi dell'azione da porre in essere")
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }
     else if (checkOperatore.controlliAttivitaCliente === 'Dichiara di aver adottato/aggiornato un modello organizzativo ex d.lgs. – 231/2001' &&
       checkOperatore.anno === '') {
       setErrorAnno(true)
       setSave(true)
       setInfoMessage("Specificare anno in sezione CONTROLLI DELL'ATTIVITA' DEL CLIENTE E SITUAZIONE ECONOMICA/PATRIMONIALE")
       setError(true)
       setTimeout(function () {
         setSave(false)
       }, 5000)
     }
     else {
       checkOperatore.aggiornaStato = true
       saveEsitoAdv(checkOperatore)
         .then(response => {
           setSave(true)
           setInfoMessage(response.data.message)
           setLoading(false)
           setError(false)
           setTimeout(function () {
             setSave(false)
             window.location.reload()
           }, 3000)
         })
         .catch(error => {
           setLoading(false)
           setTimeout(function () {
             setSave(false)
           }, 5000)
           setError(true)
           if (error.response) {
             alert(error.response.status)
             setInfoMessage(error.response.data.message)
           }
           if (error.request) {
             alert(error.response.message)
           }
         })
     }
   }
 }


  const handleSendRichiestaSupporto = richiestaSupporto => {
    setSave(true)
    setLoading(true)
    setModalOpen(false)
    setInfoMessage('')

    sendRichiestaSupporto(richiestaSupporto)
      .then(response => {
        setLoading(false)
        setInfoMessage(response.data.message)
        setError(response.data.code !== 200)
        setModalOpen(false)
        setTimeout(function () {
          window.location.reload()
        }, 1000)
      })
      .catch(err => {
        setLoading(false)
        setInfoMessage(
          "Errore durante l'invio della richiesta, se persiste contattare l'amministratore",
        )
        setError(true)
        setTimeout(function () {
          setSave(false)
        }, 5000)
      })
  }

  function resetArricchimentiOperazioneSospetta() {
    // debugger
    const ArricchimentoOperazioneSospetta = checkOperatore?.azioniDaPorreInEssere.filter(azione =>
      azione !== azioniDaPorreInEssere[0] &&
      azione !== azioniDaPorreInEssere[1] &&
      azione !== azioniDaPorreInEssere[2] &&
      azione !== azioniDaPorreInEssere[3] &&
      azione !== azioniDaPorreInEssere[4])
    checkOperatore.azioniDaPorreInEssere = checkOperatore.azioniDaPorreInEssere.filter(e => !ArricchimentoOperazioneSospetta.includes(e));
    if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[5])) {
      setNonLogicita(false)
    } if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[6])) {
      setNonChiarezza(false)
    } if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[7])) {
      setComportamentoDelCliente(false)
    } if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[8])) {
      setNonChiarezzaDest(false)
    } if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[9])) {
      setEstinzioniAnticipateP(false)
    } if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[10])) {
      setPagamentiEstinzioni(false)
    } if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[11])) {
      setProfiloNonChiaro(false)
    }
    if (!checkOperatore.azioniDaPorreInEssere.includes(azioniDaPo[12])) {
      setAltro(false)
    }
  }

  return (
    <Box>
      <Stack sx={{ display: 'flex' }}>
        <SendModal
          open={modalOpen}
          groupType={groupType}
          richiedenteSupportoConsulenziale={richiedenteSupportoConsulenziale}
          value={richiesta}
          onClick={handleChangeModal}
          idNodoPratica={props.idNodoPratica}
          onSubmit={handleSendRichiestaSupporto}
          rispostaSupporto={rispostaSupporto}
          titleSendModal={titleSendModal}
        />

        <CustomModal
          open={save}
          loading={loading}
          error={error}
          message={infoMessage}
        />

        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            INFORMAZIONI SUL CLIENTE E ANALISI DEL PROFILO DI ATTIVITA' DELL'IMPRESA
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="column">
              <>
                {' '}
                <FormControlLabel
                  label={
                    <Typography
                      sx={{ justifyContent: 'start' }}
                      variant="caption"
                    >
                      {labelCheckBoxCheckOutOperatore[0]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      checked={check1}
                      position="absolute"
                      value={labelCheckBoxCheckOutOperatore[0]}
                      onClick={event => handleClickOperatore(event, check1)}
                      size="none"
                      sx={{ minWidth: '15px', maxHeight: '15px' }}
                    ></Checkbox>
                  }
                />
                <FormControlLabel
                  label={
                    <Typography
                      sx={{ justifyContent: 'start' }}
                      variant="caption"
                    >
                      {labelCheckBoxCheckOutOperatore[1]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      checked={check2}
                      position="absolute"
                      value={labelCheckBoxCheckOutOperatore[1]}
                      onClick={event => handleClickOperatore(event, check2)}
                      size="none"
                      sx={{ minWidth: '15px', maxHeight: '15px' }}
                    ></Checkbox>
                  }
                />
                <FormControlLabel
                  label={
                    <Typography
                      sx={{ justifyContent: 'start' }}
                      variant="caption"
                    >
                      {labelCheckBoxCheckOutOperatore[2]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      checked={check3}
                      position="absolute"
                      value={labelCheckBoxCheckOutOperatore[2]}
                      onClick={event => handleClickOperatore(event, check3)}
                      size="none"
                      sx={{ minWidth: '15px', maxHeight: '15px' }}

                    >
                    </Checkbox>
                  }
                />
                <FormControlLabel
                  label={
                    <Typography
                      sx={{ justifyContent: 'start' }}
                      variant="caption"
                    >
                      {labelCheckBoxCheckOutOperatore[3]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      checked={check4}
                      position="absolute"
                      value={labelCheckBoxCheckOutOperatore[3]}
                      onClick={event => handleClickOperatore(event, check4)}
                      size="none"
                      sx={{ minWidth: '15px', maxHeight: '15px' }}
                    ></Checkbox>

                  }
                />
                <FormControlLabel
                  label={
                    <Typography
                      sx={{ justifyContent: 'start' }}
                      variant="caption"
                    >
                      {labelCheckBoxCheckOutOperatore[4]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      checked={check5}
                      position="absolute"
                      value={labelCheckBoxCheckOutOperatore[4]}
                      onClick={event => handleClickOperatore(event, check5)}
                      size="none"
                      sx={{ minWidth: '15px', maxHeight: '15px' }}
                    ></Checkbox>

                  }

                />
              </>
            </Stack>
            {check5 && (
              <textarea
                disabled={readOnlyMedioRischio}
                aria-label="empty textarea"
                placeholder="Specificare"
                defaultValue={labelCheckBoxCheckOutOperatore[4].placeholder}
                onChange={event => handleChangeCheckBoxChange(event)}
                name={'dettaglioAltroCheckOut'}
                style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }} />
            )}

            <Stack>
              {check3 && (
                <Typography variant="caption">
                  {' '}
                  Cliente a cui è stato assegnato uno score factor “giallo” all'interno del GRC ” per il quale in corso approfondimenti condotti in data XX/XX/XXXX (_la data è quella di chiusura della pratica, il testo della nota verrà completato con il controllo selezionato)
                </Typography>
              )}
            </Stack>
            <Stack>
              {check4 && (
                <Typography variant="caption">
                  {' '}
                  Cliente a cui è stato assegnato uno score factor “giallo” all'interno del GRC ” per il quale in corso approfondimenti condotti in data XX/XX/XXXX (_la data è quella di chiusura della pratica, il testo della nota verrà completato con il controllo selezionato)
                </Typography>
              )}
            </Stack>

          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            CONTROLLI DELL'ATTIVITA' DEL CLIENTE E SITUAZIONE
            ECONOMICA/PATRIMONIALE
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: theme.palette.yellow.dark,
              width: '100%',
              maxHeight: '300px',
              overflow: 'auto',
            }}
          >
            <Container>
              <FormGroup>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueRadioRisultaEssere}
                  onClick={event => handleClickResult(event)}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      disabled={readOnlyMedioRischio}
                      value="Risulta essere"
                      control={<Radio checked={checkRisulta} />}
                      label={
                        <Typography variant="caption">
                          Risulta essere
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      disabled={readOnlyMedioRischio}
                      value="Non risulta essere"
                      control={<Radio checked={checkNonRisulta} />}
                      label={
                        <Typography variant="caption">
                          Non risulta essere
                        </Typography>
                      }
                    />
                  </Stack>
                </RadioGroup>
                <FormHelperText>
                  una Realtà Consolidata nel settore di appartenenza{' '}
                </FormHelperText>
              </FormGroup>

              <FormGroup disabled={readOnlyMedioRischio}>
                <RadioGroup
                  disabled={readOnlyMedioRischio}
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={valueRadio2}
                  onClick={event => handleClickIsPresent(event)}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      disabled={readOnlyMedioRischio}
                      value="Presenta"
                      control={<Radio checked={checkPresenta} />}
                      label={
                        <Typography variant="caption">Presenta</Typography>
                      }
                    />
                    <FormControlLabel
                      disabled={readOnlyMedioRischio}
                      value="Non presenta"
                      control={<Radio checked={checkNonPresenta} />}
                      label={
                        <Typography variant="caption">Non presenta</Typography>
                      }
                    />
                  </Stack>
                </RadioGroup>
                <FormHelperText>
                  tra gli interlocutori persone con elevata
                  professionalità{' '}
                </FormHelperText>
              </FormGroup>

              <RadioGroup
                disabled={readOnlyMedioRischio}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
              >
                <Stack direction="column">
                  <Stack direction={'row'}>
                    <FormControlLabel
                      disabled={readOnlyMedioRischio}
                      value="Dichiara di aver adottato/aggiornato un modello organizzativo ex d.lgs. – 231/2001"
                      onClick={event => handleClickAdotta(event)}
                      control={<Radio checked={dichiaraDiAverAdottato} />}
                      label={
                        <Typography variant="caption">
                          Dichiara di aver adottato/aggiornato un modello
                          organizzativo ex d.lgs. – 231/2001
                        </Typography>
                      }
                    />
                    {checkOperatore.controlliAttivitaCliente ===
                      'Dichiara di aver adottato/aggiornato un modello organizzativo ex d.lgs. – 231/2001' && (
                        <TextField
                          disabled={readOnlyMedioRischio}
                          error={errorAnno}
                          onChange={event => handleChangeAnno(event)}
                          value={checkOperatore.anno}
                          sx={{ alignItems: 'start' }}
                          placeholder="specificare anno"
                          variant="standard"
                        ></TextField>
                      )}
                  </Stack>

                  <FormControlLabel
                    value="Dichiara di non aver adottato un modello organizzato ex d.lgs.-231/2001"
                    onClick={event => handleClickAdotta(event)}
                    control={<Radio
                      checked={dichiaraDiAverNonAdottato}
                      disabled={readOnlyMedioRischio} />}
                    label={
                      <Typography variant="caption">
                        Dichiara di non aver adottato un modello organizzato ex
                        d.lgs.-231/2001
                      </Typography>
                    }
                  />
                </Stack>
              </RadioGroup>

              <FormGroup>
                <FormControlLabel
                  disabled={readOnlyMedioRischio}
                  value={checkOperatore.analisiSvolte}
                  checked={boolCheck}
                  onClick={event => handleChangeRadioAnalisi(event)}
                  control={<Radio disabled={readOnlyMedioRischio} />}
                  label={
                    <Typography variant="caption">
                      Dichiara di aver svolto le seguenti analisi e di aver
                      allegato la necessaria documentazione:
                    </Typography>
                  }
                />
                {boolCheck &&
                  checkOperatore.analisiSvolte ===
                  'Dichiara di aver svolto le seguenti analisi e di aver allegato la necessaria documentazione:' && (
                    <FormGroup sx={{ overflow: 'auto' }}>
                      <Stack direction={'column'}>
                        <Tooltip
                          placement="left"
                          title={analisi[0]}
                          value={analisi[0]}
                        >
                          <FormControlLabel
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[0]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                disabled={readOnlyMedioRischio}
                                checked={checkAnalisiCentrale}
                                value={analisi[0]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkAnalisiCentrale,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip
                          placement="left-start"
                          title={analisi[1]}
                          value={analisi[1]}
                        >
                          <FormControlLabel
                            disabled={readOnlyMedioRischio}
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[1]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checked={checkAnalisiBilancio}
                                value={analisi[1]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkAnalisiBilancio,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip
                          placement="left"
                          title={analisi[2]}
                          value={analisi[2]}
                        >
                          <FormControlLabel
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[2]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                disabled={readOnlyMedioRischio}
                                checked={checkAnalisiDocumenti}
                                value={analisi[2]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkAnalisiDocumenti,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip
                          placement="left"
                          title={analisi[3]}
                          value={analisi[3]}
                        >
                          <FormControlLabel
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[3]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                disabled={readOnlyMedioRischio}
                                checked={checkAnalisiCertifcazioni}
                                value={analisi[3]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkAnalisiCertifcazioni,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip
                          placement="left"
                          title={analisi[4]}
                          value={analisi[4]}
                        >
                          <FormControlLabel
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[4]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                disabled={readOnlyMedioRischio}
                                checked={checkAnalisiPatrimoniale}
                                value={analisi[4]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkAnalisiPatrimoniale,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip
                          placement="left"
                          title={analisi[5]}
                          value={analisi[5]}
                        >
                          <FormControlLabel
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[5]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                disabled={readOnlyMedioRischio}
                                checked={checkAnalisiCertifcazioniAziendali}
                                value={analisi[5]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkAnalisiCertifcazioniAziendali,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>

                        <Tooltip
                          placement="left"
                          title={analisi[6]}
                          value={analisi[6]}
                        >
                          <FormControlLabel
                            direction={'column'}
                            label={
                              <Typography variant="caption">
                                {analisi[6]}
                              </Typography>
                            }
                            control={
                              <Checkbox
                                disabled={readOnlyMedioRischio}
                                checked={checkALtroAnalisi}
                                value={analisi[6]}
                                onChange={event =>
                                  handleChangeCheckBoxChange5(event)
                                }
                                onClick={event =>
                                  handleClickListAnalisi(
                                    event,
                                    checkALtroAnalisi,
                                  )
                                }
                                size="small"
                              />
                            }
                          />
                        </Tooltip>
                      </Stack>

                      {checkALtroAnalisi && (
                        <textarea
                          disabled={readOnlyMedioRischio}
                          aria-label="empty textarea"
                          placeholder="Specificare"
                          defaultValue={checkOperatore.altroAnalisi}
                          onChange={event => handleChangeAltroAnalisi(event)}
                          name={'dettaglioAltroAnalisi'}
                          style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
                        />
                      )}
                    </FormGroup>
                  )}
              </FormGroup>
            </Container>
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            CONTROLLI RELATIVI AL SETTORE
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup sx={{ overflow: 'auto' }}>
              <Stack direction="row">
                <Tooltip
                  placement="left"
                  title={titlesToolTip.nonSonoEmersiAltriElementi}
                >
                  <FormControlLabel
                    disabled={readOnlyMedioRischio}
                    label={
                      <Typography variant="caption">
                        Non sono emersi altri elementi
                      </Typography>
                    }
                    control={
                      <Stack direction="row">
                        <Checkbox
                          disabled={readOnlyMedioRischio}
                          checked={nonSonoEmersiAltriElementiCheck}
                          value={'Non sono emersi altri elementi'}
                          onClick={event => handleChangeControlliSettore(event)}
                          size="small"
                        />
                      </Stack>
                    }
                  />
                </Tooltip>
              </Stack>
            </FormGroup>
            <FormGroup sx={{ overflow: 'auto' }}>
              <Stack direction="row">
                <Tooltip title={titlesToolTip.svolgeAttivitaSpecifica}>
                  <FormControlLabel
                    label={
                      <Typography variant="caption">
                        Svolge Attività Specifica
                      </Typography>
                    }
                    control={
                      <Checkbox
                        disabled={readOnlyMedioRischio}
                        checked={par2}
                        value={'Svolge Attività Specifica'}
                        size="small"
                        onClick={event => handleChangePar2(event)}
                      />
                    }
                  ></FormControlLabel>
                </Tooltip>
                <Tooltip title={titlesToolTip.nonSvolgeAttivitaSpecifica}>
                  <FormControlLabel
                    label={
                      <Typography variant="caption">
                        Non Svolge Attività Specifica
                      </Typography>
                    }
                    control={
                      <Checkbox
                        disabled={readOnlyMedioRischio}
                        checked={par3}
                        value={'Non Svolge Attività Specifica'}
                        size="small"
                        onChange={event => handleChangePar3(event)}
                      />
                    }
                  ></FormControlLabel>
                </Tooltip>
              </Stack>
            </FormGroup>
          </AccordionDetails>
        </Accordion>

        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: theme.palette.yellow.middle, width: '100%' }}
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            ESITI
          </AccordionSummary>
          <Typography variant="caption"> AZIONI DA PORRE IN ESSERE</Typography>

          <AccordionDetails>
            <Tooltip
              placement="left"
              title={azioniDaPorreInEssere[0]}
              value={azioniDaPorreInEssere[0]}
            >
              <Stack direction="row">
                <FormControlLabel
                  label={
                    <Typography variant="caption">
                      {azioniDaPorreInEssere[0]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      value={azioniDaPorreInEssere[0]}
                      checked={operazioneSospetta}
                      onChange={event =>
                        handleChangeCheckBoxDropdown(event, azioniDaPorreInEssere)
                      }
                      onClick={event =>
                        handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          operazioneSospetta,
                        )
                      }
                      size="small"
                    />
                  }
                />
              </Stack>
            </Tooltip>

            <Tooltip
              placement="left"
              title={azioniDaPorreInEssere[1]}
              value={azioniDaPorreInEssere[1]}
            >
              <Stack direction="row">
                <FormControlLabel
                  label={
                    <Typography variant="caption">
                      {azioniDaPorreInEssere[1]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      value={azioniDaPorreInEssere[1]}
                      checked={instaurazione}
                      onChange={event =>
                        handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)
                      }
                      onClick={event =>
                        handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          instaurazione,
                        )
                      }
                      size="small"
                    />
                  }
                />
              </Stack>
            </Tooltip>

            <Tooltip
              placement="left"
              title={azioniDaPorreInEssere[2]}
              value={azioniDaPorreInEssere[2]}
            >
              <Stack direction="row">
                <FormControlLabel
                  label={
                    <Typography variant="caption">
                      {azioniDaPorreInEssere[2]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      value={azioniDaPorreInEssere[2]}
                      checked={mantenimentoRapporto}
                      onChange={event =>
                        handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)
                      }
                      onClick={event =>
                        handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          mantenimentoRapporto,
                        )
                      }
                      size="small"
                    />
                  }
                />
              </Stack>
            </Tooltip>

            <Tooltip
              placement="left"
              title={azioniDaPorreInEssere[3]}
              value={azioniDaPorreInEssere[3]}
            >
              <Stack direction="row">
                <FormControlLabel
                  label={
                    <Typography variant="caption">
                      {azioniDaPorreInEssere[3]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      value={azioniDaPorreInEssere[3]}
                      checked={nonInstaurazione}
                      onChange={event =>
                        handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)
                      }
                      onClick={event =>
                        handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          nonInstaurazione,
                        )
                      }
                      size="small"
                    />
                  }
                />
              </Stack>
            </Tooltip>

            <Tooltip
              placement="left"
              title={azioniDaPorreInEssere[4]}
              value={azioniDaPorreInEssere[4]}
            >
              <Stack direction="row">
                <FormControlLabel
                  label={
                    <Typography variant="caption">
                      {azioniDaPorreInEssere[4]}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      disabled={readOnlyMedioRischio}
                      value={azioniDaPorreInEssere[4]}
                      checked={monitoraggioRafforzato}
                      onChange={event =>
                        handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)
                      }
                      onClick={event =>
                        handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          monitoraggioRafforzato,
                        )
                      }
                      size="small"
                    />
                  }
                />
              </Stack>
            </Tooltip>
            {openSelection && (
              <div style={{ marginLeft: '50px' }}>
                <Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[5]}
                  value={azioniDaPorreInEssere[5]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[5]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[5]}
                        checked={nonLogicita}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          nonLogicita
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip><Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[6]}
                  value={azioniDaPorreInEssere[6]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[6]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[6]}
                        checked={nonChiarezza}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          nonChiarezza
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip><Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[7]}
                  value={azioniDaPorreInEssere[7]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[7]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[7]}
                        checked={comportamentoDelCliente}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          comportamentoDelCliente
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip><Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[8]}
                  value={azioniDaPorreInEssere[8]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[8]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[8]}
                        checked={nonChiarezzaDest}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          nonChiarezzaDest
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip><Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[9]}
                  value={azioniDaPorreInEssere[9]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[9]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[9]}
                        checked={estinzioniAnticipateP}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          estinzioniAnticipateP
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip><Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[10]}
                  value={azioniDaPorreInEssere[10]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[10]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[10]}
                        checked={pagamentiEnstinzioni}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          pagamentiEnstinzioni
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip>
                <Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[11]}
                  value={azioniDaPorreInEssere[11]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[11]}
                      </Typography>}
                      control={<Checkbox
                        disabled={readOnlyMedioRischio}
                        value={azioniDaPorreInEssere[11]}
                        checked={profiloNonChiaro}
                        onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                        onClick={event => handleClickStatusCheckBoxAzioneDaPorre(
                          event,
                          profiloNonChiaro
                        )}
                        size="small" />} />
                  </Stack>
                </Tooltip>
                <Tooltip
                  placement="left"
                  title={azioniDaPorreInEssere[12]}
                  value={azioniDaPorreInEssere[12]}
                >
                  <Stack direction="row">
                    <FormControlLabel
                      label={<Typography variant="caption">
                        {azioniDaPorreInEssere[12]}
                      </Typography>}
                      control={
                        <Checkbox
                          disabled={readOnlyMedioRischio}
                          value={azioniDaPorreInEssere[12]}
                          checked={altro}
                          onChange={event => handleChangeCheckBoxEsiti(event, azioniDaPorreInEssere)}
                          onClick={event => handleClickStatusCheckBoxAzioneDaPorre(event, altro)}
                          size="small" />} />
                  </Stack>
                </Tooltip>

                {altro && (
                  <textarea
                    disabled={readOnlyMedioRischio}
                    aria-label="empty textarea"
                    placeholder="Specificare"
                    defaultValue={azioniDaPorreInEssere[12].placeholder}
                    onChange={event => handleChangeCheckBoxEsiti(event)}
                    name={'dettaglioAltroEsiti'}
                    style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
                  />
                )}

              </div>
            )}
            <RadioGroup
              disabled={readOnlyMedioRischio}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={valueRadio4}
            >
              <FormControlLabel
                value="Aumento profilo di rischio"
                control={
                  <Checkbox
                    disabled={readOnlyMedioRischio}
                    icon={<CropSquareIcon />}
                    onClick={event => handleAumentoRischio(event)}
                    checked={aumentoRischioChecked}
                  />
                }
                label={
                  <Typography variant="caption">
                    Aumento profilo di rischio
                  </Typography>
                }
              />
              <FormControlLabel
                value="Riduzione profilo di rischio"
                control={
                  <Checkbox
                    disabled={readOnlyMedioRischio}
                    icon={<CropSquareIcon />}
                    onClick={event => handleRiduzioneRischio(event)}
                    checked={riduzioneRischioChecked}
                  />
                }
                label={
                  <Typography variant="caption">
                    Riduzione profilo di rischio
                  </Typography>
                }
              />
            </RadioGroup>

            <Stack>
              {aumentoRischioChecked && (
                <Typography variant="caption">
                  {' '}
                  In caso in cui ad esito dei controlli su un rischio «Medio» vi
                  siano altri elementi di rischio di natura comportamentale o
                  diversi da quelli catturati in automatico, noti al valutatore,
                  che a suo giudizio propongono un aumento del profilo di
                  rischio da «medio» a «alto».
                </Typography>
              )}
            </Stack>
            <Stack>
              {riduzioneRischioChecked && (
                <Typography variant="caption">
                  {' '}
                  In caso in cui ad esito dei controlli svolti sul settore di
                  attività (valutato a rischio medio), è possibile escludere
                  elementi di rischio (soggettivo od oggettivo) di riciclaggio.
                </Typography>
              )}
            </Stack>
          </AccordionDetails>
          <Container sx={{ width: '100%', ml: -3, mt: 4 }}>
            <Stack>
              <Typography variant="caption">
                {' '}
                SPECIFICARE I MOTIVI DELL'AZIONE SOPRAINDICATA
              </Typography>
              <Tooltip title="SPECIFICARE I MOTIVI DELL'AZIONE SOPRAINDICATA">
                <textarea
                  disabled={readOnlyMedioRischio}
                  defaultValue={checkOperatore.noteAzioniDaPorreInEssere}
                  placeholder="Dare giudizio compiuto sul motivo dell’azione da porre in essere sopra specificato, illustrando, con riferimento all’azione, le valutazioni oggettive (es: situazione economica/patrimoniale) e soggettive (es: coerenza/adeguatezza operazione richiesta)"
                  minrows={3}
                  onChange={handleChangeAzioniDaPorreInEssere}
                  style={{ margin: "2px", minWidth: "100%", width: "100%", maxWidth: "100%", height: "60px", maxHeight: "50%", minHeight: "60px" }}
                ></textarea>
              </Tooltip>
            </Stack>
          </Container>
        </Accordion>
        <StyledButton
          onClick={handleShow}
          sx={{
            borderRadius: '5px 5px 5px 5px',
            mt: '20px',
            alignSelf: 'flex-end',
            paddingBottom: '10px',
            paddingTop: '0px',
          }}
        >
          ...
        </StyledButton>
        {show && (
          <FormGroup>
            <InputLabel variant="standard">
              Documenti (Opzionali) Vari
            </InputLabel>
            <Select
              variant="standard"
              size="none"
              sx={{ width: '90%', maxWidth: '600px' }}
              id="demo-multiple-checkbox"
              multiple
              value={docName}
              onChange={multiChange}
              renderValue={selected => selected.join(', ')}
              MenuProps={MenuProps}
              inputProps={{
                name: 'documentiOpzionaliVari',
                id: 'uncontrolled-native',
              }}
            >
              {listDocOpzionali?.map(name => (
                <Tooltip placement="left" key={name} title={name} value={name}>
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={docName.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                </Tooltip>
              ))}
            </Select>
            <FormGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              mt={5}
            >
              <Stack mt={5}>
                {docName.length > 0 && (
                  <StyledButton
                    onClick={handleSendDocumentiOpzionali}
                    sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
                  >
                    RICHIESTA DOCUMENTI OPZIONALI
                  </StyledButton>
                )}
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="baseline"
                >
                  <OnlyWithFunction acceptedFunction={'APPROVA_PRATICA_UTENTE_BASE'} acceptedSecondFunction={'RICHIESTA_ON_BOARDING'}>
                    <StyledButton
                      onClick={event => handleChangeRichiesta(event, false)}
                      value={
                        'Richiesta di supporto per ADVR in fase di onboarding'
                      }
                      sx={{ borderRadius: '5px 0px 0px 0px' }}
                      size="small"
                    >
                      Richiesta di supporto per ADVR in fase di onboarding
                    </StyledButton>
                  </OnlyWithFunction>

                  <OnlyWithFunction acceptedFunction={'APPROVA_PRATICA_FIELD_ANALYST'} acceptedSecondFunction={'RICHIESTA_MONITORAGGIO'}>
                    <StyledButton
                      onClick={event => handleChangeRichiesta(event, false)}
                      value={
                        'Richiesta di supporto per ADVR in fase di monitoraggio'
                      }
                      sx={{ borderRadius: '5px 0px 0px 0px' }}
                      size="small"
                    >
                      Richiesta di supporto per ADVR in fase di monitoraggio
                    </StyledButton>
                  </OnlyWithFunction>
                </Stack>

                {(isValidUser('SUPPORTO_FUNZIONE_AML') || isValidUser('SUPPORTO_AML_DESK')) && (
                  <StyledButton
                    onClick={event => handleChangeRichiesta(event, true)}
                    value={'Supporto Consulenziale'}
                    helperText="none"
                    sx={{
                      borderRadius: '5px 0px 0px 0px',
                      size: 'large',
                      width: '400px',
                      height: '40px',
                    }}
                  >
                    Supporto consulenziale
                  </StyledButton>)}

              </Stack>
            </FormGroup>
          </FormGroup>
        )}
        <OnlyWithFunction acceptedFunction="CHIUSO">
          <AccordionActions
            sx={{
              backgroundColor: theme.palette.yellow.middle,
              width: '100%',
              alignItems: 'center',
              padding: 0,
            }}
          >
            {(props.statoRichiesta === StatiPratica.infoComplete || props.statoRichiesta === StatiPratica.checkOutOperatore) && (

              <StyledButton
                onClick={handleSalvataggioIntermedio}
                sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
                disabled={saved}
              >
                Salva
              </StyledButton>
            )}

            {(props.statoRichiesta === StatiPratica.infoComplete) && (
              <StyledButton
                onClick={handleChiusuraPratica}
                sx={{ borderRadius: '5px 0px 0px 0px', mt: 5 }}
                disabled={props.disabled}
              >
                Chiuso
              </StyledButton>
            )}
          </AccordionActions>
        </OnlyWithFunction>
      </Stack>
    </Box>
  )
}

export default EsitoAdeguataVerificaMedioR

