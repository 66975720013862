import React from 'react'
import { Grid, Typography } from '@mui/material'
import Header from '../UI/Header'
import { useTheme } from '@mui/material/styles'

function NotFound() {
    const theme = useTheme()
    return (
        <>
            <Header
                showHome
                header={
                    <>
                        <Grid
                            container
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            justifyContent="start"
                            alignContent="start"
                        >
                            <Typography
                                sx={{ flexGrow: 1 }}
                                xs={12}
                                item
                                alignItems="center"
                                mb={'1rem'}
                                color={theme.palette.black.main}
                                variant="h1"
                            >
                                NOT FOUND
                            </Typography>
                        </Grid>
                    </>
                }
            />
            <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                mt={14}
                justifyContent="center"
                alignContent="center"
            >
                <Grid item xs={4} justifyItems="center" alignItems="center">
                    <Typography margin="auto" variant="h1">
                        404
                    </Typography>
                    <Typography variant="h4">
                        Il contenuto ricercato non è stato trovato. Contatta l'assistenza o
                        ritorna alla home.
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default NotFound
