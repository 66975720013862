
import { customAxios } from './customAxios'

export function uploadDocument(body, idDocumento, fileName) {
  const config = {
    params: {
      fileName: fileName,
      folderId: idDocumento
    },
    headers: {
      "AuthToken": JSON.parse(localStorage.getItem('token')),
    }

  }
  const data = body
  const uploadDocumentRequest = customAxios.post(process.env.REACT_APP_BACKEND_HOST + `/alfresco/uploadDocument?autorename=true`, data, config)
  return uploadDocumentRequest
}
