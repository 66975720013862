import { Container, Box } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'
import homepageLogoADV from '../assets/logo-bancaprogetto.png'

function TopLogoDiv() {
    const theme = useTheme()
    return (
        <Container sx={{ position: 'absolute', left: '15px' }}>
            <Box
                sx={{
                    position: 'sticky',
                    height: '5rem',
                    width: '10rem',
                    backgroundColor: theme.palette.grey.light,
                    borderRadius: '0px 0px 10px 10px',
                }}
            >
                <Box
                    mx="0.5rem"
                    sx={{
                        marginTop: '14%',
                        maxWidth: '90%',
                        maxHeight: '90%',
                        objectFit: 'contain',
                    }}
                    component="img"
                    alt="Logo ADV"
                    src={homepageLogoADV}
                ></Box>
            </Box>
        </Container>
    )
}

export default TopLogoDiv
